import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { animated, config, useSpring, useTransition } from '@react-spring/web';

import { StyledFC } from '../../../types';
import rewardImg from '../../_shared/image/image.png';
import medal from '../../_shared/image/medalNew.png';
import rankImg from '../../_shared/image/supernova.png';
import { FillableButton } from '../../MedalsNew/Button';
import Bg from './Bg';
import LeftCircle from './LeftCircle';
import RedBg from './RedBg';
import RightCircle from './RightCircle';
import Star from './Star';
import { settingsService } from '../../../services';

const SuccessText = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 40px;
  max-width: 379px;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  z-index: 999;
`;

const ButtonWrapper = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 8%;
  width: fit-content;
  z-index: 999;
  @media (max-height: 720px) {
    bottom: unset;
    top: 620px;
  }
`;

const Reward = styled(animated.div)`
  position: absolute;
  top: 205px;
  left: 6px;
  width: 140px;
  height: 140px;
`;

const StarBgWrapper = styled(animated.div)`
  position: absolute;
  z-index: -1;
  top: -24px;
  left: -6px;
`;

const Img = styled(animated.img)`
  width: 125px;
`;

const Medal = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 186px;
  top: 183px;
  width: 120px;
  height: 120px;
`;

const Rank = styled(animated.div)`
  position: absolute;
  top: 200px;
  right: 54px;
  width: 140px;
  height: 140px;
`;

const CirclesWrapper = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 357px;
  width: 180px;
  display: flex;
  justify-content: space-between;
`;

const GreetingsText = styled(animated.p)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 490px;
  left: 0;
  right: 0;
  width: 339px;
  text-align: center;
  font-weight: 400;
  line-height: 20px;
  font-size: 16px;
  background-color: #ebebeb;
  padding: 0 16px;
`;

const rewardsDescriptionText =
  'Переходите в Playground, продавайте продукты Сбера, проходите обучение от СберАгента, приглашайте друзей составить вам компанию и получайте крутые награды за каждое ваше действие.';
const endOnboardingButtonText = 'Открыть Playground';

const OnboardingNinethPhase: StyledFC<{
  onPhaseEnd: () => void;
  onNextPhaseStart: () => void;
}> = ({ className, onPhaseEnd, onNextPhaseStart }) => {
  const [isMounted, setIsMounted] = useState(false);

  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const transitionExpText = useTransition(isMounted, {
    from: { opacity: 0, transform: 'translateY(50px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(50px)' },
  });

  const transitionReward = useTransition(isMounted, {
    from: { opacity: 0, transform: 'translateY(450px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(450px)' },
    config: config.gentle,
  });

  const transitionMedal = useTransition(isMounted, {
    from: { opacity: 0, transform: 'translateY(550px)' },
    enter: { opacity: 1, transform: 'translateY(00px)' },
    leave: { opacity: 0, transform: 'translateY(550px)' },
    config: config.gentle,
  });

  const transitionRank = useTransition(isMounted, {
    from: { opacity: 0, transform: 'translateY(650px)' },
    enter: { opacity: 1, transform: 'translateY(00px)' },
    leave: { opacity: 0, transform: 'translateY(650px)' },
    config: config.gentle,
  });

  const transitionGreetingsText = useTransition(isMounted, {
    from: { opacity: 0, transform: 'translateY(300px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(300px)' },
    config: config.gentle,
  });

  const [fadeAwayStyles, fadeAwayApi] = useSpring(() => ({
    from: {
      opacity: 1,
    },
    config: config.stiff,
  }));
  useEffect(() => {
    settingsService.finishOnboarding(false).catch((e) => {
      console.log(e);
    });
  }, []);
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isButtonClicked) {
      onNextPhaseStart();
      fadeAwayApi.start({
        to: { opacity: 0 },
      });
      timer = setTimeout(() => {
        onPhaseEnd();
      }, 400);
    }
    return () => clearTimeout(timer);
  }, [isButtonClicked]);

  return (
    <animated.div style={fadeAwayStyles}>
      {transitionExpText(
        (styles, item) =>
          item && (
            <SuccessText style={styles}>
              Супер! Все награды уже в вашем новом профиле
            </SuccessText>
          )
      )}

      {/* {transitionReward(
        (styles, item) =>
          item && (
            <Reward style={styles}>
              <StarBgWrapper>
                <Star />
              </StarBgWrapper>
              <Img src={rewardImg} />
            </Reward>
          )
      )} */}

      {transitionMedal(
        (styles, item) =>
          item && (
            <Medal style={styles}>
              <StarBgWrapper>
                <Bg />
              </StarBgWrapper>
              <Img src={medal} />
            </Medal>
          )
      )}

      {transitionRank(
        (styles, item) =>
          item && (
            <Rank style={styles}>
              <StarBgWrapper>
                <RedBg />
              </StarBgWrapper>
              <Img src={rankImg} />
            </Rank>
          )
      )}
      {transitionMedal(
        (styles, item) =>
          item && (
            <CirclesWrapper style={styles}>
              <LeftCircle />
              <RightCircle />
            </CirclesWrapper>
          )
      )}

      {transitionGreetingsText(
        (styles, item) =>
          item && (
            <GreetingsText style={styles}>
              {rewardsDescriptionText}
            </GreetingsText>
          )
      )}

      <ButtonWrapper>
        <FillableButton
          padding='12px 16px'
          onClick={() => {
            setIsButtonClicked(true);
          }}
        >
          {endOnboardingButtonText}
        </FillableButton>
      </ButtonWrapper>
    </animated.div>
  );
};

export default OnboardingNinethPhase;
