import React from 'react';
import { StyledFC } from '../../../types';
import styled from 'styled-components';

const _Divider: StyledFC<{ margin?: string }> = ({
  className,
  margin = 'none',
}) => {
  return <div className={className} />;
};

const Divider = styled(_Divider)`
  height: 0px;
  border: 1px solid #393736;
  opacity: 15%;
  margin: ${(props) => props.margin};
`;

export default Divider;
