import React from 'react';
import { StyledFC } from '../../../types';
import styled from 'styled-components';

const _Notification: StyledFC = () => {
  return (
    <div>
      <svg
        width='10'
        height='10'
        viewBox='0 0 10 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='5' cy='5' r='5' fill='#F74949' />
      </svg>
    </div>
  );
};

const Notification = styled(_Notification)``;

export default Notification;
