import React, { useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { animated, config, useSpring, useTransition } from '@react-spring/web';

import { StyledFC } from '../../../types';
import { FillableButton } from '../../MedalsNew/Button';
import AnimatingNumber from './AnimatingNumber';
import CirclesAndStars from './CirclesAndStars';
import { Star, StarGray } from './Star';
import XpBox from './XP';

const SuccessText = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 40px;
  max-width: 379px;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
`;

const NumberWrapper = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  top: 347px;
  right: 20px;
`;

const PlusSign = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: -135px;
  right: 0;
  top: 1px;
  width: fit-content;
  font-weight: 600;
  font-size: 57px;
  background: linear-gradient(98.04deg, #265ce7 84.77%, #c214ff 92.13%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const LevelBlock = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  top: 480px;
  right: 0;
  background-color: #d5d5d5;
  border-radius: 4px;
  height: 8px;
  width: 200px;
`;

const LevelProgress = styled(animated.div)`
  position: absolute;
  background: linear-gradient(90deg, #90d027 80%, #ffb11b 100%);
  height: 8px;
  width: 100%;
  border: 0px solid black;
  border-radius: 4px;
`;

const XpBoxWrapper = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: -160px;
  top: 20px;
  width: fit-content;
`;

const LevelText = styled(animated.p)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: -22px;
  top: 300px;
  width: fit-content;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
`;

const LevelNumber = styled(animated.p)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 79px;
  top: 300px;
  width: fit-content;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #bdbdbd;
`;

const LevelNumberWrapper = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 70px;
  top: 260px;
  height: 70px;
`;

const StarWrapper = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 160px;
  width: fit-content;
`;

const StarGrayWrapper = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 160px;
  width: fit-content;
`;

const ButtonWrapper = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 8%;
  width: fit-content;
  @media (max-height: 720px) {
    bottom: unset;
    top: 620px;
  }
`;

const CirclesAndStarsWrapper = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 41px;
  right: 0;
  top: 163px;
  z-index: 100;
  width: fit-content;
`;

const gotExpTitleText = 'Начисляем опыт XP и увеличивает ваш игровой уровень';
const continueButtonText = 'Далее';

const OnboardingFourthPhase: StyledFC<{
  onPhaseEnd: () => void;
  onNextPhaseStart: () => void;
}> = ({ className, onPhaseEnd, onNextPhaseStart }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isMountedAfterDelay, setIsMountedAfterDelay] = useState(false);
  const [valueSingleLevel, setValueSinglLevel] = useState('1');
  const [value, setValue] = useState('999');
  const [isLevelSecondStageStarted, setIsLevelSecondStageStarted] =
    useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const cachedAnimatingNumber = useMemo(
    () => <AnimatingNumber value={value} startAtHeightValue={630} />,
    [value]
  );

  const cachedSingleAnimatingNumber = useMemo(
    () => <AnimatingNumber value={valueSingleLevel} startAtHeightValue={0} />,
    [valueSingleLevel]
  );

  const [levelbarStyles, levelbarApi] = useSpring(() => ({
    from: {
      opacity: 0,
    },
    config: config.default,
  }));

  useEffect(() => {
    let timer: NodeJS.Timer;
    let timer2: NodeJS.Timer;

    timer = setTimeout(() => {
      setIsMounted(true);
      setIsMountedAfterDelay(true);
    }, 600);

    timer2 = setTimeout(() => {
      levelbarApi.start({
        to: {
          opacity: 1,
        },
      });
    }, 550);

    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  }, []);

  const transitionNumberWrapper = useTransition(isMounted, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const transitionExpText = useTransition(isMounted, {
    from: { opacity: 0, transform: 'translateY(50px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(50px)' },
  });

  const transitionProgress = useTransition(isMounted, {
    from: { width: '0%' },
    enter: { width: '100%' },
    leave: { width: '0%' },
    config: { duration: 1630 },
    delay: 1000,
    onRest: () => setIsLevelSecondStageStarted(true),
  });

  useEffect(() => {
    let timer1: NodeJS.Timer;
    let timer2: NodeJS.Timer;
    let timer3: NodeJS.Timer;
    let timer4: NodeJS.Timer;
    let timer5: NodeJS.Timer;
    let timer6: NodeJS.Timer;
    let timer7: NodeJS.Timer;
    let timer8: NodeJS.Timer;
    let timer9: NodeJS.Timer;
    if (isMountedAfterDelay) {
      timer1 = setTimeout(() => {
        setValue('384');
      }, 1000);

      timer2 = setTimeout(() => {
        setValue('272');
      }, 1200);

      timer3 = setTimeout(() => {
        setValue('756');
      }, 1400);

      timer4 = setTimeout(() => {
        setValue('141');
      }, 1600);

      timer5 = setTimeout(() => {
        setValue('638');
      }, 1800);

      timer6 = setTimeout(() => {
        setValue('324');
      }, 2000);

      timer7 = setTimeout(() => {
        setValue('216');
      }, 2200);

      timer8 = setTimeout(() => {
        setValue('101');
      }, 2400);

      timer9 = setTimeout(() => {
        setValue('010');
      }, 2600);
    }
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(timer4);
      clearTimeout(timer5);
      clearTimeout(timer6);
      clearTimeout(timer7);
      clearTimeout(timer8);
      clearTimeout(timer9);
    };
  }, [isMountedAfterDelay]);

  const [levelNumberFadeInStyles, levelNumberFadeInApi] = useSpring(() => ({
    from: {
      opacity: 0,
      transform: 'scale(1.3) translateY(50px) translateX(-20px)',
    },
    config: config.default,
  }));

  const [levelNumberScaleStyles, levelNumberScaleApi] = useSpring(() => ({
    from: {
      opacity: 1,
      transform: 'translateY(0px) translateX(0px)',
    },
    config: config.default,
  }));

  const [levelTextScaleStyles, levelTextScaleApi] = useSpring(() => ({
    from: {
      color: '#bdbdbd',
      transform: 'translateY(0px) translateX(0px)',
    },
    config: config.default,
  }));

  const [levelScaleStyles, levelScaleApi] = useSpring(() => ({
    from: {
      transform: 'scale(1) translateY(0px)',
    },
    config: config.default,
  }));

  const [starStyles, starApi] = useSpring(() => ({
    from: {
      opacity: 0,
      transform: 'scale(0.3) translateY(20px) translateX(0px)',
    },
    config: config.stiff,
  }));

  const [starSecondStyles, starSecondApi] = useSpring(() => ({
    from: {
      opacity: 0,
      transform: 'scale(0.6) translateY(20px) translateX(0px)',
    },
    config: config.stiff,
  }));

  const [buttonStyles, buttonApi] = useSpring(() => ({
    from: {
      opacity: 0,
    },
    config: config.default,
  }));

  const [starGrayStyles, starGrayApi] = useSpring(() => ({
    from: {
      opacity: 0,
    },
    config: config.default,
  }));

  const [circlesAndStarsStyles, circlesAndStarsApi] = useSpring(() => ({
    from: {
      opacity: 0,
      transform: 'scale(0.3) translateY(20px) translateX(0px)',
    },
    config: config.slow,
  }));

  useEffect(() => {
    let timer: NodeJS.Timer;
    let timer2: NodeJS.Timer;
    let timer3: NodeJS.Timer;

    if (isLevelSecondStageStarted) {
      timer = setTimeout(() => {
        levelNumberFadeInApi.start({
          to: {
            opacity: 1,
            transform: 'scale(1.3) translateY(0px) translateX(-9px)',
          },
        });
        levelNumberScaleApi.start({
          to: {
            opacity: 0,
            transform: 'translateY(-30px) translateX(25px)',
          },
        });
        levelTextScaleApi.start({
          to: {
            color: '#212121',
            transform: 'translateY(30px) translateX(-13px)',
          },
        });
        levelScaleApi.start({
          to: {
            transform: 'scale(0.7) translateY(255px)',
          },
        });
        buttonApi.start({
          to: {
            opacity: 1,
          },
        });
      }, 1000);

      timer2 = setTimeout(() => {
        setValueSinglLevel('2');
        starApi.start({
          to: [
            {
              opacity: 1,
              transform: 'scale(0.7) translateY(-1px) translateX(0px)',
            },
            {
              opacity: 0,
              transform: 'scale(0.7) translateY(-1px) translateX(0px)',
            },
          ],
        });
        circlesAndStarsApi.start({
          to: [
            {
              opacity: 1,
              transform: 'scale(1) translateY(0px) translateX(0px)',
            },
          ],
        });
      }, 2000);

      timer3 = setTimeout(() => {
        starSecondApi.start({
          to: [
            {
              opacity: 1,
              transform: 'scale(1) translateY(-1px) translateX(0px)',
            },
            {
              opacity: 0,
              transform: 'scale(1) translateY(-1px) translateX(0px)',
            },
          ],
          onStart: () =>
            starGrayApi.start({
              to: {
                opacity: 1,
              },
            }),
        });
      }, 2570);
    }
    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, [isLevelSecondStageStarted]);

  const [fadeAwayStyles, fadeAwayApi] = useSpring(() => ({
    from: {
      opacity: 1,
    },
    config: config.stiff,
  }));

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isButtonClicked) {
      onNextPhaseStart();
      fadeAwayApi.start({
        to: { opacity: 0 },
      });
      timer = setTimeout(() => {
        onPhaseEnd();
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [isButtonClicked]);

  return (
    <animated.div style={fadeAwayStyles}>
      {transitionExpText(
        (styles, item) =>
          item && <SuccessText style={styles}>{gotExpTitleText}</SuccessText>
      )}
      {transitionNumberWrapper(
        (styles, item) =>
          item && (
            <>
              <CirclesAndStarsWrapper style={circlesAndStarsStyles}>
                <CirclesAndStars />
              </CirclesAndStarsWrapper>
              <LevelNumberWrapper style={levelNumberFadeInStyles}>
                {cachedSingleAnimatingNumber}
              </LevelNumberWrapper>
              <StarGrayWrapper style={starGrayStyles}>
                <StarGray />
              </StarGrayWrapper>
              <StarWrapper style={starSecondStyles}>
                <Star />
              </StarWrapper>
              <StarWrapper style={starStyles}>
                <Star />
              </StarWrapper>
              <animated.div style={levelNumberScaleStyles}>
                <LevelNumber>1</LevelNumber>
              </animated.div>
              <animated.div style={levelTextScaleStyles}>
                <LevelText>уровень</LevelText>
              </animated.div>
              <animated.div style={levelScaleStyles}>
                <NumberWrapper style={styles}>
                  <PlusSign>+</PlusSign>
                  {cachedAnimatingNumber}
                  <XpBoxWrapper>
                    <XpBox />
                  </XpBoxWrapper>
                </NumberWrapper>
              </animated.div>
            </>
          )
      )}
      {
        <animated.div style={levelbarStyles}>
          <LevelBlock>
            {transitionProgress(
              (styles, item) => item && <LevelProgress style={styles} />
            )}
          </LevelBlock>
        </animated.div>
      }

      <ButtonWrapper style={buttonStyles}>
        <FillableButton
          padding='12px 16px'
          onClick={() => setIsButtonClicked(true)}
        >
          {continueButtonText}
        </FillableButton>
      </ButtonWrapper>
    </animated.div>
  );
};

export default OnboardingFourthPhase;
