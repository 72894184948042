import React from 'react';
import { useTransition, animated } from '@react-spring/web';
import styled from 'styled-components';

import { StyledFC } from '../../../types';

const Wrapper = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

const StarsAndCircles: StyledFC<{ show: boolean }> = ({ className, show }) => {
  const transition = useTransition(show, {
    from: { opacity: 0, transform: 'scale(0.1) translateY(300px)' },
    enter: { opacity: 1, transform: 'scale(1) translateY(-50px)' },
    leave: { opacity: 0, transform: 'scale(0.1) translateY(300px)' },
    config: { tension: 280, friction: 60 },
    delay: 1000,
  });

  return transition(
    (styles, item) =>
      item && (
        <Wrapper style={styles}>
          <svg
            width='452'
            height='300'
            viewBox='0 0 452 328'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect width='452' height='328' fill='none' />
            <path
              d='M359.058 301.626C359.707 301.219 360.554 301.663 360.588 302.429L360.722 305.407C360.734 305.687 360.864 305.95 361.079 306.13L363.367 308.041C363.955 308.532 363.795 309.475 363.077 309.744L360.286 310.791C360.023 310.89 359.814 311.095 359.709 311.355L358.599 314.121C358.313 314.833 357.366 314.972 356.889 314.372L355.03 312.041C354.855 311.822 354.596 311.686 354.316 311.667L351.342 311.465C350.577 311.414 350.153 310.556 350.575 309.917L352.218 307.429C352.372 307.195 352.421 306.906 352.353 306.634L351.626 303.743C351.439 303 352.123 302.331 352.862 302.535L355.735 303.329C356.006 303.403 356.295 303.361 356.533 303.212L359.058 301.626Z'
              fill='#FFB341'
            />
            <path
              d='M415.193 179.139C415.401 178.402 416.328 178.163 416.866 178.708L419.853 181.731C420.05 181.931 420.323 182.039 420.603 182.028L424.849 181.863C425.615 181.834 426.128 182.641 425.776 183.322L423.824 187.096C423.695 187.346 423.677 187.638 423.774 187.901L425.243 191.889C425.507 192.608 424.898 193.346 424.142 193.221L419.949 192.531C419.672 192.486 419.388 192.559 419.168 192.732L415.829 195.361C415.227 195.835 414.337 195.484 414.222 194.726L413.582 190.525C413.54 190.247 413.383 190 413.15 189.844L409.618 187.481C408.981 187.055 409.04 186.1 409.725 185.756L413.523 183.85C413.774 183.724 413.961 183.499 414.037 183.228L415.193 179.139Z'
              fill='#FFB341'
            />
            <circle cx='80' cy='194' r='4' fill='#FFB341' />
            <circle cx='384' cy='246' r='2' fill='#FFB341' />
          </svg>
        </Wrapper>
      )
  );
};

export default StarsAndCircles;
