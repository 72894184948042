import { connect } from 'react-redux';
import Menu from './index';
import { setSeasonName } from '../../reducers/profileReducer';

let mapStateToProps = (state: any) => {
  return {
    profileData: state.profileReducer,
  };
};

let mapDispatchToProps = (dispatch: any) => {
  return {
    setSeasonName: (data: string) => {
      dispatch(setSeasonName(data));
    },
  };
};
const MenuContainerComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu);
export default MenuContainerComponent;
