import React from 'react';
import { StyledFC } from '../../types';

const _CompletedSignSVG: StyledFC<{ color?: string; fontSize?: number }> = ({
  className,
  color = '#212121',
  fontSize = 48,
}) => {
  return (
    <svg
      width={fontSize}
      height={fontSize}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M46.9656 1.23116C48.4303 2.78062 48.3271 5.1923 46.735 6.6178L19.2987 31.9933C17.7995 33.3356 15.4935 33.3356 13.9944 31.9933L1.26505 20.3122C-0.327062 18.8867 -0.430315 16.475 1.03442 14.9256C2.49916 13.3761 4.97723 13.2756 6.56933 14.7011L16.6465 24.0076L41.4307 1.00671C43.0228 -0.418788 45.5008 -0.318301 46.9656 1.23116Z'
        fill={color}
      />
    </svg>
  );
};

export default _CompletedSignSVG;
