import React, { useContext } from 'react';
import styled from 'styled-components';

import { NavContext } from '../../main';
import { MedalsContext } from './MedalsContext';
import Header from './Header';

import { Medal } from './Grid';
import { StyledFC } from '../../types';
import Notification from '../_shared/Notification';

const StyledImg = styled.img<{ unlocked: boolean }>`
  filter: ${({ unlocked }) => (unlocked ? 'none' : 'grayscale(1)')};
  opacity: ${({ unlocked }) => (unlocked ? '1' : '0.5')};
  margin-bottom: 20px;
  object-fit: contain;
  transition: opacity 0.3s ease;
`;
const NotificationStyle = styled.div`
  position: absolute;
  margin-left: 120px;
`;
const StyledDiv = styled.div<{ unlocked: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);

    & h5 {
      transition: opacity 0.3s ease;
      opacity: 1;
    }

    & ${StyledImg} {
      opacity: ${({ unlocked }) => (unlocked ? '1' : '0.7')};
    }
  }

  & h5 {
    transition: opacity 0.3s ease;
    opacity: ${({ unlocked }) => (unlocked ? '1' : '0.5')};
  }
`;

const GridItem: StyledFC<{ data: Medal }> = ({ className, data }) => {
  const { push } = useContext(NavContext);
  const { setMedal } = useContext(MedalsContext);

  const setupMedalGlobal = () => {
    push(`/medals/${data.name}`);
    setMedal(data);
  };

  return (
    <StyledDiv onClick={setupMedalGlobal} unlocked={data.earned}>
      <NotificationStyle>
        {(data.isNew || data.isNewAdded) && <Notification />}
      </NotificationStyle>
      <StyledImg
        unlocked={data.earned}
        src={data.img}
        alt=''
        width='116'
        height='116'
      />
      <Header type='h5' size={11}>
        {data.name}
      </Header>
    </StyledDiv>
  );
};

export default GridItem;
