import React from 'react';
import { StyledFC } from '../../types';

const _Accept: StyledFC<{ color?: string }> = ({
  className,
  color = 'white',
}) => {
  return (
    <svg
      width='14'
      height='11'
      viewBox='0 0 14 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.52036 10.1064C4.12296 10.1065 3.74183 9.94859 3.46106 9.66735L0.258452 6.46593C-0.0861507 6.12122 -0.0861507 5.56244 0.258452 5.21773C0.603165 4.87312 1.16194 4.87312 1.50666 5.21773L4.52036 8.23143L12.4933 0.258452C12.838 -0.0861506 13.3968 -0.0861506 13.7415 0.258452C14.0861 0.603165 14.0861 1.16194 13.7415 1.50666L5.57966 9.66735C5.29889 9.94859 4.91776 10.1065 4.52036 10.1064Z'
        fill={color}
      />
    </svg>
  );
};

export default _Accept;
