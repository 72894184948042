import React from 'react';
import { StyledFC } from '../../../types';
import styled from 'styled-components';
import SimpleIconContainer from '../SimpleIconContainer';
import ArrowRightSVG from '../../../iconsSource/ArrowRightSVG';

const _ArrowRightIcon: StyledFC<{ size: number; color: string }> = ({
  className,
  size,
  color,
}) => {
  return (
    <SimpleIconContainer color={color} size={size}>
      <ArrowRightSVG color={color} />
    </SimpleIconContainer>
  );
};

const ArrowRightIcon = styled(_ArrowRightIcon)``;

export default ArrowRightIcon;
