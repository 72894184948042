import React from 'react';
import { StyledFC } from '../../../types';
import styled from 'styled-components';

const _CircleIconContainer: StyledFC<{
  size: number;
  disabled?: boolean;
  cursor?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  backgroundColor?: string;
}> = ({
  className,
  children,
  size,
  disabled,
  cursor = 'inherit',
  onClick,
  backgroundColor = '#212121',
}) => {
  return (
    <div className={className} onClick={onClick ? onClick : undefined}>
      {children}
    </div>
  );
};

const CircleIconContainer = styled(_CircleIconContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex-shrink: 0;
  background-color: ${(props) =>
    `${props.disabled ? '#353535' : props.backgroundColor}`};
  color: ${(props) => `${props.disabled ? '#535353' : '#27c498'}`};
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  transition: all 0.1s ease;
  border-radius: ${(props) => `${props.size / 2}px`};
  cursor: ${(props) => props.cursor};

  :hover {
    ${(props) =>
      !props.disabled &&
      'border: 2px solid #212121; background-color: #EBEBEB;'}
  }
`;

export default CircleIconContainer;
