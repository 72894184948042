import React from 'react';
import { StyledFC } from '../../../types';
import styled from 'styled-components';
import SimpleIconContainer from '../SimpleIconContainer';
import SeasonIconSVG from '../../../iconsSource/SeasonIconSVG';

const _SeasonIcon: StyledFC<{ size: number; color: string }> = ({
  className,
  size,
  color,
}) => {
  return (
    <SimpleIconContainer className={className} color={color} size={size}>
      <SeasonIconSVG />
    </SimpleIconContainer>
  );
};

const SeasonIcon = styled(_SeasonIcon)``;

export default SeasonIcon;
