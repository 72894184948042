import React from 'react';

import { StyledFC } from '../../../../types';

const Circles: StyledFC = ({ className }) => {
  return (
    <svg
      width='73px'
      height='73px'
      viewBox='0 0 585 518'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <circle
          id='Oval'
          stroke='#90D128'
          fill='#90D128'
          cx='513'
          cy='52'
          r='7.5'
        ></circle>
        <circle
          id='Oval'
          stroke='#90D128'
          fill='#90D128'
          cx='8'
          cy='333'
          r='7.5'
        ></circle>
        <circle
          id='Oval'
          stroke='#90D128'
          fill='#90D128'
          cx='118'
          cy='288'
          r='7.5'
        ></circle>
        <circle
          id='Oval'
          stroke='#90D128'
          fill='#90D128'
          cx='494'
          cy='414'
          r='7.5'
        ></circle>
        <circle
          id='Oval'
          stroke='#90D128'
          fill='#90D128'
          cx='564.5'
          cy='31.5'
          r='20'
        ></circle>
        <circle
          id='Oval'
          stroke='#90D128'
          fill='#90D128'
          cx='528.5'
          cy='85.5'
          r='15'
        ></circle>
        <circle
          id='Oval'
          stroke='#90D128'
          fill='#90D128'
          cx='475'
          cy='96'
          r='10.5'
        ></circle>
        <circle
          id='Oval'
          stroke='#90D128'
          fill='#90D128'
          cx='129.722513'
          cy='59.8429319'
          r='7.5'
        ></circle>
        <circle
          id='Oval'
          stroke='#90D128'
          fill='#90D128'
          cx='219.10733'
          cy='498.89267'
          r='18'
        ></circle>
        <circle
          id='Oval'
          stroke='#90D128'
          fill='#90D128'
          cx='94.026178'
          cy='16.0680628'
          r='15.0811518'
        ></circle>
        <circle
          id='Oval'
          stroke='#90D128'
          fill='#90D128'
          cx='248.502618'
          cy='450.942408'
          r='10.5575916'
        ></circle>
      </g>
    </svg>
  );
};

export default Circles;
