import React, { useEffect, useRef, useState } from 'react';
import { useSpring, useTransition, animated, config } from '@react-spring/web';
import styled from 'styled-components';
import Spinner from './Spinner';
import { StyledFC } from '../../../types';
import store from '../../../store/store';
import { tokenCookies } from '../../../utils';
import { FillableButton } from '../../MedalsNew/Button';

const SpinnerBlock = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #ebebeb;
`;

const LoadingText = styled.p`
  margin-top: 20px;
`;

// const AvatarEditorWrapper = styled(animated.div)`
//   position: absolute;
//   margin-left: auto;
//   margin-right: auto;
//   left: 0;
//   right: 0;
//   opacity: 0;
// `;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 8%;
`;

const avatarLoadingText_1 = 'Подбираем награду';
const avatarLoadingText_2 = 'Готовим новые квесты';
const avatarLoadingText_3 = 'Почти готово';

const OnboardingSecondPhase: StyledFC<{
  onPhaseEnd: () => void;
  onNextPhaseStart: () => void;
  onSkipPhase: () => void;
}> = ({ className, onPhaseEnd, onNextPhaseStart, onSkipPhase }) => {
  const [loaded, setLoaded] = useState<any>(
    localStorage.getItem('avatarLoaded')
  );

  const iFrameRef = useRef<any>();
  const [isMounted, setIsMounted] = useState(false);
  const [isLoadingTextFirstShown, setIsLoadingTextFirstShown] = useState(false);
  const [isLoadingTextSecondShown, setIsLoadingTextSecondShown] =
    useState(false);
  const [isLoadingTextThirdShown, setIsLoadingTextThirdShown] = useState(false);
  const [isLoadingCompleted, setIsLoadingCompleted] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  // const [avatarWrapperStyles, avatarWraperApi] = useSpring(() => ({
  //   from: {
  //     opacity: 0,
  //   },
  // }));
  let userToken: any;
  if (!tokenCookies.get('token')) {
    userToken = localStorage.getItem('userToken');
  } else {
    userToken = tokenCookies.get('token');
  }
  useEffect(() => {
    let timer: NodeJS.Timer;
    let timer2: NodeJS.Timer;
    let timer3: NodeJS.Timer;
    let timer4: NodeJS.Timer;
    timer = setTimeout(() => {
      setIsMounted(true);
      setIsLoadingTextFirstShown(true);
    }, 100);
    timer2 = setTimeout(() => {
      setIsLoadingTextFirstShown(false);
      setIsLoadingTextSecondShown(true);
    }, 2300);
    timer3 = setTimeout(() => {
      setIsLoadingTextSecondShown(false);
      setIsLoadingTextThirdShown(true);
    }, 3300);
    timer4 = setTimeout(() => {
      setIsMounted(false);
      setIsLoadingCompleted(true);
      // avatarWraperApi.start({
      //   to: { opacity: 1 },
      // });
    }, 4300);
    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(timer4);
    };
  }, []);

  const transitionSpinnerWrapper = useTransition(isMounted, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const [fadeAwayStyles, fadeAwayApi] = useSpring(() => ({
    from: {
      opacity: 1,
    },
    config: config.stiff,
  }));

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isLoadingCompleted) {
      onNextPhaseStart();
      fadeAwayApi.start({
        to: { opacity: 0 },
      });
      timer = setTimeout(() => {
        onPhaseEnd();
      }, 500);
    }
    return () => clearTimeout(timer);
  }, [isLoadingCompleted]);

  const listener = function (event: StorageEvent) {
    if (event.key === 'backBtnClick') {
      setIsButtonClicked(true);
    }
    if (event.key === 'avatarLoaded') {
      setIsMounted(false);
      // avatarWraperApi.start({
      //   to: { opacity: 1 },
      // });
    }
  };
  useEffect(() => {
    window.addEventListener('storage', listener);

    return () => window.removeEventListener('storage', listener);
  }, []);
  function sendToken() {
    const token = {
      token: userToken,
    };
    iFrameRef.current.contentWindow.postMessage(token, '*');
  }

  useEffect(() => {
    setLoaded(localStorage.getItem('avatarLoaded'));
    if (loaded === 'true') {
      // Упаковано для Артаса от Тралла
      setIsMounted(false);
      // avatarWraperApi.start({
      //   to: { opacity: 1 },
      // });
      // ++++++++++++++++++++++++++++++
    }
  });

  return (
    <animated.div style={fadeAwayStyles}>
      {transitionSpinnerWrapper(
        (styles, item) =>
          item && (
            <SpinnerBlock style={styles}>
              <Spinner />
              {isLoadingTextFirstShown && (
                <LoadingText>{avatarLoadingText_1}</LoadingText>
              )}
              {isLoadingTextSecondShown && (
                <LoadingText>{avatarLoadingText_2}</LoadingText>
              )}
              {isLoadingTextThirdShown && (
                <>
                  <LoadingText>{avatarLoadingText_3}</LoadingText>
                  {/* <ButtonWrapper>
                    <FillableButton onClick={onSkipPhase} padding='12px 16px'>
                      Пропустить
                    </FillableButton>
                  </ButtonWrapper> */}
                </>
              )}
            </SpinnerBlock>
          )
      )}

      {/* <AvatarEditorWrapper style={avatarWrapperStyles}>
        <iframe
          onLoad={sendToken}
          ref={iFrameRef}
          id='avatar_iframe'
          src='pg_icon/index.html'
          style={{
            boxSizing: 'border-box',
            width: '100%',
            height: '870px',
            margin: 0,
            padding: '0 0 50px 0',
            border: 'none',
          }}
          sandbox='allow-same-origin allow-scripts'
        />
      </AvatarEditorWrapper> */}
    </animated.div>
  );
};

export default OnboardingSecondPhase;
