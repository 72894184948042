import React from 'react';

import { StyledFC } from '../../../../types';

export const Star: StyledFC = ({ className }) => {
  return (
    <svg
      width='264'
      height='258'
      viewBox='0 0 264 258'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_479_5139'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='1'
        width='264'
        height='253'
      >
        <path
          d='M122.576 14.6715C125.712 9.78924 127.28 7.34809 129.274 6.50511C131.017 5.76856 132.983 5.76856 134.726 6.50511C136.72 7.34809 138.288 9.78923 141.424 14.6715L177.37 70.6361C178.242 71.9935 178.678 72.6722 179.236 73.2215C179.732 73.7086 180.297 74.1193 180.913 74.4399C181.608 74.8015 182.389 75.0064 183.949 75.4161L248.282 92.309C253.895 93.7827 256.701 94.5196 258.119 96.1557C259.358 97.5852 259.965 99.4552 259.803 101.34C259.618 103.497 257.781 105.743 254.107 110.234L211.989 161.715C210.967 162.963 210.457 163.588 210.107 164.289C209.797 164.91 209.581 165.575 209.466 166.26C209.337 167.033 209.384 167.838 209.476 169.449L213.29 235.854C213.623 241.647 213.789 244.543 212.671 246.398C211.695 248.018 210.104 249.173 208.261 249.602C206.153 250.092 203.449 249.039 198.042 246.932L136.066 222.784C134.563 222.199 133.811 221.906 133.036 221.79C132.349 221.687 131.651 221.687 130.964 221.79C130.189 221.906 129.437 222.199 127.934 222.784L65.9576 246.932C60.5509 249.039 57.8475 250.092 55.7387 249.602C53.8961 249.173 52.3054 248.018 51.3287 246.398C50.2108 244.543 50.3772 241.647 50.7099 235.854L54.524 169.449C54.6165 167.838 54.6627 167.033 54.5336 166.26C54.4191 165.575 54.2033 164.91 53.8931 164.289C53.5433 163.588 53.0325 162.963 52.011 161.715L9.89351 110.234C6.21921 105.743 4.38206 103.497 4.19657 101.34C4.0345 99.4552 4.64212 97.5852 5.88105 96.1557C7.299 94.5196 10.1052 93.7827 15.7176 92.309L80.0511 75.4161C81.6115 75.0064 82.3917 74.8015 83.0867 74.4399C83.7031 74.1193 84.2683 73.7086 84.7637 73.2215C85.3224 72.6722 85.7583 71.9935 86.6302 70.6361L122.576 14.6715Z'
          stroke='#ebebeb'
          strokeWidth='8'
        />
      </mask>
      <g mask='url(#mask0_479_5139)'>
        <rect
          x='-8'
          y='290'
          width='493'
          height='272'
          transform='rotate(-90 -8 290)'
          fill='url(#paint0_linear_479_5139)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_479_5139'
          x1='20.5168'
          y1='422.761'
          x2='479.366'
          y2='424.415'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='.09' stopColor='#3CBAFF'>
            <animate
              attributeName='stop-color'
              values='rgba(155,122,222,.1);rgba(155,122,222,.1);rgba(155,122,222,.1);rgba(155,122,222,.1);#FF8514;#3CBAFF;'
              dur='1s'
              repeatCount='indefinite'
            ></animate>
          </stop>
          <stop offset='.2' stopColor='#9536DF'>
            <animate
              attributeName='stop-color'
              values='#9536DF;rgba(155,122,222,.1);#C214FF;rgba(155,122,222,.1);#3CBAFF;#9536DF'
              dur='1s'
              repeatCount='indefinite'
            ></animate>
          </stop>
          <stop offset='.4' stopColor='#265CE7'>
            <animate
              attributeName='stop-color'
              values='#265CE7;rgba(155,122,222,.1);#FF8514;rgba(155,122,222,.1);#9536DF;#265CE7'
              dur='1s'
              repeatCount='indefinite'
            ></animate>
          </stop>
          <stop offset='.6' stopColor='#C214FF'>
            <animate
              attributeName='stop-color'
              values='#C214FF;rgba(155,122,222,.1);rgba(155,122,222,.1);#9536DF;#265CE7;#C214FF'
              dur='1s'
              repeatCount='indefinite'
            ></animate>
          </stop>
          <stop offset='.8' stopColor='#FF8514'>
            <animate
              attributeName='stop-color'
              values='#FF8514;rgba(155,122,222,.1);#9536DF;rgba(155,122,222,.1);#C214FF;#FF8514'
              dur='1s'
              repeatCount='indefinite'
            ></animate>
          </stop>
          <stop offset='1' stopColor='#3CBAFF'>
            <animate
              attributeName='stop-color'
              values='#3CBAFF;#9536DF;rgba(155,122,222,.1);rgba(155,122,222,.1);rgba(155,122,222,.1);#3CBAFF;'
              dur='1s'
              repeatCount='indefinite'
            ></animate>
          </stop>
          <animateTransform
            attributeName='transform'
            type='translate'
            from='2000'
            to='0'
            dur='1s'
            repeatCount='indefinite'
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const StarGray: StyledFC = ({ className }) => {
  return (
    <svg
      width='264'
      height='258'
      viewBox='0 0 264 258'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_479_513'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='1'
        width='264'
        height='253'
      >
        <path
          d='M122.576 14.6715C125.712 9.78924 127.28 7.34809 129.274 6.50511C131.017 5.76856 132.983 5.76856 134.726 6.50511C136.72 7.34809 138.288 9.78923 141.424 14.6715L177.37 70.6361C178.242 71.9935 178.678 72.6722 179.236 73.2215C179.732 73.7086 180.297 74.1193 180.913 74.4399C181.608 74.8015 182.389 75.0064 183.949 75.4161L248.282 92.309C253.895 93.7827 256.701 94.5196 258.119 96.1557C259.358 97.5852 259.965 99.4552 259.803 101.34C259.618 103.497 257.781 105.743 254.107 110.234L211.989 161.715C210.967 162.963 210.457 163.588 210.107 164.289C209.797 164.91 209.581 165.575 209.466 166.26C209.337 167.033 209.384 167.838 209.476 169.449L213.29 235.854C213.623 241.647 213.789 244.543 212.671 246.398C211.695 248.018 210.104 249.173 208.261 249.602C206.153 250.092 203.449 249.039 198.042 246.932L136.066 222.784C134.563 222.199 133.811 221.906 133.036 221.79C132.349 221.687 131.651 221.687 130.964 221.79C130.189 221.906 129.437 222.199 127.934 222.784L65.9576 246.932C60.5509 249.039 57.8475 250.092 55.7387 249.602C53.8961 249.173 52.3054 248.018 51.3287 246.398C50.2108 244.543 50.3772 241.647 50.7099 235.854L54.524 169.449C54.6165 167.838 54.6627 167.033 54.5336 166.26C54.4191 165.575 54.2033 164.91 53.8931 164.289C53.5433 163.588 53.0325 162.963 52.011 161.715L9.89351 110.234C6.21921 105.743 4.38206 103.497 4.19657 101.34C4.0345 99.4552 4.64212 97.5852 5.88105 96.1557C7.299 94.5196 10.1052 93.7827 15.7176 92.309L80.0511 75.4161C81.6115 75.0064 82.3917 74.8015 83.0867 74.4399C83.7031 74.1193 84.2683 73.7086 84.7637 73.2215C85.3224 72.6722 85.7583 71.9935 86.6302 70.6361L122.576 14.6715Z'
          stroke='#FFB341'
          strokeWidth='8'
        />
      </mask>
      <g mask='url(#mask0_479_513)'>
        <rect
          x='-8'
          y='290'
          width='493'
          height='272'
          transform='rotate(-90 -8 290)'
          fill='url(#paint0_linear_479_513)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_479_513'
          x1='20.5168'
          y1='422.761'
          x2='479.366'
          y2='424.415'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.03125' stopColor='white' stopOpacity='0' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
      </defs>
    </svg>
  );
};
