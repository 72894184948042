import React from 'react';
import { StyledFC } from '../../types';

const _CompletedIcon: StyledFC<{ color?: string; fontSize?: number }> = ({
  className,
  color = '#212121',
  fontSize = 16,
}) => {
  return (
    <svg
      width={fontSize}
      height={fontSize}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='18' cy='18' r='18' fill='#90D128' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.985 12.4844C27.5459 13.0941 27.5064 14.043 26.8967 14.6039L16.3905 24.5884C15.8165 25.1165 14.9334 25.1165 14.3594 24.5884L9.48491 19.9922C8.87525 19.4313 8.83571 18.4824 9.3966 17.8728C9.95749 17.2631 10.9064 17.2235 11.5161 17.7844L15.3749 21.4463L24.8655 12.3961C25.4752 11.8352 26.4241 11.8748 26.985 12.4844Z'
        fill='white'
      />
    </svg>
  );
};

export default _CompletedIcon;
