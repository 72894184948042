import React from 'react';
import { StyledFC } from '../../types';

const _CloseIcon: StyledFC<{ color?: string; fontSize?: number }> = ({
  className,
  color = 'white',
  fontSize = 16,
}) => {
  return (
    <svg
      width={fontSize}
      height={fontSize}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.14285 1.14282L8 7.99997M14.8571 14.8571L8 7.99997M8 7.99997L14.8571 1.14282M8 7.99997L1.14285 14.8571'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default _CloseIcon;
