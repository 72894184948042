import React, { useState } from 'react';
import { StyledFC } from '../../../types';
import styled from 'styled-components';
import CameraIconSVG from '../../../iconsSource/CameraIconSVG';
import CircleIconContainer from '../CircleIconContainer';

const _CameraIcon: StyledFC<{
  size: number;
  fontSize?: number;
  color: string;
  backgroundColor?: string;
  onClick?: () => void;
}> = ({
  className,
  size,
  color,
  backgroundColor = '#ffffff',
  fontSize = 16,
}) => {
  return (
    <CircleIconContainer
      className={className}
      size={size}
      backgroundColor={backgroundColor}
    >
      <CameraIconSVG fontSize={fontSize} color={color} />
    </CircleIconContainer>
  );
};

const CameraIcon = styled(_CameraIcon)``;

export default CameraIcon;
