import React from 'react';
import { StyledFC } from '../../../types';
import styled from 'styled-components';
import { kMaxLength } from 'buffer';

const _TextInput: StyledFC<{
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number;
  placeholder?: string;
}> = ({ className, value, onChange, maxLength = 999, placeholder = '' }) => {
  return (
    <div className={className}>
      <input
        type='text'
        maxLength={maxLength}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

const TextInput = styled(_TextInput)`
  border: solid 0px black;
  border-radius: 6px;
  width: 100%;

  & > input {
    outline: none;
    width: calc(100% - 20px);
    background-color: #ebebeb;
    border: solid 0px black;
    border-radius: 6px;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: #5c5c5c;
  }

  & > input:hover,
  input:focus {
    color: #212121;
  }
`;

export default TextInput;
