import { API } from '../_api/axios';

export const profileService = {
  setGender: (value: number) =>
    API.post('profile/avatar/gender', {
      gender: value,
    }),
  prevUserState: () => API.get('user/prev-user-state'),
  gottenRanks: () => API.get('ranks/gotten-ranks'),
  getProfile: () => API.get<any>('profile'),
  getAvatarVideo: () => API.get<any>('profile/avatar/video'),
  setNickname: (props: string) => API.post('profile/nickname', { name: props }),
  saveItems: (itemData: string) =>
    API.post('profile/avatar/items', {
      gender: 0,
      itemIds: itemData,
      level: 1,
    }),
};
