import { API } from '../_api/axios';

export const seasonServices = {
  completeAwards: () => API.get('season/complete-awards'),
  setOpenDesc: (awardId: any) => API.post(`season/awards-open-desc/${awardId}`),
  getSeasons: () => API.get('season'),
  getSeasonInfo: () => API.get('season/info'),
  takeReward: (id: any) => API.post(`/season/complete/${id}`),
  takeMedal: (medalPlaybookId: number) =>
    API.post(`medal/take`, {
      playbookId: medalPlaybookId,
    }),
};
