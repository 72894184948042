import {connect} from "react-redux";
import {setProfileVideo} from "../../reducers/profileReducer";
import Header from "./header";




let mapStateToProps = (state:any) => {
    return{
        profileData: state.profileReducer,
    }
}


let mapDispatchToProps = (dispatch:any) => {
    return{
        setVideo: (data:any) =>{
            dispatch(setProfileVideo(data))
        }
    }
}
const HeaderContainer = connect(mapStateToProps,mapDispatchToProps)(Header);
export default HeaderContainer;