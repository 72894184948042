import React from 'react';
import { StyledFC } from '../../types';

const _ArrowRightIcon: StyledFC<{ color?: string }> = ({
  className,
  color = '#212121',
}) => {
  return (
    <svg
      width='7'
      height='12'
      viewBox='0 0 7 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 11L6 6L1 1'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default _ArrowRightIcon;
