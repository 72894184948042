import { profileService } from '../services';

const setProfile = 'setProfileData';
const setVideo = 'setVideoAvatar';
const setNickname = 'setNickname';
const postNickname = 'postNickname';
const setRank = 'setRank';
const setToken = 'setToken';
const turnOffOB = 'turnOffOB';
const SET_SEASON_NAME = 'setSeasonName';

let InitialState = {
  seasonName: '',
  level: 0,
  totalExp: 0,
  earnedExp: 0,
  rankName: '',
  rankMedal: '',
  rankId: 0,
  name: {
    firstName: '',
    lastName: '',
    middleName: '',
  },
  nickname: '',
  useNickName: true,
  showOnboarding: false,
  videoSrc: '',
  token: '123',
};
const profileReducer = (state = InitialState, action: any) => {
  switch (action.type) {
    case setProfile: {
      let copyState = { ...state };
      copyState.level = action.data.level.level;
      copyState.totalExp = action.data.level.totalExp;
      copyState.earnedExp = action.data.level.earnedExp;
      copyState.rankName = action.data.rank.name;
      copyState.rankMedal = action.data.rank.medalSrc;
      copyState.rankId = action.data.rank.id;
      copyState.name = action.data.name;
      copyState.nickname = action.data.nickname;
      copyState.useNickName = action.data.useNickname;
      copyState.showOnboarding = action.data.showOnboarding;
      return copyState;
    }
    case setVideo: {
      let copyState = { ...state };
      copyState.videoSrc = action.data;
      return copyState;
    }
    case setRank: {
      let copyState = { ...state };
      copyState.rankId = action.data.id;
      copyState.rankName = action.data.name;
      copyState.rankMedal = action.data.medalSrc;
      return copyState;
    }
    case setNickname: {
      let copyState = { ...state };
      copyState.nickname = action.data;
      return copyState;
    }
    case setToken: {
      let copyState = { ...state };
      copyState.token = action.data;
      return copyState;
    }
    case turnOffOB: {
      let copyState = { ...state };
      copyState.showOnboarding = action.data;
      return copyState;
    }
    case SET_SEASON_NAME: {
      let copyState = { ...state };
      copyState.seasonName = action.data;
      return copyState;
    }
    case postNickname: {
      let copyState = { ...state };
      profileService
        .setNickname(copyState.nickname)
        .then(() => {
          //@ts-ignore
          window.ym(88659232, 'reachGoal', 'changeNickname');
        })
        .catch((e: any) => {
          console.log(e);
        });
      return copyState;
    }

    default:
      return state;
  }
};
export const setProfileRank = (data: any) => ({ type: setRank, data });
export const postProfileNickname = () => ({ type: postNickname });
export const setProfileNickname = (data: any) => ({ type: setNickname, data });
export const setProfileData = (data: any) => ({ type: setProfile, data });
export const setProfileVideo = (data: any) => ({ type: setVideo, data });
export const setProfileToken = (data: any) => ({ type: setToken, data });
export const turnOffOnboarding = (data: any) => ({ type: turnOffOB, data });
export const setSeasonName = (data: string) => ({
  type: SET_SEASON_NAME,
  data,
});
export default profileReducer;
