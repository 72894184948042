import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTransition, animated } from '@react-spring/web';

import { NavContext } from '../../main';
import { RoundButton } from '../MedalsNew/Button';
import Flex from '../MedalsNew/Flex';
import Header from '../MedalsNew/Header';
import Icon from '../MedalsNew/Icon';

import { StyledFC } from '../../types';

const _SideDrawer: StyledFC<{
  preloadable?: boolean;
  routeSettings: string;
  isRouteTheSame?: boolean;
  header?: string;
  onClick: () => void;
}> = ({ className, children, preloadable, routeSettings, onClick, header }) => {
  const { route } = useContext(NavContext);

  const testRoute =
    route === routeSettings ||
    (route.includes(routeSettings.split('/')[1]) &&
      routeSettings.split('/').length < 3);

  const isRouteTheSame = testRoute ? true : false;

  const transitionContent = useTransition(isRouteTheSame, {
    from: { x: -452 },
    enter: { x: 0 },
    leave: { x: -452 },
    delay: 100,
  });

  return !preloadable ? (
    transitionContent(
      (styles, item) =>
        item && (
          <animated.div style={styles} className={className}>
            <RoundButton onClick={onClick}>
              <Icon />
            </RoundButton>
            {header && (
              <Flex>
                <Header height='44px'>{header}</Header>
              </Flex>
            )}
            {children}
          </animated.div>
        )
    )
  ) : (
    <div
      style={{
        transform: `translateX(${isRouteTheSame ? 0 : -452}px)`,
        transition: 'transform 0.4s ease',
        willChange: `transform`,
      }}
      className={className}
    >
      <RoundButton onClick={onClick}>
        <Icon />
      </RoundButton>
      {header && (
        <Flex>
          <Header height='44px'>{header}</Header>
        </Flex>
      )}
      {children}
    </div>
  );
};

const SideDrawer = styled(_SideDrawer)`
  position: absolute;
  box-sizing: border-box;
  z-index: 10;
  width: 452px;
  margin-left: -16px;
  padding: 24px 16px;
  height: 100vh;
  background-color: #f4f4f4;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  will-change: transform;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

export default SideDrawer;
