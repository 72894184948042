import { API } from '../_api/axios';
import { Task } from '../components/TasksNew';

export const taskService = {
  checkTasks: (arrayTaskIds: any) =>
    API.post('/tasks/progress/checked', arrayTaskIds),
  completeTask: (awardId: number) => API.post(`/tasks/complete/${awardId}`),
  getTasks: (pageSize: number, category: string) =>
    API.get<Task[]>(`/tasks?filter=${category}&page=1&page_size=${pageSize}`),
  getTasksInfo: () => API.get('user/tasks/info'),
};
