import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import {
  useTransition,
  useSpring,
  animated,
  config,
  SpringValue,
} from '@react-spring/web';

import StarsAndCircles from './StarsAndCircles';
import { FillableButton } from '../../MedalsNew/Button';
import { profileService, seasonServices } from '../../../services';
import { NavContext } from '../../../main';
import { API } from '../../../_api/axios';

const MainBlock = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 400px;
  text-align: center;
  text-decoration: none;

  a {
    text-decoration: none;
    color: black;
  }
  .date {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #bdbdbd;
  }
  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.02em;
    padding: 24px 24px 24px 24px;
    color: #212121;
  }
  .descriptionMoney {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.02em;
    padding: 24px 24px 24px 24px;
    color: #212121;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #212121;
  }
`;

const ButtonWrapper = styled(animated.div)`
  position: absolute;
  width: 113px;
  left: calc(50% - 113px / 2);
  top: 400px;
`;

const RewardImage = styled(animated.img)<{ filter: string }>`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 95px;
  width: 209px;
  filter: ${(props) => props.filter};
`;

const Badge = styled(animated.div)`
  position: relative;
  margin: -49px auto;
  width: 155px;
  height: 330px;
  border-bottom-right-radius: 28px;
  border-bottom-left-radius: 28px;
  overflow: hidden;
`;

const BadgeBackground = styled(animated.div)<{ filter: string }>`
  position: absolute;
  background-size: 100% 100%;
  filter: ${(props) => props.filter};
  background: linear-gradient(190deg, #dfff1d 12.71%, #90d128 74.44%);
  width: 100%;
  height: 330px;
`;

let rewardData: any;

export const setRewardData = (props: any) => {
  rewardData = props;
};

export const SeasonReward = () => {
  const { push, route } = useContext(NavContext);
  const [isMounted, setIsMounted] = useState(false);
  const [medals, setMedals] = useState([]);
  const [medalDesription, setMedalDescription] = useState<string>('');
  const [badgeStyles, setBadgeStyles] =
    useState<{ backgroundSize: SpringValue<string> }>();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const transitionBadge = useTransition(isMounted, {
    from: { y: -310 },
    enter: { y: 0 },
    leave: { x: -310 },
    delay: 1000,
    config: config.stiff,
  });

  const transitionReward = useTransition(isMounted, {
    from: { opacity: 0, transform: 'scale(0.3)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0.3)' },
    delay: 1000,
    config: config.stiff,
  });

  const transitionText = useTransition(isMounted, {
    from: { opacity: 0, transform: 'translateY(300px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(300px)' },
    delay: 1000,
    config: config.gentle,
  });

  const badgeBgStyles = useSpring({
    from: { backgroundSize: '100% 100%' },
    to: [{ backgroundSize: '100% 300%' }, { backgroundSize: '100% 100%' }],
    config: { duration: 500 },
    reset: true,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setBadgeStyles(badgeBgStyles);
    }, 2700);

    return () => clearTimeout(timer);
  }, []);

  const transitionButton = useTransition(isMounted, {
    from: { opacity: 0, transform: 'translateY(300px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(300px)' },
    delay: 1000,
    config: config.gentle,
  });
  //@ts-ignore
  window.ym(88659232, 'reachGoal', 'openSeasonReward');
  useEffect(() => {
    API.get('/medals?filter=ALL&page=1&page_size=9999').then((response) => {
      setMedals(response.data);
    });
  }, []);
  useEffect(() => {
    if (medals) {
      const currentMedal: any = medals.filter(
        (item: any) => item.id === rewardData.playbookId
      );
      if (currentMedal) {
        setMedalDescription(currentMedal[0]?.descriptionToReceive);
      }
    }
  }, [medals]);
  useEffect(() => {
    seasonServices.setOpenDesc(rewardData.award);
  }, []);
  return (
    <>
      {rewardData.status === 'EARNED' ? (
        <>
          <StarsAndCircles show={isMounted} />
          {transitionBadge(
            (styles, item) =>
              item && (
                <Badge style={styles}>
                  <BadgeBackground
                    style={badgeStyles}
                    filter={
                      rewardData.status === 'EARNED'
                        ? 'grayscale(0%)'
                        : 'grayscale(100%)'
                    }
                  />
                </Badge>
              )
          )}
        </>
      ) : (
        <Badge>
          <BadgeBackground
            filter={
              rewardData.status === 'EARNED'
                ? 'grayscale(0%)'
                : 'grayscale(100%)'
            }
          />
        </Badge>
      )}
      {rewardData.status === 'EARNED' ? (
        transitionReward(
          (styles, item) =>
            item && (
              <RewardImage
                style={styles}
                filter={
                  rewardData.status === 'LOCKED'
                    ? 'grayscale(100%)'
                    : 'grayscale(0%)'
                }
                src={rewardData.imageFileKey}
              />
            )
        )
      ) : (
        <RewardImage
          filter={
            rewardData.status === 'LOCKED' ? 'grayscale(100%)' : 'grayscale(0%)'
          }
          src={rewardData.imageFileKey}
        />
      )}

      <MainBlock>
        {rewardData.status === 'EARNED' || rewardData.status === 'TAKEN' ? (
          transitionText(
            (styles, item) =>
              item && (
                <animated.div style={styles} className={'title'}>
                  {rewardData.title}
                  <div className={'description'}>{rewardData.desc}</div>
                  {rewardData.title === '1000 рублей от СберАгента' && (
                    <div className={'descriptionMoney'}>
                      Начислим 1000 рублей на ваш счет в СберАгенте
                    </div>
                  )}
                </animated.div>
              )
          )
        ) : (
          <>
            <div className={'title'}>{rewardData.title}</div>
            {rewardData.link === 'MEDAL_REWARD' ? (
              <>
                <div className={'description'}>{medalDesription}</div>{' '}
                {rewardData.title === '1000 рублей от СберАгента' && (
                  <div className={'descriptionMoney'}>
                    Начислим 1000 рублей на ваш счет в СберАгенте
                  </div>
                )}
              </>
            ) : (
              <div className={'description'}>{rewardData.desc}</div>
            )}
          </>
        )}
        <div className={'description'}>
          <div>
            {rewardData.link && rewardData.status === 'EARNED' && (
              <div>
                {transitionButton(
                  (styles, item) =>
                    item && (
                      <ButtonWrapper style={styles}>
                        <FillableButton
                          padding='12px 16px'
                          onClick={() => {
                            //@ts-ignore
                            window.ym(88659232, 'reachGoal', 'takeReward');
                            if (rewardData.link === 'MEDAL_REWARD') {
                              rewardData.takeReward();
                              push('/seasons');
                            } else if (rewardData.link === 'ITEM_REWARD') {
                              profileService.saveItems(rewardData.award);
                              rewardData.takeReward();
                              push('/seasons');
                            } else {
                              rewardData.takeReward();
                              window.location.assign(rewardData.link);
                            }
                          }}
                        >
                          Супер!
                        </FillableButton>
                      </ButtonWrapper>
                    )
                )}
              </div>
            )}
          </div>
        </div>
      </MainBlock>
    </>
  );
};
