import { connect } from 'react-redux';
import AppContainer from './index';
import { setProfileData } from '../../reducers/profileReducer';

let mapStateToProps = (state: any) => {
  return {
    profileData: state.profileReducer,
  };
};

let mapDispatchToProps = (dispatch: any) => {
  return {
    setProfile: (data: any) => {
      dispatch(setProfileData(data));
    },
  };
};
const AppContainerComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppContainer);
export default AppContainerComponent;
