import React, { Fragment } from 'react';

import RoundedBox from '../_shared/RoundedBox';
import RowItem from '../RowItem';
import Divider from '../_shared/Divider';

import { StyledFC } from '../../types';
import { Task } from '../TasksNew';

const RowItemsList: StyledFC<{ data: Task[] }> = ({ className, data }) => {
  const dataLength = data.length;

  return (
    <>
      <RoundedBox className={className} padding={'0 16px'}>
        {!dataLength && <p>Пока тут пусто</p>}
        {data.map((task, index) => (
          <Fragment key={task.playbookId}>
            <RowItem dataArray={data} data={task} />
            {dataLength !== index + 1 && <Divider />}
          </Fragment>
        ))}
      </RoundedBox>
    </>
  );
};

export default RowItemsList;
