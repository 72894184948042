import React, {
  useEffect,
  useState,
} from 'react';

import styled from 'styled-components';

import {
  animated,
  config,
  useSpring,
  useTransition,
} from '@react-spring/web';

import { StyledFC } from '../../../types';
import rankImg from '../../_shared/image/supernova.png';
import { FillableButton } from '../../MedalsNew/Button';
import CirclesAndStarsBg from './CirclesAndStars';
import StarBg from './RedBg';

const SuccessText = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 40px;
  max-width: 379px;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  z-index: 999;
`;

const ButtonWrapper = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 8%;
  width: fit-content;
  z-index: 999;
  @media (max-height: 720px) {
    bottom: unset;
    top: 620px;
  }
`;

const Img = styled(animated.img)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 300px;
  width: 440px;
  height: 440px;
  object-fit: contain;
`;

const BgWrapper = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: -20px;
  top: 277px;
  width: fit-content;
`;

const CirclesAndStarsWrapper = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: -6px;
  right: 0;
  top: 258px;
  z-index: 10;
  width: fit-content;
`;
const acceptTitleText = 'Примите звание «Супернова»';
const acceptButtonText = 'Принять';

const OnboardingEighthPhase: StyledFC<{
  onPhaseEnd: () => void;
  onNextPhaseStart: () => void;
}> = ({ className, onPhaseEnd, onNextPhaseStart }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isBgStarted, setIsBgStarted] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const transitionExpText = useTransition(isMounted, {
    from: { opacity: 0, transform: 'translateY(50px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(50px)' },
  });

  const transitionStarBg = useTransition(isMounted, {
    from: { opacity: 0, transform: 'scale(0.3)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0.3)' },
    config: config.stiff,
  });

  const transitionImg = useTransition(isMounted, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 200 },
  });

  useEffect(() => {
    let timer: NodeJS.Timer;

    timer = setTimeout(() => {
      setIsBgStarted(true);
    }, 600);

    return () => clearTimeout(timer);
  }, []);

  const transitionCirclesAndStars = useTransition(isBgStarted, {
    from: { opacity: 0, transform: 'scale(0.3) translateY(50px)' },
    enter: { opacity: 1, transform: 'scale(1) translateY(0px)' },
    leave: { opacity: 0, transform: 'scale(0.3) translateY(50px)' },
    config: config.slow,
  });

  const [fadeAwayStyles, fadeAwayApi] = useSpring(() => ({
    from: {
      opacity: 1,
    },
    config: config.stiff,
  }));

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isButtonClicked) {
      onNextPhaseStart();
      fadeAwayApi.start({
        to: { opacity: 0 },
      });
      timer = setTimeout(() => {
        onPhaseEnd();
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [isButtonClicked]);

  return (
    <animated.div style={fadeAwayStyles}>
      {transitionExpText(
        (styles, item) =>
          item && <SuccessText style={styles}>{acceptTitleText}</SuccessText>
      )}
      {transitionStarBg(
        (styles, item) =>
          item && (
            <BgWrapper style={styles}>
              <StarBg />
            </BgWrapper>
          )
      )}
      {transitionImg(
        (styles, item) => item && <Img style={styles} src={rankImg} />
      )}
      {transitionCirclesAndStars(
        (styles, item) =>
          item && (
            <CirclesAndStarsWrapper style={styles}>
              <CirclesAndStarsBg />
            </CirclesAndStarsWrapper>
          )
      )}

      <ButtonWrapper>
        <FillableButton
          padding='12px 16px'
          onClick={() => {
            setIsButtonClicked(true);
          }}
        >
          {acceptButtonText}
        </FillableButton>
      </ButtonWrapper>
    </animated.div>
  );
};

export default OnboardingEighthPhase;
