export const wrapperTransitionConfig = {
  from: { x: -452 },
  enter: { x: 0 },
  leave: { x: -452 },
};

export const textTransitionConfig = {
  from: { opacity: 0, transform: 'translateY(50px)' },
  enter: { opacity: 1, transform: 'translateY(0px)' },
  leave: { opacity: 0, transform: 'translateY(50px)' },
};

export const buttonTransitionConfig = {
  from: { opacity: 0, transform: 'translateY(-50px)' },
  enter: { opacity: 1, transform: 'translateY(0px)' },
  leave: { opacity: 0, transform: 'translateY(-50px)' },
};

export const fadeInApiFromConfig = {
  from: {
    opacity: 0,
    transform: 'scale(0.5)',
  },
};

export const fadeInApiToConfig = {
  to: { opacity: 1, transform: 'scale(1)' },
};

export const whatIsNewTextFirstLine = 'Привет!';
export const whatIsNewTextSecondLine =
  'Тебя давно не было, посмотри какие награды ты заработал';

export const buttonText = 'Понял, сейчас посмотрю';
