import React, { useEffect, useState } from 'react';
import { StyledFC } from './types';
import styled from 'styled-components';
import AppContainerComponent from './components/AppContainer/AppContainer';
import useNavContext from './components/NavContext/NavContext';
import { API } from './_api/axios';
import { MedalsContextProvider } from './components/MedalsNew/MedalsContext';
import { TasksContextProvider } from './components/TasksNew/TasksContext';
import { getDateAfter30Days, tokenCookies } from './utils';
export const throwError = () => new Error('NavContext is not initialised yet');

export const NavContext = React.createContext<{
  route: string;
  push: (newRoute: string) => void;
}>({ route: '/', push: throwError });

const _App: StyledFC<{}> = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    //@ts-ignore
    window.ym(88659232, 'setUserID', Math.floor(Math.random() * 10000));
  }, []);

  const handleWidgetOpen = () => {
    // @ts-ignore
    window.ym(88659232, 'reachGoal', 'theChosen');
    //@ts-ignore
    window.ym(88659232, 'userParams', {
      got_access: Math.random() < 0.5 ? 'yes' : 'nope',
    });
    setIsOpen(!isOpen);
  };

  return (
    <div className={className}>
      <AppContainerComponent onClose={() => setIsOpen(true)} isOpen={true} />
    </div>
  );
};

const App = styled(_App)`
  background: rgba(0, 0, 0, 0);
  height: 100vh;
  margin: 0;

  .toggle-button {
    border: none;
    padding: 10px 8px 10px 8px;
    border-radius: 10px;
    background-color: cyan;
  }

  .button-wrap {
    position: absolute;
    top: 90px;
    left: 21%;

    & > img {
      cursor: pointer;
    }
  }

  .bg {
    height: calc(100% - 110px);
    width: 100%;
    max-width: 1395px;
  }
`;

const NavWrapped: React.FC = () => {
  const { route, push } = useNavContext();

  const simulation = (id: string, productId: number) => {
    console.log(id, productId);
    API.post('user-events/send', {
      actionId: id,
      productId: productId,
      spgpId: 1,
    }).then((res) => {
      console.log(res);
    });
  };
  return (
    <TasksContextProvider>
      <MedalsContextProvider>
        <NavContext.Provider
          value={{
            route: route,
            push: push,
          }}
        >
          <App />
        </NavContext.Provider>
      </MedalsContextProvider>
    </TasksContextProvider>
  );
};

export default NavWrapped;
