import React from 'react';

import { StyledFC } from '../../../../types';

const CirclesAndStarsBg: StyledFC = ({ className }) => {
  return (
    <svg
      width='350px'
      height='200px'
      viewBox='0 0 1807 735'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Untitled</title>
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='Group'
          transform='translate(0.090325, -0.000000)'
          fillRule='nonzero'
        >
          <path
            d='M1787.84893,591.600252 L1781.82052,594.769577 C1780.97176,595.215794 1779.92198,594.889472 1779.47576,594.040717 C1779.29808,593.702738 1779.23676,593.31561 1779.30131,592.939265 L1780.45263,586.226527 C1780.54923,585.663299 1780.3625,585.0886 1779.95329,584.68972 L1775.0762,579.935732 C1774.38955,579.266407 1774.3755,578.167165 1775.04482,577.480508 C1775.31135,577.207077 1775.66059,577.029134 1776.03846,576.974226 L1782.77843,575.99485 C1783.34394,575.912677 1783.83281,575.557493 1784.08572,575.045051 L1787.09992,568.9376 C1787.5243,568.077717 1788.5654,567.72467 1789.42528,568.149048 C1789.76769,568.318037 1790.04485,568.59519 1790.21383,568.9376 L1793.22804,575.045051 C1793.48095,575.557493 1793.96982,575.912677 1794.53533,575.99485 L1801.2753,576.974226 C1802.22424,577.112114 1802.88172,577.993161 1802.74383,578.942098 C1802.68893,579.31997 1802.51098,579.669203 1802.23755,579.935732 L1797.36046,584.68972 C1796.95125,585.0886 1796.76452,585.663299 1796.86112,586.226527 L1798.01245,592.939265 C1798.17455,593.884368 1797.5398,594.781932 1796.59469,594.94403 C1796.21835,595.008578 1795.83122,594.947263 1795.49324,594.769577 L1789.46483,591.600252 C1788.95902,591.334332 1788.35474,591.334332 1787.84893,591.600252 Z'
            id='Star'
            fill='#90D128'
            transform='translate(1788.656991, 581.469369) rotate(22.000000) translate(-1788.656991, -581.469369) '
          ></path>
          <path
            d='M1390.13859,191.129216 L1382.77053,195.002835 C1381.73316,195.548211 1380.45009,195.149374 1379.90472,194.112006 C1379.68754,193.698921 1379.6126,193.225765 1379.6915,192.765787 L1381.09867,184.56133 C1381.21674,183.87294 1380.98851,183.17053 1380.48837,182.683009 L1374.52748,176.872581 C1373.68823,176.054516 1373.67106,174.710998 1374.48912,173.87175 C1374.81488,173.537557 1375.24172,173.320071 1375.70357,173.252961 L1383.94131,172.055947 C1384.63249,171.955512 1385.23,171.521399 1385.5391,170.895081 L1389.22314,163.430419 C1389.74182,162.379451 1391.01427,161.947949 1392.06524,162.466633 C1392.48374,162.673175 1392.82249,163.011918 1393.02903,163.430419 L1396.71306,170.895081 C1397.02216,171.521399 1397.61967,171.955512 1398.31086,172.055947 L1406.5486,173.252961 C1407.70841,173.421492 1408.512,174.498326 1408.34347,175.658139 C1408.27636,176.119982 1408.05888,176.546823 1407.72468,176.872581 L1401.7638,182.683009 C1401.26365,183.17053 1401.03542,183.87294 1401.1535,184.56133 L1402.56067,192.765787 C1402.75879,193.920913 1401.98298,195.017936 1400.82786,195.216055 C1400.36787,195.294947 1399.89472,195.220007 1399.48164,195.002835 L1392.11357,191.129216 C1391.49536,190.804202 1390.75681,190.804202 1390.13859,191.129216 Z'
            id='Star'
            fill='#90D128'
            transform='translate(1391.126220, 178.747025) rotate(-3.000000) translate(-1391.126220, -178.747025) '
          ></path>
          <path
            d='M418.008409,725.391436 L412.325916,728.378904 C410.767259,729.198336 408.839435,728.599075 408.02,727.040424 C407.693697,726.419752 407.581097,725.708831 407.699634,725.01771 L408.784893,718.690163 C408.962292,717.655845 408.619379,716.600467 407.867906,715.86796 L403.270673,711.386766 C402.009693,710.157618 401.98389,708.138969 403.213042,706.877984 C403.702498,706.375859 404.34383,706.049082 405.037755,705.948247 L411.390975,705.025071 C412.429486,704.87417 413.327244,704.221908 413.791681,703.280852 L416.632927,697.523863 C417.412256,695.944774 419.324134,695.296434 420.903226,696.075764 C421.53203,696.386096 422.040994,696.89506 422.351327,697.523863 L425.192573,703.280852 C425.657009,704.221908 426.554768,704.87417 427.593279,705.025071 L433.946498,705.948247 C435.689131,706.201466 436.896538,707.819422 436.643319,709.562055 C436.542486,710.255982 436.21571,710.897315 435.713582,711.386766 L431.116349,715.86796 C430.364875,716.600467 430.021962,717.655845 430.19936,718.690163 L431.28462,725.01771 C431.582297,726.753304 430.416638,728.40159 428.681047,728.699272 C427.989925,728.817803 427.279003,728.705202 426.658337,728.378904 L420.975845,725.391436 C420.046972,724.903101 418.937282,724.903101 418.008409,725.391436 Z'
            id='Star'
            fill='#90D128'
            transform='translate(419.492334, 712.245800) rotate(26.000000) translate(-419.492334, -712.245800) '
          ></path>
          <path
            d='M27.4505817,596.10046 L15.6170277,602.321727 C13.9509523,603.197635 11.8902679,602.557077 11.0143601,600.891002 C10.665569,600.227562 10.5452099,599.467645 10.6719157,598.728893 L12.9319234,585.552037 C13.1215481,584.446441 12.7550024,583.318329 11.9517393,582.535341 L2.37819307,573.20344 C1.03030972,571.889578 1.00272959,569.731808 2.31659114,568.383924 C2.83977766,567.84719 3.52530935,567.497894 4.26705778,567.390112 L17.4973733,565.467634 C18.6074556,565.30633 19.5670847,564.609119 20.0635286,563.603213 L25.9803055,551.614514 C26.8133433,549.926595 28.8569824,549.233577 30.5449011,550.066615 C31.2170392,550.398335 31.7610802,550.942375 32.0928002,551.614514 L38.0095772,563.603213 C38.5060211,564.609119 39.4656502,565.30633 40.5757325,565.467634 L53.806048,567.390112 C55.6687769,567.660783 56.9593954,569.390244 56.6887251,571.252973 C56.5809427,571.994722 56.2316469,572.680253 55.6949127,573.20344 L46.1213664,582.535341 C45.3181034,583.318329 44.9515577,584.446441 45.1411823,585.552037 L47.4011901,598.728893 C47.7193821,600.584096 46.4733892,602.34598 44.6181869,602.664172 C43.8794357,602.790878 43.119518,602.670518 42.456078,602.321727 L30.6225241,596.10046 C29.6296363,595.578468 28.4434694,595.578468 27.4505817,596.10046 Z'
            id='Star'
            fill='#90D128'
            transform='translate(29.036774, 576.213911) rotate(-3.000000) translate(-29.036774, -576.213911) '
          ></path>
          <path
            d='M825.140191,111.118297 L819.457699,114.10576 C817.899042,114.925194 815.971217,114.325936 815.151782,112.767279 C814.825479,112.146613 814.71288,111.435689 814.831416,110.744569 L815.916676,104.41702 C816.094075,103.382706 815.751162,102.327327 814.999688,101.594822 L810.402456,97.1136275 C809.141475,95.8844753 809.115673,93.8658241 810.344825,92.604844 C810.83428,92.1027155 811.475613,91.7759398 812.169538,91.6751066 L818.522757,90.7519294 C819.561269,90.6010248 820.459027,89.9487655 820.923463,89.0077147 L823.764709,83.2507217 C824.544038,81.6716289 826.455916,81.0232923 828.035008,81.8026217 C828.663811,82.1129537 829.172776,82.6219191 829.483108,83.2507217 L832.324355,89.0077147 C832.788791,89.9487655 833.686549,90.6010248 834.72506,90.7519294 L841.07828,91.6751066 C842.820911,91.9283255 844.028319,93.5462831 843.7751,95.2889147 C843.674267,95.9828398 843.347491,96.624173 842.845362,97.1136275 L838.24813,101.594822 C837.496656,102.327327 837.153743,103.382706 837.331142,104.41702 L838.416401,110.744569 C838.714078,112.480159 837.548419,114.128448 835.812829,114.426125 C835.121707,114.544662 834.410784,114.432063 833.790119,114.10576 L828.107627,111.118297 C827.178754,110.62996 826.069064,110.62996 825.140191,111.118297 Z'
            id='Star'
            fill='#90D128'
            transform='translate(826.624116, 97.972658) rotate(-3.000000) translate(-826.624116, -97.972658) '
          ></path>
          <circle
            id='Oval'
            fill='#90D128'
            cx='762.409675'
            cy='28.5'
            r='28.5'
          ></circle>
          <circle
            id='Oval'
            fill='#90D128'
            cx='1440.90967'
            cy='138'
            r='23'
          ></circle>
          <circle
            id='Oval'
            fill='#F3AB3F'
            cx='1440.90967'
            cy='214'
            r='14'
          ></circle>
          <circle
            id='Oval'
            fill='#F3AB3F'
            cx='762.909671'
            cy='132'
            r='17'
          ></circle>
          <circle
            id='Oval'
            fill='#90D128'
            cx='891.909671'
            cy='423'
            r='7'
          ></circle>
          <circle
            id='Oval'
            fill='#90D128'
            cx='1264.90967'
            cy='504'
            r='7'
          ></circle>
          <circle
            id='Oval'
            fill='#90D128'
            cx='1537.90967'
            cy='698'
            r='7'
          ></circle>
        </g>
      </g>
    </svg>
  );
};

export default CirclesAndStarsBg;
