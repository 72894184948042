import React from 'react';
import { StyledFC } from '../../types';

const _TaskIndicatortIcon: StyledFC = ({ className }) => {
  return (
    <svg
      width='24'
      height='28'
      viewBox='0 0 24 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.9998 2.00024C15.9998 3.10481 15.1043 4.00024 13.9998 4.00024L9.99976 4.00025C8.89519 4.00025 7.99976 3.10481 7.99976 2.00024C7.99976 0.895675 8.89519 0.000244141 9.99976 0.000244141L13.9998 0.000244141C15.1043 0.000244141 15.9998 0.895675 15.9998 2.00024ZM18.8763 2.99012C19.0126 2.45491 19.4475 2.00024 19.9998 2.00024C22.2089 2.00024 23.9998 3.79111 23.9998 6.00024L23.9998 24.0002C23.9998 26.2094 22.2089 28.0002 19.9998 28.0002H3.99976C1.79062 28.0002 -0.000244141 26.2094 -0.000244141 24.0002L-0.000244141 6.00025C-0.000244141 3.79111 1.79062 2.00024 3.99975 2.00024C4.55204 2.00024 4.98693 2.45491 5.12318 2.99013C5.5637 4.72049 7.13228 6.00025 8.99976 6.00025L14.9998 6.00024C16.8672 6.00024 18.4358 4.72048 18.8763 2.99012Z'
        fill='#F74949'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.1037 11.9847C18.6646 12.5943 18.625 13.5433 18.0153 14.1041L11.4936 20.1041C10.9195 20.6323 10.0365 20.6323 9.46244 20.1041L5.98418 16.9041C5.37451 16.3433 5.33498 15.3943 5.89587 14.7847C6.45676 14.175 7.40568 14.1355 8.01535 14.6964L10.478 16.962L15.9842 11.8964C16.5938 11.3355 17.5428 11.375 18.1037 11.9847Z'
        fill='white'
      />
    </svg>
  );
};

export default _TaskIndicatortIcon;
