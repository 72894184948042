import { useCallback, useState } from 'react';

const useNavContext = () => {
  const [route, setRoute] = useState<string>('/');

  const push = useCallback((newRoute: string) => {
    setRoute(newRoute);
  }, []);

  return { route, push };
};

export default useNavContext;
