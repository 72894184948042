import {connect} from "react-redux";
import RankBox from "./index";




let mapStateToProps = (state:any) => {
    return{
        profileData: state.profileReducer,
    }
}


let mapDispatchToProps = (dispatch:any) => {
    return{

    }
}
const RankBoxContainer = connect(mapStateToProps,mapDispatchToProps)(RankBox);
export default RankBoxContainer;