import React from 'react';
import styled from 'styled-components';

import CompletedSignSVG from '../../../iconsSource/CompletedSignSVG';
import SimpleIconContainer from '../SimpleIconContainer';

import { StyledFC } from '../../../types';

const _CompletedSignIcon: StyledFC<{
  size: number;
  color: string;
  fontSize?: number;
}> = ({ className, size, color, fontSize }) => {
  return (
    <SimpleIconContainer color={color} size={size}>
      <CompletedSignSVG color={color} />
    </SimpleIconContainer>
  );
};

const CompletedSignIcon = styled(_CompletedSignIcon)``;

export default CompletedSignIcon;
