import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { API } from '../../_api/axios';
import { Button } from './Button';
import Flex from './Flex';
import GridList from './GridList';

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 30px 24px;
  margin: 50px 0;
`;

type Brands = 0 | 1 | 2 | 3;

export interface Medal {
  isNewAdded: boolean;
  isNew: boolean;
  id: number;
  img: string;
  earned: boolean;
  name: string;
  description: string;
  descriptionAfterReceive: string;
  descriptionToReceive: string;
  total: number;
  progress: number;
  productId: number;
  date: string | null;
}

const pageSize = 50;

const compareEarned = (a: Medal, b: Medal) =>
  Number(b.earned) - Number(a.earned);

const Grid = () => {
  const [medals, setMedals] = useState<Medal[]>([]);
  const [filteredNumber, setFilteredNumber] = useState<Brands>(0);

  useEffect(() => {
    API.get<Medal[]>(`/medals?filter=ALL&page=1&page_size=${pageSize}`)
      .then(({ data }) => data.sort(compareEarned))
      .then((data) =>
        filteredNumber === 0
          ? data
          : data.filter((m) => m.productId === filteredNumber)
      )
      .then((medals) => setMedals(medals))
      .catch((e) => {
        console.warn(e);
      });
  }, [filteredNumber]);
  //@ts-ignore
  window.ym(88659232, 'reachGoal', 'allMedals');
  return (
    <>
      <Flex margin='40px 0 0 0'>
        <Button
          fs='14px'
          lh='110%'
          ls='-0.02em'
          margin='0 6px 0 0'
          outlined={filteredNumber !== 0}
          onClick={() => setFilteredNumber(0)}
        >
          Все
        </Button>
        <Button
          fs='14px'
          lh='110%'
          ls='-0.02em'
          margin='0 6px 0 0'
          outlined={filteredNumber !== 3}
          onClick={() => setFilteredNumber(3)}
        >
          Playground
        </Button>
        <Button
          fs='14px'
          lh='110%'
          ls='-0.02em'
          margin='0 6px 0 0'
          outlined={filteredNumber !== 1}
          onClick={() => setFilteredNumber(1)}
        >
          СберАгент
        </Button>
        <Button
          fs='14px'
          lh='110%'
          ls='-0.02em'
          outlined={filteredNumber !== 2}
          onClick={() => setFilteredNumber(2)}
        >
          Wellness
        </Button>
      </Flex>
      <StyledGrid>
        <GridList data={medals} />
      </StyledGrid>
    </>
  );
};

export default Grid;
