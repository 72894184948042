import React, { createContext, useState, FC } from 'react';
import { Task } from '../../TasksNew';

const initialState = { task: {} as Task, setTask: (_: Task) => {} };

export const TasksContext = createContext(initialState);

export const TasksContextProvider: FC = ({ children }) => {
  const [task, set] = useState<Task>({} as Task);

  const setTask = (task: Task) => set(task);

  const value = {
    task,
    setTask,
  };

  return (
    <TasksContext.Provider value={value}>{children}</TasksContext.Provider>
  );
};
