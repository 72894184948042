import React, { useState, useEffect, useRef } from 'react';
import { animated, useTransition, useSpring, config } from '@react-spring/web';
import styled from 'styled-components';
import { FillableButton, CarouselButton } from '../MedalsNew/Button';
import { StyledFC } from '../../types';
import {
  textTransitionConfig,
  buttonTransitionConfig,
  wrapperTransitionConfig,
  fadeInApiToConfig,
  fadeInApiFromConfig,
  whatIsNewTextFirstLine,
  whatIsNewTextSecondLine,
  buttonText,
} from './config';

interface OnboardingProps {
  awards: any[];
  onClose: () => void;
}

const Wrapper = styled(animated.div)`
  width: 452px;
  height: 100vh;
  margin-left: -16px;
  background-color: #ebebeb;
  position: absolute;
  z-index: 999;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

const WhatIsNewText = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 40px;
  max-width: 379px;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
`;

const Carousel = styled(animated.div)`
  box-sizing: border-box;
  position: absolute;
  inset: 0;
  margin: auto;
  height: 324px;
  width: inherit;
  overflow: hidden;
`;

const List = styled.ul`
  list-style: none;
  display: flex;
  gap: 16px;
  padding: 0;
  margin: 0;
  height: inherit;
  width: inherit;

  white-space: nowrap;
  overflow-x: hidden;

  overflow-y: hidden;
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

const ListItem = styled.li`
  display: grid;
  box-sizing: border-box;
  /* flex-direction: column;
  justify-content: space-between;
  align-items: center; */
  padding: 30px;
  min-width: 244px;
  height: 100%;
  background-color: #ffffff;
  border-radius: 20px;

  &:first-of-type {
    margin-left: 106px;
  }

  &:last-of-type {
    margin-right: 106px;
  }
`;

const AwardImg = styled.img`
  width: 192px;
  height: 192px;
`;

const CircleBox = styled.div`
  position: relative;
  width: 192px;
  height: 192px;
`;

const LvlText = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14.647px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #3e4149;
  margin-bottom: 8px;
`;

const LvlNumber = styled.div`
  background-image: linear-gradient(98.04deg, #9536df 34.32%, #265ce7 74.77%);
  background-size: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 80px;
  line-height: 80px;
  font-weight: 600;
  text-align: center;
`;

const XpText = styled.div`
  background-image: linear-gradient(98.04deg, #9536df 34.32%, #265ce7 74.77%);
  background-size: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
  text-align: center;
`;

const XpBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: 8px;
  background-image: linear-gradient(98.04deg, #9536df 34.32%, #265ce7 74.77%);
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  border-radius: 8px;
`;

const CenteredBox = styled.div`
  position: absolute;
  inset: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
`;

const CenteredExpBox = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  inset: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
`;

const Name = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #212121;
`;

const Type = styled.p`
  color: #bdbdbd;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  text-align: center;
  letter-spacing: -0.02em;
`;

const LeftButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 16px;
  margin: auto;
  height: fit-content;
  z-index: 999;
`;

const RightButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  right: 16px;
  margin: auto;
  height: fit-content;
  z-index: 999;
`;

const ButtonWrapper = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 8%;
  width: fit-content;

  @media (max-height: 720px) {
    bottom: unset;
    top: 620px;
  }
`;

const OnboardingSecondRun: StyledFC<OnboardingProps> = ({
  awards,
  onClose,
}): JSX.Element => {
  const [isMounted, setIsMounted] = useState(false);
  const [isMountFinished, setIsMountFinished] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isLeftButtonShown, setIsLeftButtonShown] = useState(false);
  const [isRightButtonShown, setIsRightButtonShown] = useState(true);

  const itemsRef = useRef<Map<any, any>>();
  const middleItemId = Math.ceil(awards.length / awards.length);
  const currentItemIdRef = useRef<number>(middleItemId);

  function scrollToId(itemId: number) {
    const map = getMap();
    const node = map.get(itemId);
    node.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }

  function getMap() {
    if (!itemsRef.current) {
      itemsRef.current = new Map();
    }
    return itemsRef.current;
  }

  const handleRightButton = () => {
    if (!(currentItemIdRef.current >= awards.length)) {
      currentItemIdRef.current += 1;
    }
    if (currentItemIdRef.current === awards.length) {
      setIsRightButtonShown(false);
    }
    setIsLeftButtonShown(true);

    scrollToId(currentItemIdRef.current);
  };

  const handleLeftButton = () => {
    if (!(currentItemIdRef.current <= 1)) {
      currentItemIdRef.current -= 1;
    }
    if (currentItemIdRef.current === 1) {
      setIsLeftButtonShown(false);
    }
    setIsRightButtonShown(true);

    scrollToId(currentItemIdRef.current);
  };

  const [fadeInStyles, fadeInApi] = useSpring(() => ({
    ...fadeInApiFromConfig,
    config: config.stiff,
  }));

  useEffect(() => {
    setIsMounted(true);

    let timer: NodeJS.Timer;
    let timer2: NodeJS.Timer;

    timer = setTimeout(() => {
      setIsMountFinished(true);
      fadeInApi.start(fadeInApiToConfig);
    }, 500);

    timer2 = setTimeout(() => {
      scrollToId(middleItemId);
    }, 0);

    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timer;

    if (isButtonClicked) {
      setIsMounted(false);
      timer = setTimeout(onClose, 600);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isButtonClicked]);

  const transitionWrapper = useTransition(isMounted, wrapperTransitionConfig);

  const transitionWhatIsNewText = useTransition(
    isMountFinished,
    textTransitionConfig
  );

  const transitionButton = useTransition(
    isMountFinished,
    buttonTransitionConfig
  );

  return transitionWrapper(
    (styles, item) =>
      item && (
        <Wrapper style={styles}>
          {transitionWhatIsNewText(
            (styles, item) =>
              item && (
                <WhatIsNewText style={styles}>
                  {whatIsNewTextFirstLine}
                  <br />
                  {whatIsNewTextSecondLine}
                </WhatIsNewText>
              )
          )}
          <Carousel style={fadeInStyles}>
            {isLeftButtonShown && (
              <LeftButtonWrapper>
                <CarouselButton onClick={handleLeftButton} padding='30px 7px'>
                  &lt;
                </CarouselButton>
              </LeftButtonWrapper>
            )}
            {isRightButtonShown && (
              <RightButtonWrapper>
                <CarouselButton onClick={handleRightButton} padding='30px 7px'>
                  &gt;
                </CarouselButton>
              </RightButtonWrapper>
            )}
            <List>
              {awards.map((award, index) => (
                <ListItem
                  key={index + 1}
                  ref={(node) => {
                    const map = getMap();
                    if (node) {
                      map.set(index + 1, node);
                    } else {
                      map.delete(index + 1);
                    }
                  }}
                >
                  {award.img !== 'XP' && award.img !== 'LVL' && (
                    <AwardImg src={award.img} />
                  )}
                  {award.img === 'XP' && (
                    <CircleBox>
                      <CenteredExpBox>
                        <XpText>+{award?.exp}</XpText>
                        <XpBadge>XP</XpBadge>
                      </CenteredExpBox>
                      <svg
                        width='192'
                        height='192'
                        viewBox='0 0 153 153'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M153 76.5C153 118.75 118.75 153 76.5 153C34.2502 153 0 118.75 0 76.5C0 34.2502 34.2502 0 76.5 0C118.75 0 153 34.2502 153 76.5ZM5.22122 76.5C5.22122 115.866 37.1338 147.779 76.5 147.779C115.866 147.779 147.779 115.866 147.779 76.5C147.779 37.1338 115.866 5.22122 76.5 5.22122C37.1338 5.22122 5.22122 37.1338 5.22122 76.5Z'
                          fill='url(#paint0_radial_3224_10335)'
                        />
                        <defs>
                          <radialGradient
                            id='paint0_radial_3224_10335'
                            cx='0'
                            cy='0'
                            r='1'
                            gradientUnits='userSpaceOnUse'
                            gradientTransform='translate(1.19941e-05 153) rotate(-45) scale(189.72)'
                          >
                            <stop stopColor='#FF3C3C' />
                            <stop offset='0.421875' stopColor='#265CE7' />
                            <stop offset='1' stopColor='#9536DF' />
                          </radialGradient>
                        </defs>
                      </svg>
                    </CircleBox>
                  )}
                  {award.img === 'LVL' && (
                    <CircleBox>
                      <CenteredBox>
                        <LvlNumber>+{award?.lvl}</LvlNumber>
                        <LvlText>уровень</LvlText>
                      </CenteredBox>
                      <svg
                        width='192'
                        height='192'
                        viewBox='0 0 153 153'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M153 76.5C153 118.75 118.75 153 76.5 153C34.2502 153 0 118.75 0 76.5C0 34.2502 34.2502 0 76.5 0C118.75 0 153 34.2502 153 76.5ZM5.22122 76.5C5.22122 115.866 37.1338 147.779 76.5 147.779C115.866 147.779 147.779 115.866 147.779 76.5C147.779 37.1338 115.866 5.22122 76.5 5.22122C37.1338 5.22122 5.22122 37.1338 5.22122 76.5Z'
                          fill='url(#paint0_radial_3224_10329)'
                        />
                        <defs>
                          <radialGradient
                            id='paint0_radial_3224_10329'
                            cx='0'
                            cy='0'
                            r='1'
                            gradientUnits='userSpaceOnUse'
                            gradientTransform='translate(144.13 133.043) rotate(-127.036) scale(152.779)'
                          >
                            <stop stopColor='#FF3C3C' />
                            <stop offset='0.421875' stopColor='#F8BA1D' />
                            <stop offset='1' stopColor='#9536DF' />
                          </radialGradient>
                        </defs>
                      </svg>
                    </CircleBox>
                  )}
                  <Name>{award.name}</Name>
                  <Type>{award.type}</Type>
                </ListItem>
              ))}
            </List>
          </Carousel>
          {transitionButton(
            (styles, item) =>
              item && (
                <ButtonWrapper style={styles}>
                  <FillableButton
                    padding='12px 16px'
                    onClick={() => setIsButtonClicked(true)}
                  >
                    {buttonText}
                  </FillableButton>
                </ButtonWrapper>
              )
          )}
        </Wrapper>
      )
  );
};

export default OnboardingSecondRun;
