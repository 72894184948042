import { connect } from 'react-redux';
import SettingsNew from './index';
import {
  postProfileNickname,
  setProfileNickname,
} from '../../reducers/profileReducer';

let mapStateToProps = (state: any) => {
  return {
    profileData: state.profileReducer,
  };
};

let mapDispatchToProps = (dispatch: any) => {
  return {
    setNickname: (data: any) => {
      dispatch(setProfileNickname(data));
    },
    postNickname: () => {
      dispatch(postProfileNickname());
    },
  };
};
const SettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsNew);
export default SettingsContainer;
