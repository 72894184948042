import React from 'react';
import { StyledFC } from '../../types';

const _SeasonIcon: StyledFC = ({ className }) => {
  return (
    <svg
      width='36'
      height='32'
      viewBox='0 0 36 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.4088 4.28273C29.837 2.14886 27.6437 0.882531 25.5098 1.4543L4.25945 7.14832C2.12559 7.72008 0.859261 9.91343 1.43103 12.0473L1.91541 13.855C2.02992 14.2824 2.41403 14.574 2.85005 14.6491C4.80607 14.9858 6.48601 16.4229 7.03343 18.4659L7.2146 19.1421C7.76203 21.1851 7.02571 23.2697 5.50013 24.5393C5.16006 24.8223 4.97321 25.2669 5.08772 25.6943L5.57213 27.5021C6.1439 29.636 8.33725 30.9023 10.4711 30.3305L31.7215 24.6365C33.8553 24.0648 35.1217 21.8714 34.5499 19.7375L30.4088 4.28273ZM8.50254 8.49629C7.96907 8.63923 7.65249 9.18757 7.79543 9.72104L8.52013 12.4256C8.66307 12.9591 9.2114 13.2757 9.74487 13.1327C10.2783 12.9898 10.5949 12.4415 10.452 11.908L9.72728 9.2034C9.58434 8.66993 9.036 8.35335 8.50254 8.49629ZM10.3663 15.4504C9.83281 15.5934 9.51623 16.1417 9.65917 16.6752L10.3839 19.3797C10.5268 19.9132 11.0751 20.2298 11.6086 20.0869C12.1421 19.9439 12.4587 19.3956 12.3157 18.8621L11.591 16.1575C11.4481 15.624 10.8997 15.3075 10.3663 15.4504ZM12.2295 22.4054C12.7629 22.2624 13.3113 22.579 13.4542 23.1125L14.1789 25.8171C14.3218 26.3505 14.0053 26.8989 13.4718 27.0418C12.9383 27.1848 12.39 26.8682 12.2471 26.3347L11.5224 23.6301C11.3794 23.0967 11.696 22.5483 12.2295 22.4054Z'
        fill='#4743FD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.004 11.4067C27.585 11.251 28.171 11.6398 28.2534 12.2356L29.0819 18.2253C29.151 18.7248 28.8373 19.1977 28.3501 19.3282L24.3732 20.3938L22.3027 12.6664L27.004 11.4067ZM20.3708 13.1841L22.4414 20.9115L18.4644 21.9771C17.9773 22.1076 17.4692 21.855 17.2793 21.3878L15.0019 15.7863C14.7754 15.2291 15.0884 14.5995 15.6694 14.4438L20.3708 13.1841Z'
        fill='white'
      />
      <path
        d='M16.5669 10.5797C16.3525 9.77955 16.8274 8.95704 17.6276 8.74263C18.4278 8.52822 19.2503 9.00309 19.4647 9.80329L19.8529 11.2522L18.4041 11.6404C17.6039 11.8548 16.7814 11.3799 16.5669 10.5797Z'
        fill='white'
      />
      <path
        d='M24.2945 8.50919C24.0801 7.70899 23.2576 7.23412 22.4574 7.44853C21.6572 7.66294 21.1823 8.48545 21.3967 9.28565L21.7849 10.7345L23.2338 10.3463C24.034 10.1319 24.5089 9.30939 24.2945 8.50919Z'
        fill='white'
      />
    </svg>
  );
};

export default _SeasonIcon;
