import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import CameraIcon from '../_shared/icons/CameraIcon';
import { NavContext } from '../../main';
import { StyledFC } from '../../types';
import Typography from '../_shared/Typography/Typography';
import TextInput from '../_shared/TextInput';
import Divider from '../_shared/Divider';
import ArrowRightIcon from '../_shared/icons/ArrowRightIcon';
import RoundedBox from '../_shared/RoundedBox';
import { debounce } from 'lodash';

export type Pages = 'main' | 'rank' | 'name';

const AvatarBlock = styled.div`
  display: flex;
  margin-left: 18px;
  justify-content: center;
  margin-top: 36px;

  .container-img {
    position: relative;
    cursor: pointer;
  }

  .avatar-img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: white;
  }

  .icon {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 10px;

    pointer-events: none;
  }
`;

const _SettingsNew: StyledFC<any> = (props, { className }) => {
  const { push } = useContext(NavContext);
  const listener = function (event: StorageEvent) {
    if (event.key === 'backBtnClick') {
      push('/');
    }
  };

  useEffect(() => {
    window.addEventListener('storage', listener);

    return () => window.removeEventListener('storage', listener);
  }, []);
  const debouncedChangeHandler = useMemo(
    () => debounce(props.postNickname, 700),
    []
  );
  const regex =
    /(?:\/)([^#]+)(?=#|$)([^а-я])(([уyu]|[нзnz3][аa]|(хитро|не)?[вvwb][зz3]?[ыьъi]|[сsc][ьъ']|(и|[рpr][аa4])[зсzs]ъ?|([оo0][тбtb6]|[пp][оo0][дd9])[ьъ']?|(.\B)+?[оаеиeo])?-?([еёe][бb6](?!о[рй])|и[пб][ае][тц]).*?|([нn][иеаaie]|([дпdp]|[вv][еe3][рpr][тt])[оo0]|[рpr][аa][зсzc3]|[з3z]?[аa]|с(ме)?|[оo0]([тt]|дно)?|апч)?-?[хxh][уuy]([яйиеёюuie]|ли(?!ган)).*?|([вvw][зы3z]|(три|два|четыре)жды|(н|[сc][уuy][кk])[аa])?-?[бb6][лl]([яy](?!(х|ш[кн]|мб)[ауеыио]).*?|[еэe][дтdt][ь']?)|([рp][аa][сзc3z]|[знzn][аa]|[соsc]|[вv][ыi]?|[пp]([еe][рpr][еe]|[рrp][оиioеe]|[оo0][дd])|и[зс]ъ?|[аоao][тt])?[пpn][иеёieu][зz3][дd9].*?|([зz3][аa])?[пp][иеieu][дd][аоеaoe]?[рrp](ну.*?|[оаoa][мm]|([аa][сcs])?([иiu]([лl][иiu])?[нщктлtlsn]ь?)?|([оo](ч[еиei])?|[аa][сcs])?[кk]([оo]й)?|[юu][гg])[ауеыauyei]?|[мm][аa][нnh][дd]([ауеыayueiи]([лl]([иi][сзc3щ])?[ауеыauyei])?|[оo][йi]|[аоao][вvwb][оo](ш|sh)[ь']?([e]?[кk][ауеayue])?|юк(ов|[ауи])?)|[мm][уuy][дd6]([яyаиоaiuo0].*?|[еe]?[нhn]([ьюия'uiya]|ей))|мля([тд]ь)?|лять|([нз]а|по)х|м[ао]л[ао]фь([яию]|[её]й))(?=($|[^а-я]))/;

  const validateString = (str: string) => {
    return str.split('').every((letter) => letter.charCodeAt(0) !== 9);
  };
  const nickNameChangeHandler = (newValue: string) => {
    if (
      (newValue.length !==
        newValue.split('').filter((el) => el === ' ').length ||
        newValue === '') &&
      !regex.test(newValue) &&
      validateString(newValue) &&
      newValue.length <= 30
    ) {
      props.setNickname(newValue);
      if (newValue !== '') {
        debouncedChangeHandler();
      }
    }
  };
  return (
    <div>
      <AvatarBlock>
        <div
          className={'container-img'}
          onClick={() => {
            //@ts-ignore
            window.ym(88659232,'reachGoal','openAvatar');
            push('/settings/avatar')
          }}
        >
          <video
            className={'avatar-img'}
            src={props.profileData.videoSrc}
            autoPlay
            loop
          />

          <CameraIcon className={'icon'} color={'#212121'} size={40} />
        </div>
      </AvatarBlock>
      <div>
        <Typography mb={8} lh={18} ls={'-0.02em'} fw={400} mt={35} fz={14}>
          Отображаемое имя
        </Typography>
      </div>
      <div>
        <TextInput
          maxLength={30}
          value={props.profileData.nickname}
          onChange={(e) => {
            nickNameChangeHandler(e.target.value);
          }}
        />
      </div>
      <Divider margin={'10px 0px 40px 0px'} />
      <RoundedBox padding={'1px 0 34px 0'}>
        <div onClick={() => push('/settings/rank')} className={props.className}>
          <div className={'row'}>
            <div className={'right-box'}>
              <img
                width='40px'
                src={props.profileData.rankMedal}
                alt='Медаль'
              />
              <div className={'text-block'}>
                <label className={'title'}>{props.profileData.rankName}</label>
                <label className={'desc'}>Изменить звание</label>
              </div>
            </div>
            <div className={'arrow'}>
              <ArrowRightIcon color={'#212121'} size={20} />
            </div>
          </div>
        </div>
      </RoundedBox>
    </div>
  );
};

const SettingsNew = styled(_SettingsNew)`
  display: flex;
  margin-left: 18px;
  justify-content: start;
  margin-top: 36px;

  .arrow {
    color: #212121;
    justify-content: end;
    transition: all 0.2s ease;
    opacity: 0.4;
  }

  .info {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #868686;
    margin: 10px 0 37px 0;
  }

  .desc {
    cursor: inherit;
    font-weight: 400;
    font-size: 14px;
    color: inherit;
    transition: opacity 0.2s ease;
    opacity: 0.4;
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right-box {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .text-block {
    cursor: inherit;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 14px;

    .title {
      cursor: inherit;
      font-weight: 400;
      font-size: 20px;
      color: #212121;
    }
  }
`;

export default SettingsNew;
