import {API} from "../_api/axios";
import {isBooleanObject} from "util/types";


export const settingsService = {
    finishOnboarding: (props:any)=>
        API({
            url: '/settings/onboarding/flag',
            method: 'patch',
            headers: {
                'Content-Type': 'application/json',
            },
            data: String(props),
        })
            .then(() => {

            })
            .catch((e) => {
                console.log(e);
            }),

};