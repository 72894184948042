import React from 'react';

import { StyledFC } from '../../../../../types';

export const StarParticle: StyledFC = ({ className }) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.69506 0.523543C5.83119 0.289836 6.16881 0.289836 6.30494 0.523543L7.86143 3.19587C7.91129 3.28148 7.99485 3.34219 8.09168 3.36316L11.1142 4.01767C11.3785 4.07491 11.4829 4.39601 11.3027 4.59769L9.2421 6.9038C9.17609 6.97768 9.14417 7.0759 9.15415 7.17447L9.46568 10.2513C9.49292 10.5204 9.21978 10.7188 8.97228 10.6098L6.1423 9.36271C6.05164 9.32276 5.94836 9.32276 5.8577 9.36271L3.02772 10.6098C2.78022 10.7188 2.50708 10.5204 2.53432 10.2513L2.84585 7.17447C2.85583 7.0759 2.82391 6.97768 2.7579 6.9038L0.697349 4.59769C0.517145 4.39601 0.621478 4.07491 0.88581 4.01767L3.90832 3.36316C4.00516 3.34219 4.08871 3.28148 4.13857 3.19587L5.69506 0.523543Z'
        fill='#EAB64D'
      />
    </svg>
  );
};

export const PlusParticle: StyledFC = ({ className }) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 1.41397C6.55228 1.41397 7 1.86168 7 2.41397L7 4.9495L9.53553 4.9495C10.0878 4.9495 10.5355 5.39722 10.5355 5.9495C10.5355 6.50179 10.0878 6.9495 9.53553 6.9495L7 6.9495L7 9.48504C7 10.0373 6.55228 10.485 6 10.485C5.44772 10.485 5 10.0373 5 9.48504L5 6.9495L2.46447 6.9495C1.91218 6.9495 1.46447 6.50179 1.46447 5.9495C1.46447 5.39722 1.91218 4.9495 2.46447 4.9495L5 4.9495L5 2.41397C5 1.86168 5.44772 1.41397 6 1.41397Z'
        fill='#EAB64D'
      />
    </svg>
  );
};

export const CircleParticle: StyledFC = ({ className }) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='6' cy='6' r='4' fill='#EAB64D' />
    </svg>
  );
};

export const PadParticle: StyledFC = ({ className }) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.73101 5.30653C9.6082 4.80414 9.43528 4.22107 9.09107 3.70768C8.70323 3.12644 8.19418 2.90275 7.57848 3.03843C7.37648 3.08244 7.17286 3.13744 6.97733 3.19062C6.84804 3.22545 6.71391 3.26213 6.5814 3.29513C6.55393 3.30246 6.52646 3.3098 6.49899 3.3153C6.30022 3.36664 6.0966 3.41798 5.89298 3.38864C5.72976 3.3648 5.56654 3.3263 5.40656 3.28963L5.35323 3.27679C5.2288 3.24746 5.10436 3.21629 4.98155 3.18695C4.74399 3.12828 4.49674 3.06594 4.25111 3.02193C3.8972 2.95959 3.55784 3.0586 3.26696 3.30796C3.02294 3.51882 2.81932 3.80669 2.64641 4.18807C2.34906 4.84081 2.16483 5.5779 2.04848 6.57901C2.03555 6.68902 2.02586 6.80271 2.01454 6.91088C2.0097 6.96222 2.00485 7.01173 2 7.06307V7.64614V7.6553C2.00162 7.66814 2.00323 7.68097 2.00646 7.69381C2.01131 7.71948 2.01454 7.74515 2.01616 7.76898C2.0307 8.01651 2.07272 8.22737 2.14059 8.41623C2.30058 8.85811 2.62863 9.06347 3.04233 8.98279C3.27827 8.93695 3.47865 8.81594 3.66126 8.69126C4.00709 8.45473 4.31575 8.14853 4.57916 7.879C4.71814 7.73781 4.87651 7.62963 5.05265 7.55813C5.49867 7.37661 5.9754 7.33443 6.51353 7.42794C6.91592 7.49762 7.25043 7.67914 7.53485 7.98351C7.73685 8.19987 7.95663 8.38506 8.20226 8.58675C8.45113 8.79027 8.6903 8.91678 8.93594 8.97362C9.00866 8.99013 9.07814 8.99929 9.1444 8.99929C9.45791 8.99929 9.69708 8.81411 9.84414 8.4529C9.9718 8.13936 9.99442 7.80566 9.99927 7.49028C10.009 6.80087 9.9217 6.08395 9.73101 5.30653Z'
        fill='#EAB64D'
      />
    </svg>
  );
};

export const HeartParticle: StyledFC = ({ className }) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.63816 2C7.21114 2.00664 6.79343 2.12584 6.42722 2.34557C6.06101 2.5653 5.75927 2.87776 5.55246 3.25142C5.34565 2.87776 5.0439 2.5653 4.67769 2.34557C4.31148 2.12584 3.89377 2.00664 3.46676 2C2.78604 2.02958 2.14469 2.32736 1.68283 2.8283C1.22097 3.32924 0.976144 3.99261 1.00184 4.67349C1.00184 6.3978 2.81677 8.28099 4.33896 9.55782C4.67882 9.84342 5.10853 10 5.55246 10C5.99639 10 6.42609 9.84342 6.76595 9.55782C8.28814 8.28099 10.1031 6.3978 10.1031 4.67349C10.1288 3.99261 9.88394 3.32924 9.42208 2.8283C8.96022 2.32736 8.31888 2.02958 7.63816 2Z'
        fill='#EAB64D'
      />
    </svg>
  );
};

export const BagParticle: StyledFC = ({ className }) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.36 2.5395C6.8455 2.085 7.532 1.3595 7.807 0.6675C7.9345 0.347 7.6785 0 7.3335 0H4.3335C3.9885 0 3.7325 0.3465 3.86 0.6675C4.135 1.3595 4.8215 2.085 5.307 2.5395C2.618 2.939 0.333496 6.309 0.333496 9C0.333496 10.6545 1.679 12 3.3335 12H8.3335C9.988 12 11.3335 10.6545 11.3335 9C11.3335 6.309 9.049 2.939 6.36 2.5395ZM5.1555 6.8805L6.6765 7.134C7.347 7.2455 7.834 7.8205 7.834 8.5005C7.834 9.3275 7.161 10.0005 6.334 10.0005V10.5005C6.334 10.7765 6.11 11.0005 5.834 11.0005C5.558 11.0005 5.334 10.7765 5.334 10.5005V10.0005H5.2C4.666 10.0005 4.1685 9.7135 3.901 9.251C3.763 9.012 3.8445 8.7065 4.0835 8.568C4.3215 8.4295 4.628 8.511 4.7665 8.7505C4.8555 8.9045 5.022 9.0005 5.2 9.0005H6.334C6.6095 9.0005 6.834 8.776 6.834 8.5005C6.834 8.3115 6.6985 8.1515 6.512 8.1205L4.991 7.867C4.3205 7.7555 3.8335 7.1805 3.8335 6.5005C3.8335 5.6735 4.5065 5.0005 5.3335 5.0005V4.5005C5.3335 4.2245 5.5575 4.0005 5.8335 4.0005C6.1095 4.0005 6.3335 4.2245 6.3335 4.5005V5.0005H6.4675C7.001 5.0005 7.499 5.288 7.7665 5.7505C7.9045 5.9895 7.823 6.295 7.584 6.4335C7.3455 6.572 7.0395 6.4905 6.901 6.251C6.8115 6.0965 6.6455 6.001 6.4675 6.001H5.3335C5.058 6.001 4.8335 6.2255 4.8335 6.501C4.8335 6.69 4.969 6.85 5.1555 6.881V6.8805Z'
        fill='#EAB64D'
      />
    </svg>
  );
};

export const LabelParticle: StyledFC = ({ className }) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.36862 2.13641L3.67247 3.05163L2.33713 8.03516C2.26849 8.29134 2.30442 8.5643 2.43703 8.79398C2.56964 9.02367 2.78806 9.19126 3.04424 9.25991L7.55189 10.4677C7.80804 10.5355 8.08062 10.4992 8.31009 10.3668C8.53957 10.2343 8.70729 10.0164 8.77664 9.76062L10.112 4.77709L7.36862 2.13641ZM6.6353 4.8732C6.53978 4.84761 6.45399 4.79426 6.38879 4.71991C6.32358 4.64556 6.28189 4.55355 6.26898 4.4555C6.25608 4.35746 6.27253 4.25779 6.31627 4.16909C6.36001 4.0804 6.42906 4.00667 6.51471 3.95723C6.60035 3.90778 6.69873 3.88484 6.79741 3.89131C6.89609 3.89778 6.99063 3.93336 7.06909 3.99356C7.14754 4.05376 7.20638 4.13588 7.23817 4.22952C7.26996 4.32316 7.27326 4.42413 7.24767 4.51965C7.21335 4.64774 7.12955 4.75695 7.01471 4.82325C6.89986 4.88956 6.76339 4.90752 6.6353 4.8732Z'
        fill='#EAB64D'
      />
    </svg>
  );
};

export const GiftParticle: StyledFC = ({ className }) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 8.33346H9.99942C9.99942 9.25239 9.2518 10 8.33287 10H3.66654C2.74761 10 2 9.25239 2 8.33346ZM9.99942 5.66698V7.66684H2V5.66698C2 4.74805 2.74761 4.00044 3.66654 4.00044H4.08418C3.61188 3.53381 3.54255 2.93285 3.90186 2.45389C4.08351 2.21157 4.3485 2.05458 4.64814 2.01192C4.94778 1.96825 5.2461 2.04558 5.48841 2.22724C5.7144 2.39656 5.88105 2.61187 6.00404 2.83119C6.12703 2.61187 6.29369 2.39622 6.51967 2.22724C6.76165 2.04558 7.0593 1.96825 7.35994 2.01192C7.65959 2.05458 7.92457 2.21157 8.10622 2.45389C8.46753 2.93552 8.3952 3.54047 7.92457 4.00044H8.3332C9.25214 4.00044 9.99975 4.74805 9.99975 5.66698H9.99942ZM6.33502 4.00044C6.38068 4.00044 6.93531 3.98877 7.4586 3.52447C7.58759 3.40148 7.78658 3.1385 7.57293 2.85352C7.49826 2.75386 7.38894 2.6892 7.26561 2.67154C7.14296 2.6552 7.0193 2.68554 6.91964 2.76053C6.49367 3.07984 6.36968 3.69979 6.34002 3.89911L6.34068 3.90611L6.33502 4.00044ZM5.66907 4.00044H5.67306C5.67306 4.00044 5.66773 3.90245 5.66807 3.89911C5.63873 3.70079 5.51441 3.08017 5.08811 2.76053C5.00645 2.6992 4.90912 2.6672 4.80913 2.6672C4.65247 2.6672 4.50982 2.7542 4.43482 2.85386C4.22117 3.13884 4.42049 3.40148 4.55815 3.53281C5.06978 3.98644 5.62174 4.00011 5.66873 4.00044H5.66907ZM7.99956 6.00029C7.99956 5.81597 7.85024 5.66698 7.66625 5.66698C7.61192 5.66698 6.33302 5.65565 6.33302 4.66706H5.6664C5.6664 5.64565 4.39383 5.66665 4.33283 5.66698C4.14884 5.66698 3.99985 5.81631 3.99985 6.00029C3.99985 6.18428 4.14918 6.3336 4.33316 6.3336C4.80546 6.3336 5.56541 6.17095 5.99971 5.64832C6.43401 6.17128 7.19395 6.3336 7.66625 6.3336C7.85024 6.3336 7.99956 6.18461 7.99956 6.00029Z'
        fill='#EAB64D'
      />
    </svg>
  );
};
