import React from 'react';
import { StyledFC } from '../../../types';
import styled from 'styled-components';
import SimpleIconContainer from '../SimpleIconContainer';
import HelpIconSVG from '../../../iconsSource/HelpIconSVG';

const _HelpIcon: StyledFC<{ size: number; color: string }> = ({
  className,
  size,
  color,
}) => {
  return (
    <SimpleIconContainer className={className} color={color} size={size}>
      <HelpIconSVG />
    </SimpleIconContainer>
  );
};

const HelpIcon = styled(_HelpIcon)``;

export default HelpIcon;
