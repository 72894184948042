import React, { useState } from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import OnboardingFirstPhase from './OnboardingFirstPhase';
import OnboardingSecondPhase from './OnboardingSecondPhase';
import OnboardingThirdPhase from './OnboardingThirdPhase';
import OnboardingFourthPhase from './OnboardingFourthPhase';
import OnboardingFifthPhase from './OnBoardingFifthPhase';
import OnboardingSixthPhase from './OnboardingSixthPhase';
import OnboardingSeventhPhase from './OnboardingSeventhPhase';
import OnboardingEighthPhase from './OnboardingEighthPhase';
import OnboardingNinethPhase from './OnboardingNinethPhase';

import OnboardingFirstNewPhase from './OnboardingFirstNewPhase';

import { StyledFC } from '../../types';

const Wrapper = styled.div`
  width: 452px;
  height: 100vh;
  margin-left: -16px;
  background-color: #ebebeb;
  position: absolute;
  z-index: 999;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

const Onboarding: StyledFC<{
  close: () => void;
}> = ({ className, close }) => {
  const [_, setCookies] = useCookies(['onboardingVieved']);
  const [isFirstPhase, setIsFirstPhase] = useState(true);
  const [isSecondPhase, setIsSecondPhase] = useState(false);
  const [isFirstNewPhase, setISFirstNewPhase] = useState(false);
  const [isThirdPhase, setIsThirdPhase] = useState(false);
  const [isFourthPhase, setIsFourthPhase] = useState(false);
  const [isFifthPhase, setIsFifthPhase] = useState(false);
  const [isSixthPhase, setIsSixthPhase] = useState(false);
  const [isSeventhPhase, setIsSeventhPhase] = useState(false);
  const [isEighthPhase, setIsEighthPhase] = useState(false);
  const [isNinethPhase, setIsNinethPhase] = useState(false);

  return (
    <Wrapper>
      {isFirstPhase && (
        <OnboardingFirstPhase
          onPhaseEnd={() => setIsFirstPhase(false)}
          onNextPhaseStart={() => setISFirstNewPhase(true)}
        />
      )}

      {isFirstNewPhase && (
        <OnboardingFirstNewPhase
          onPhaseEnd={() => setISFirstNewPhase(false)}
          onNextPhaseStart={() => setIsSecondPhase(true)}
        />
      )}

      {isSecondPhase && (
        <OnboardingSecondPhase
          onPhaseEnd={() => setIsSecondPhase(false)}
          onNextPhaseStart={() => setIsThirdPhase(true)}
          onSkipPhase={() => {
            setIsSecondPhase(false);
            setIsThirdPhase(true);
          }}
        />
      )}

      {isThirdPhase && (
        <OnboardingThirdPhase
          onPhaseEnd={() => setIsThirdPhase(false)}
          onNextPhaseStart={() => setIsFourthPhase(true)}
        />
      )}
      {isFourthPhase && (
        <OnboardingFourthPhase
          onPhaseEnd={() => setIsFourthPhase(false)}
          onNextPhaseStart={() => setIsFifthPhase(true)}
        />
      )}
      {isFifthPhase && (
        <OnboardingFifthPhase
          onPhaseEnd={() => setIsFifthPhase(false)}
          onNextPhaseStart={() => setIsSeventhPhase(true)}
        />
      )}
      {/* {isSixthPhase && (
        <OnboardingSixthPhase
          onPhaseEnd={() => setIsSixthPhase(false)}
          onNextPhaseStart={() => setIsSeventhPhase(true)}
        />
      )} */}
      {isSeventhPhase && (
        <OnboardingSeventhPhase
          onPhaseEnd={() => setIsSeventhPhase(false)}
          onNextPhaseStart={() => setIsEighthPhase(true)}
        />
      )}
      {isEighthPhase && (
        <OnboardingEighthPhase
          onPhaseEnd={() => setIsEighthPhase(false)}
          onNextPhaseStart={() => setIsNinethPhase(true)}
        />
      )}
      {isNinethPhase && (
        <OnboardingNinethPhase
          onPhaseEnd={() => {
            setIsNinethPhase(false);
            setCookies('onboardingVieved', true);
            close();
          }}
          onNextPhaseStart={() => {}}
        />
      )}
    </Wrapper>
  );
};

export default Onboarding;
