import React, { useContext, useEffect, useMemo, useState } from 'react';
import { StyledFC } from '../../types';
import styled from 'styled-components';
import { NavContext } from '../../main';
import { useCookies } from 'react-cookie';
import { profileService } from '../../services';

const _Header: StyledFC<any> = (props, { className, data }) => {
  const { push } = useContext(NavContext);
  const [cookies] = useCookies();
  const avatarVieved = useMemo(() => cookies.avatarVieved, [cookies]);
  const listener = function (event: StorageEvent) {
    if (event.key === 'avatarVideo' && event.newValue) {
      props.setVideo(event.newValue);
    }
  };

  useEffect(() => {
    if (props.profileData.token && props.profileData.token !== '123')
      profileService.getAvatarVideo().then((response) => {
        props.setVideo(response.data);
      });
    //  const v = localStorage.getItem('avatarVideo');
    // if (v) props.setVideo(v);
    window.addEventListener('storage', listener);
    return () => window.removeEventListener('storage', listener);
  }, [props.profileData.token]);
  return (
    <div className={props.className}>
      <div className={['avatar', !avatarVieved && 'firstView'].join(' ')}>
        {props.profileData.videoSrc ? (
          <div className='videoAvatar'>
            <video
              autoPlay={true}
              loop
              height={props.videoSize}
              src={props.profileData.videoSrc}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const Header = styled(_Header)`
  width: 100%;

  grid-template:
    'avatar' 384px
    'name' 43px
    'rank' 44px / 100% 100% 100%;

  .videoAvatar {
    margin-left: 12px;
    height: 816px;
  }
  .avatar {
    position: absolute;
    z-index: ${(props) => props.zIndex};
    height: ${(props) => props.height}px;
    width: 120%;
    margin-left: -36px;
    margin-top: 20px;
    background: url('avatarBackgroundNew.png') no-repeat;
    background-size: 100% 50%;
    background-position: 0 109px;
    grid-area: avatar;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .createAvatarBtn {
      position: absolute;
      bottom: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 16px;
      height: 24px;
      background: #323232;
      border-radius: 200px;
      /* font-family: 'Alef'; */
      font-size: 14px;
      line-height: 24px;
      color: #ffffff;
    }
    .faceImg {
      width: 24px;
      height: 24px;
      margin-left: 4px;
    }
    .avatarImg {
      width: 319px;
      height: 816px;
      object-fit: contain;
      z-index: 0;
      margin-top: 400px;
    }

    &.firstView {
      padding-top: 30px;
      .avatarImg {
        width: 100%;
      }
    }
  }

  .name {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 7px;
    height: 31px;
    color: #ebebeb;
    font-weight: 600;
    font-size: 28px;
    font-style: normal;
  }

  .share {
    position: absolute;
    right: 32px;
    top: 56px;
  }
`;

export default Header;
