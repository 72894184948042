import React, { useContext, useState } from 'react';
import { StyledFC } from '../../../types';
import styled from 'styled-components';
import Divider from '../../_shared/Divider';
import { NavContext } from '../../../main';
import { setRewardData } from '../SeasonReward';
import image from '../../_shared/image/image.png';
import Notification from '../../_shared/Notification';

// TODO single source card type
type SeasonCardProps = {
  status: 'EARNED' | 'TAKEN' | 'LOCKED' | 'IN_PROGRESS';
  title: string;
  exp: number;
  notifyNew: any;
  link: string;
  imageFileKey: string;
  takeReward?: () => void;
  desc?: string;
};

const RewardImage = styled.img<{
  size: number;
  filter: string;
  opacity: number;
}>`
  transition: 150ms;
  filter: ${(props) => props.filter};
  opacity: ${(props) => props.opacity};
  height: ${(props) => props.size}px;
  max-width: 110px;
`;

const _SeasonCard: StyledFC<SeasonCardProps> = (props) => {
  const { push } = useContext(NavContext);
  const setUpCard = () => {
    push('/seasons/reward');
    setRewardData(props);
  };

  const [hovered, setHovered] = useState(false);
  const handleActivation = () => {
    window.open(props.link, '_blank');
  };
  return (
    <div
      onClick={setUpCard}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={props.className}
    >
      <>
        <div className={'card-info'}>
          <div className={'card-logo'}>
            <RewardImage
              opacity={
                (props.status !== 'LOCKED' && props.status !== 'IN_PROGRESS') ||
                hovered
                  ? 1
                  : 0.5
              }
              filter={
                (props.status !== 'LOCKED' && props.status !== 'IN_PROGRESS') ||
                hovered
                  ? 'grayscale(0%)'
                  : 'grayscale(100%)'
              }
              src={props.imageFileKey || image}
              size={hovered ? 111 : 100}
            />
          </div>
          <div className={'title'}>
            <div>
              {props.title}{' '}
              <div className={`reward-info ${hovered ? '' : 'exp'}`}>
                {props.exp} exp
              </div>
            </div>
          </div>
          <div className='notification'>
            {props.notifyNew === 'EARNED' && <Notification />}
          </div>
        </div>
        <Divider />
      </>
    </div>
  );
};

const SeasonCard = styled(_SeasonCard)`
  margin-top: -10px;
  width: 300px;
  margin-left: 50px;
  color: #aeb1b0;
  flex-shrink: 0;
  cursor: pointer;
  .notification {
    padding-top: 26px;
  }
  .card-info {
    display: grid;
    grid-template-columns: 30% 60% 10%;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;

    & > .card-logo {
      height: 60px;

      margin-bottom: 50px;
    }

    .reward-title {
      margin: 20px 20px 8px 20px;
      font-style: normal;
      font-weight: 400;
      width: 100%;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.3px;
      color: #ebebeb;
    }

    .reward-info {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #aeb1b0;

      margin: 20px 20px 8px 20px;
    }

    .exp {
      opacity: 50%;
      transition: 450ms;
    }
    .title {
      padding-left: 26px;
      padding-top: 26px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: flex-start;

      letter-spacing: -0.02em;
      color: #212121;
    }
  }

  .status {
    height: 46px;
    display: flex;
    justify-content: start;
    align-items: start;
    border-bottom: solid 0 black;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    background-color: ${(props) =>
      props.status === 'EARNED' && props.takeReward ? '' : ''};

    button {
      cursor: pointer;

      height: 100%;
      width: 100%;
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: solid 0 black;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      font-weight: bold;
    }
  }
`;

export default SeasonCard;
