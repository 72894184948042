import React from 'react';

import { StyledFC } from '../../../../types';

const CirclesAndStars: StyledFC = ({ className }) => {
  return (
    <svg
      width='228px'
      height='149px'
      viewBox='0 0 1789 1094'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Untitled</title>
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='Group' transform='translate(0.849316, 0.921579)'>
          <path
            d='M775.609632,71.2484047 L761.721261,78.5499538 C759.765882,79.577957 757.347374,78.826171 756.31937,76.8707926 C755.910014,76.0921507 755.768755,75.2002784 755.917463,74.3332479 L758.569906,58.8683187 C758.792457,57.5707424 758.362264,56.2467416 757.419519,55.327793 L746.18359,44.3754693 C744.601656,42.8334645 744.569287,40.3010114 746.111292,38.7190771 C746.725326,38.0891426 747.529896,37.6791939 748.400444,37.5526958 L763.928116,35.2963931 C765.230956,35.1070792 766.357219,34.2888018 766.939867,33.1082279 L773.884052,19.0377705 C774.861742,17.0567558 777.260245,16.2433998 779.24126,17.221089 C780.03011,17.61041 780.66862,18.2489201 781.057941,19.0377705 L788.002127,33.1082279 C788.584775,34.2888018 789.711037,35.1070792 791.013878,35.2963931 L806.54155,37.5526958 C808.727729,37.8703663 810.242455,39.9001377 809.924784,42.0863172 C809.798286,42.9568653 809.388338,43.7614349 808.758403,44.3754693 L797.522474,55.327793 C796.57973,56.2467416 796.149536,57.5707424 796.372088,58.8683187 L799.024531,74.3332479 C799.397975,76.5105937 797.935624,78.5784174 795.758278,78.9518614 C794.891247,79.1005688 793.999375,78.9593101 793.220733,78.5499538 L779.332361,71.2484047 C778.167065,70.6357723 776.774928,70.6357723 775.609632,71.2484047 Z'
            id='Star'
            fill='#90D128'
            transform='translate(777.470997, 50.769827) rotate(22.000000) translate(-777.470997, -50.769827) '
          ></path>
          <path
            d='M1026.12629,411.781277 L1012.23792,419.082826 C1010.28254,420.110829 1007.86403,419.359043 1006.83603,417.403665 C1006.42667,416.625023 1006.28541,415.733151 1006.43412,414.86612 L1009.08656,399.401191 C1009.30911,398.103615 1008.87892,396.779614 1007.93618,395.860665 L996.700248,384.908342 C995.118314,383.366337 995.085944,380.833884 996.627949,379.251949 C997.241984,378.622015 998.046553,378.212066 998.917101,378.085568 L1014.44477,375.829265 C1015.74761,375.639952 1016.87388,374.821674 1017.45652,373.6411 L1024.40071,359.570643 C1025.3784,357.589628 1027.7769,356.776272 1029.75792,357.753961 C1030.54677,358.143282 1031.18528,358.781792 1031.5746,359.570643 L1038.51878,373.6411 C1039.10143,374.821674 1040.22769,375.639952 1041.53054,375.829265 L1057.05821,378.085568 C1059.24439,378.403239 1060.75911,380.43301 1060.44144,382.61919 C1060.31494,383.489738 1059.905,384.294307 1059.27506,384.908342 L1048.03913,395.860665 C1047.09639,396.779614 1046.66619,398.103615 1046.88875,399.401191 L1049.54119,414.86612 C1049.91463,417.043466 1048.45228,419.11129 1046.27494,419.484734 C1045.4079,419.633441 1044.51603,419.492182 1043.73739,419.082826 L1029.84902,411.781277 C1028.68372,411.168645 1027.29159,411.168645 1026.12629,411.781277 Z'
            id='Star'
            fill='#90D128'
            transform='translate(1027.987654, 391.302700) rotate(-3.000000) translate(-1027.987654, -391.302700) '
          ></path>
          <path
            d='M434.008666,1070.94517 L426.879824,1074.69303 C424.924446,1075.72103 422.505938,1074.96924 421.477934,1073.01387 C421.068578,1072.23522 420.927319,1071.34335 421.076027,1070.47632 L422.437514,1062.53824 C422.660066,1061.24066 422.229872,1059.91666 421.287128,1058.99771 L415.519774,1053.37593 C413.93784,1051.83393 413.90547,1049.30148 415.447475,1047.71954 C416.06151,1047.08961 416.866079,1046.67966 417.736627,1046.55316 L425.706914,1045.39501 C427.009755,1045.2057 428.136017,1044.38742 428.718665,1043.20684 L432.283086,1035.98454 C433.260775,1034.00353 435.659279,1033.19017 437.640293,1034.16786 C438.429144,1034.55718 439.067654,1035.19569 439.456975,1035.98454 L443.021395,1043.20684 C443.604043,1044.38742 444.730306,1045.2057 446.033146,1045.39501 L454.003433,1046.55316 C456.189613,1046.87083 457.704338,1048.9006 457.386668,1051.08678 C457.26017,1051.95733 456.850221,1052.7619 456.220287,1053.37593 L450.452933,1058.99771 C449.510189,1059.91666 449.079995,1061.24066 449.302546,1062.53824 L450.664034,1070.47632 C451.037478,1072.65367 449.575127,1074.72149 447.397781,1075.09494 C446.53075,1075.24364 445.638878,1075.10238 444.860236,1074.69303 L437.731395,1070.94517 C436.566099,1070.33254 435.173962,1070.33254 434.008666,1070.94517 Z'
            id='Star'
            fill='#90D128'
            transform='translate(435.870030, 1056.216597) rotate(26.000000) translate(-435.870030, -1056.216597) '
          ></path>
          <path
            d='M39.1262896,676.781277 L25.2379179,684.082826 C23.2825396,685.110829 20.864031,684.359043 19.8360277,682.403665 C19.4266714,681.625023 19.2854127,680.733151 19.4341201,679.86612 L22.0865631,664.401191 C22.3091148,663.103615 21.8789209,661.779614 20.9361766,660.860665 L9.70024782,649.908342 C8.11831351,648.366337 8.08594428,645.833884 9.62794913,644.251949 C10.2419835,643.622015 11.0465532,643.212066 11.9171013,643.085568 L27.4447729,640.829265 C28.7476136,640.639952 29.8738759,639.821674 30.456524,638.6411 L37.4007098,624.570643 C38.378399,622.589628 40.7769024,621.776272 42.757917,622.753961 C43.5467674,623.143282 44.1852775,623.781792 44.5745985,624.570643 L51.5187843,638.6411 C52.1014324,639.821674 53.2276947,640.639952 54.5305354,640.829265 L70.058207,643.085568 C72.2443864,643.403239 73.7591122,645.43301 73.4414418,647.61919 C73.3149437,648.489738 72.904995,649.294307 72.2750605,649.908342 L61.0391317,660.860665 C60.0963874,661.779614 59.6661935,663.103615 59.8887452,664.401191 L62.5411882,679.86612 C62.9146322,682.043466 61.4522809,684.11129 59.2749351,684.484734 C58.4079046,684.633441 57.5160323,684.492182 56.7373904,684.082826 L42.8490187,676.781277 C41.6837226,676.168645 40.2915857,676.168645 39.1262896,676.781277 Z'
            id='Star'
            fill='#90D128'
            transform='translate(40.987654, 656.302700) rotate(-3.000000) translate(-40.987654, -656.302700) '
          ></path>
          <path
            d='M1665.47854,675.847754 L1656.29245,680.677167 C1654.33707,681.70517 1651.91857,680.953384 1650.89056,678.998006 C1650.48121,678.219364 1650.33995,677.327492 1650.48865,676.460461 L1652.24304,666.2316 C1652.46559,664.934024 1652.0354,663.610023 1651.09265,662.691074 L1643.66095,655.446954 C1642.07902,653.90495 1642.04665,651.372497 1643.58865,649.790562 C1644.20269,649.160628 1645.00726,648.750679 1645.87781,648.624181 L1656.14817,647.13181 C1657.45101,646.942496 1658.57727,646.124219 1659.15992,644.943645 L1663.75296,635.63712 C1664.73065,633.656105 1667.12915,632.842749 1669.11017,633.820438 C1669.89902,634.209759 1670.53753,634.848269 1670.92685,635.63712 L1675.5199,644.943645 C1676.10254,646.124219 1677.22881,646.942496 1678.53165,647.13181 L1688.80201,648.624181 C1690.98819,648.941851 1692.50291,650.971623 1692.18524,653.157802 C1692.05874,654.02835 1691.6488,654.83292 1691.01886,655.446954 L1683.58716,662.691074 C1682.64441,663.610023 1682.21422,664.934024 1682.43677,666.2316 L1684.19116,676.460461 C1684.5646,678.637807 1683.10225,680.705631 1680.92491,681.079075 C1680.05787,681.227782 1679.166,681.086523 1678.38736,680.677167 L1669.20127,675.847754 C1668.03597,675.235121 1666.64384,675.235121 1665.47854,675.847754 Z'
            id='Star'
            fill='#90D128'
            transform='translate(1667.339906, 659.369177) rotate(33.000000) translate(-1667.339906, -659.369177) '
          ></path>
          <path
            d='M714.243913,605.11738 L707.115072,608.865234 C705.159694,609.893237 702.741185,609.141451 701.713182,607.186073 C701.303826,606.407431 701.162567,605.515558 701.311274,604.648528 L702.672762,596.710446 C702.895314,595.41287 702.46512,594.088869 701.522375,593.169921 L695.755022,587.54814 C694.173087,586.006135 694.140718,583.473682 695.682723,581.891748 C696.296757,581.261814 697.101327,580.851865 697.971875,580.725367 L705.942162,579.567216 C707.245003,579.377902 708.371265,578.559625 708.953913,577.379051 L712.518334,570.156746 C713.496023,568.175731 715.894526,567.362375 717.875541,568.340065 C718.664391,568.729385 719.302901,569.367896 719.692222,570.156746 L723.256643,577.379051 C723.839291,578.559625 724.965553,579.377902 726.268394,579.567216 L734.238681,580.725367 C736.42486,581.043037 737.939586,583.072809 737.621916,585.258988 C737.495418,586.129536 737.085469,586.934106 736.455534,587.54814 L730.688181,593.169921 C729.745436,594.088869 729.315242,595.41287 729.537794,596.710446 L730.899282,604.648528 C731.272726,606.825874 729.810374,608.893697 727.633029,609.267141 C726.765998,609.415849 725.874126,609.27459 725.095484,608.865234 L717.966643,605.11738 C716.801346,604.504748 715.40921,604.504748 714.243913,605.11738 Z'
            id='Star'
            fill='#90D128'
            transform='translate(716.105278, 590.388803) rotate(-3.000000) translate(-716.105278, -590.388803) '
          ></path>
          <circle
            id='Oval'
            fill='#90D128'
            cx='986.650684'
            cy='270.578421'
            r='50.5'
          ></circle>
          <circle
            id='Oval'
            fill='#F3AB3F'
            cx='883.650684'
            cy='459.578421'
            r='27.5'
          ></circle>
          <circle
            id='Oval'
            fill='#F3AB3F'
            cx='1549.65068'
            cy='588.578421'
            r='27.5'
          ></circle>
          <circle
            id='Oval'
            fill='#9A57F2'
            cx='1749.15068'
            cy='561.078421'
            r='39'
          ></circle>
          <circle
            id='Oval'
            fill='#9A57F2'
            cx='1313.15068'
            cy='1005.07842'
            r='14'
          ></circle>
          <circle
            id='Oval'
            fill='#90D128'
            cx='1001.15068'
            cy='977.078421'
            r='14'
          ></circle>
        </g>
      </g>
    </svg>
  );
};

export default CirclesAndStars;
