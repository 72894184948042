import React from 'react';
import styled from 'styled-components';
import { StyledFC } from '../../../types';
import Spinner from './Spinner';

const Background = styled('div')`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #f4f4f4;
`;

const LoadingText = styled.p`
  text-align: center;
  margin-top: 50px;
`;

export const Preloader: StyledFC<{ loadingText?: string }> = ({
  loadingText,
}): JSX.Element => {
  return (
    <Background>
      <Spinner />
      <LoadingText>{loadingText}</LoadingText>
    </Background>
  );
};
