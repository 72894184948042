import React, { useContext } from 'react';
import styled from 'styled-components';

import ArrowRightIcon from '../_shared/icons/ArrowRightIcon';
import CompletedIcon from '../_shared/icons/CompletedIcon';
import { Task } from '../TasksNew';
import { NavContext } from '../../main';
import { TasksContext } from '../TasksNew/TasksContext';

import { StyledFC } from '../../types';
import Notification from '../_shared/Notification';

const Item = styled.div`
  cursor: pointer;
  padding: 30px 0 30px 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RightBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CompletedIconBlock = styled.div`
  width: 40px;
  height: 40px;
  transition: transform 0.3s ease;
  transform-origin: top left;

  ${Item}:hover & {
    transform: scale(2);
  }
`;

const XpBlock = styled.div`
  position: relative;
  min-width: 40px;
  height: 40px;
`;

const XpBlockBackGround = styled.div<{ data: Task }>`
  width: 100%;
  height: 100%;
  background-color: ${({ data }) =>
    data.exp <= 50 ? '#8E8E96' : data.exp <= 200 ? '#3935ff' : '#9A57F2'};
  border-radius: 8px;
  transition: transform 0.2s ease;

  ${Item}:hover & {
    transform: scale(1.09);
  }
`;

const XpBlockInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
`;

const XpBlockNumber = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  line-height: 12px;
`;

const XpBlockXpSign = styled.span`
  display: block;
  font-size: 9px;
  font-weight: 600;
  text-align: center;
`;

const TextBlock = styled.div`
  cursor: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 14px;
`;

const Title = styled.span`
  cursor: inherit;
  font-weight: 400;
  font-size: 20px;
  color: #212121;
`;

const Description = styled.span`
  display: flex;
  cursor: inherit;
  font-weight: 400;
  font-size: 14px;
  color: inherit;
`;

const ArrowBlock = styled.div`
  color: #212121;
  transition: all 0.3s ease;
  opacity: 0.4;

  ${Item}:hover & {
    opacity: 1;
    transform: translateX(4px);
  }
`;

const LevelBlock = styled.div`
  margin: 7px 0 7px 0;
  height: 8px;
  position: relative;
  background-color: #ebebeb;
  border: 0 solid black;
  border-radius: 4px;
  width: 100px;
  margin-right: 12px;
`;

const LevelProgress = styled.div<{ data: Task }>`
  position: absolute;
  background: #90d128;
  height: 8px;
  width: ${({ data }) => (data.progress / data.total) * 100}%;
  border: 0 solid black;
  border-radius: 4px;
`;

const Text = styled.div`
  transition: opacity 0.3s ease;
  opacity: 0.4;
  margin-right: 12px;

  ${Item}:hover & {
    opacity: 1;
  }
`;

function declOfNum(number: number, titles: string[]) {
  const cases = [2, 0, 1, 1, 1, 2];

  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
}

const RowItem: StyledFC<{ dataArray: Task[]; data: Task }> = ({
  className,
  dataArray,
  data,
}) => {
  const { push } = useContext(NavContext);
  const { setTask } = useContext(TasksContext);

  const setupTaskGlobal = () => {
    push(`/tasks/${data.descriptionToExecution}`);
    setTask(data);
  };

  const xpText = data.completed ? `${data.exp} XP` : '';
  const brandText = data.logo.includes('SberAgent') ? 'СберАгент' : 'Wellness';
  const progressText = `${data.progress} из ${data.total}`;

  const daysLeft = `${declOfNum(data.timeLeft.days, [
    'Остался',
    'Осталось',
    'Осталось',
  ])} ${data.timeLeft.days} ${declOfNum(data.timeLeft.days, [
    ' день',
    ' дня',
    ' дней',
  ])}`;

  const hoursLeft = `${declOfNum(data.timeLeft.hours, [
    'Остался',
    'Осталось',
    'Осталось',
  ])} ${data.timeLeft.hours} ${declOfNum(data.timeLeft.hours, [
    ' час',
    ' часа',
    ' часов',
  ])}`;

  const minutesLeft = `${declOfNum(data.timeLeft.minutes, [
    'Осталась',
    'Осталось',
    'Осталось',
  ])} ${data.timeLeft.minutes} ${declOfNum(data.timeLeft.minutes, [
    ' минута',
    ' минуты',
    ' минут',
  ])}`;

  const secondsLeft = `${declOfNum(data.timeLeft.seconds, [
    'Осталась',
    'Осталось',
    'Осталось',
  ])} ${data.timeLeft.seconds} ${declOfNum(data.timeLeft.seconds, [
    ' cекунда',
    ' секунды',
    ' секунд',
  ])}`;

  const timeLeftText = `${
    data.timeLeft.days
      ? daysLeft
      : data.timeLeft.hours
      ? hoursLeft
      : data.timeLeft.minutes
      ? minutesLeft
      : secondsLeft
  }`;
  return (
    <Item onClick={setupTaskGlobal}>
      <Row>
        <RightBlock>
          {data.completed ? (
            <CompletedIconBlock>
              <CompletedIcon size={20} color={'inherit'} fontSize={30} />
            </CompletedIconBlock>
          ) : (
            <XpBlock>
              <XpBlockBackGround data={data} />
              <XpBlockInner>
                <XpBlockNumber>{data.exp}</XpBlockNumber>
                <XpBlockXpSign>xp</XpBlockXpSign>
              </XpBlockInner>
            </XpBlock>
          )}
          <TextBlock>
            <Title>{data.nameInSmallCard}</Title>
            <Description>
              {!data.completed && data.progress > 0 && (
                <>
                  <LevelBlock>
                    <LevelProgress data={data} />
                  </LevelBlock>
                  <Text>{progressText}</Text>
                </>
              )}
              {data.completed && <Text>{xpText}</Text>}
              {!data.completed && !data.progress && <Text>{timeLeftText}</Text>}
              <Text>{brandText}</Text>
            </Description>
          </TextBlock>
        </RightBlock>
        {(data.isNew || data.completed || data.isNewAdded) && <Notification />}
        <ArrowBlock>
          <ArrowRightIcon color={'#212121'} size={20} />
        </ArrowBlock>
      </Row>
    </Item>
  );
};

export default RowItem;
