import React, { useState } from 'react';
import styled from 'styled-components';
import { StyledFC } from '../../types';
import CloseIconSVG from '../../iconsSource/CloseIconSVG';

const ModalWrapper = styled.div`
  height: 100%;
  width: 100%;
  transition: 200ms;
  display: flex;
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  background: rgba(244, 244, 244, 0.5);
  background-size: 452px;
`;
const Content = styled.div<{ top: number }>`
  z-index: 99999;
  position: fixed;
  transition: 100ms;
  left: 476px;
  top: calc(42vh + ${(props) => props.top}px);
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    top: calc(40vh + (${(props) => props.top}px));
  }
  padding: 20px;
  border-radius: 12px;
  background-color: white;
`;
const Button = styled.button`
  margin-right: 8px;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #3e4149;
  flex: none;
  order: 0;
  flex-grow: 0;
  transition: 100ms;
  padding: 12px 12px;
  gap: 4px;
  text-align: center;
  background: #f4f4f4;
  border: 2px solid #3e4149;
  border-radius: 11px;
  &:hover {
    color: white;
    background: #3e4149;
  }
`;

const Arrow = styled.div<{ mt: number }>`
  transition: 100ms;
  position: absolute;
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    margin-top: calc(${(props) => props.mt}px + 25px);
  }
  margin-top: ${(props) => props.mt}px;
  margin-left: -30px;
`;
const Testing = styled.div`
  width: 1000px;
  background: red;
  z-index: 99999;
`;
const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-bottom: 13px;
  span {
    cursor: pointer;
    float: right;
  }
`;
const ContentText = styled.div`
  width: 308px;
`;
const ButtonWrapper = styled.div`
  margin-top: 14px;
`;

const Modal: StyledFC<{ setActive: any; setIndexShow: any }> = (
  setActive: any,
  setIndexShow: any
) => {
  const titleList = [
    {
      title: 'Playground',
      text: 'Вы попали на Playground — новую платформу, на которой каждый может получать награды. Рассказываем, как всё работает. ',
      top: 0,
      marginTop: 85,
    },
    {
      title: 'Очки опыта и уровни',
      indexShow: 1,
      top: 0,
      marginTop: 85,
      text: 'Чтобы получать награды, нужно выполнять простые задания. За выполнение каждого задания мы начисляем очки XP — они повышают ваш игровой уровень и дают доступ к интересным призам. ',
    },
    {
      title: 'Сезоны',
      top: 105,
      marginTop: 85,
      text: 'Все задания привязаны к лимитированным по времени сезонам. Чем больше сезонов вы пройдёте, тем круче награды получите. ',
    },
    {
      title: 'Задания',
      top: 195,
      marginTop: 140,
      text: 'Выполняй задания, чтобы заработать очки опыта (XP): увеличить свой уровень и получить ценные награды в Сезонах!',
    },
    {
      title: 'Медали',
      top: 230,
      marginTop: 155,
      text: 'За выполнение заданий мы также даём классные, тематические медали. Попробуйте собрать их все — покажите, кто на Playground справляется с заданиями лучше всех. ',
    },
    {
      title: 'Аватар',
      top: -250,
      marginTop: 155,
      text: 'В личном профиле на Playground вас ждёт аватар: меняйте его внешность, имя, модные образы и позы, когда захотите. ',
    },
    {
      title: 'Комментарии и пожелания',
      top: 0,
      marginTop: 155,
      text: 'Любые вопросы, комментарии и пожелания о Playground вы можете отправить нам на почту — отвечаем быстро: playground@wellnessme.ru',
    },
  ];
  const [top, setTop] = useState(0);
  const [title, setTitle] = useState(titleList[0].title);
  const [step, setStep] = useState(0);

  function nextStep() {
    setStep(step + 1);

    setTop(titleList[step + 1].top);
    setTitle(titleList[step + 1].title);
    setContent(titleList[step + 1].text);
    setActive.setIndexShow(step + 1);
  }
  function prevStep() {
    setTitle(titleList[step - 1].title);
    setTop(titleList[step - 1].top);
    setContent(titleList[step - 1].text);
    setStep(step - 1);
    setActive.setIndexShow(step - 1);
  }
  const [content, setContent] = useState(titleList[0].text);
  return (
    <ModalWrapper>
      <Content top={top}>
        {step != 0 && step != 6 && (
          <Arrow mt={titleList[step - 1].marginTop}>
            <svg
              width='13'
              height='32'
              viewBox='0 0 13 32'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M10 4.03804V2C10 1.17157 10.6716 0.5 11.5 0.5C12.3284 0.5 13 1.17157 13 2V30C13 30.8284 12.3284 31.5 11.5 31.5C10.6716 31.5 10 30.8284 10 30V27.9482C10 26.9736 9.59946 26.0417 8.89222 25.3711L2.06067 18.8926C0.397218 17.3152 0.397217 14.6652 2.06067 13.0877L8.8965 6.60528C9.601 5.93719 10 5.00895 10 4.03804Z'
                fill='white'
              />
            </svg>
          </Arrow>
        )}
        <Title>
          {title}
          <span
            onClick={() => {
              setActive.setIndexShow(0);
              setActive.setActive(false);
            }}
          >
            <CloseIconSVG color={'black'} />
          </span>
        </Title>
        <ContentText>{content}</ContentText>
        <ButtonWrapper>
          {step > 0 && <Button onClick={prevStep}>Назад</Button>}
          {step != 6 && <Button onClick={nextStep}>Далее</Button>}
          {step === 6 && (
            <Button
              onClick={() => {
                setActive.setIndexShow(0);
                setActive.setActive(false);
              }}
            >
              Завершить
            </Button>
          )}
        </ButtonWrapper>
      </Content>
    </ModalWrapper>
  );
};

export default Modal;
