import React from 'react';
import { StyledFC } from '../../../types';
import styled from 'styled-components';

const StyledRoundedBox = styled.div<{ padding?: string; onClick?: () => void }>`
  background-color: white;
  border: 0px black solid;
  border-radius: 18px;
  padding: ${({ padding }) => padding};
`;

const RoundedBox: StyledFC<{ padding?: string; onClick?: () => void }> = ({
  className,
  children,
  padding = 'none',
  onClick,
}) => {
  return (
    <StyledRoundedBox className={className} padding={padding} onClick={onClick}>
      {children}
    </StyledRoundedBox>
  );
};

export default RoundedBox;
