import React from 'react';
import styled from 'styled-components';

interface StyledHeaderProps {
  height?: string;
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  size?: number;
}

interface ChildrenProp {
  children?: any;
}

interface HeaderProps extends ChildrenProp, StyledHeaderProps {}

const StyledHeader = styled.h1<StyledHeaderProps>`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: ${({ size = 24 }) => `${size}px`};
  font-weight: 600;
  height: ${({ height = 'fit-content' }) => height};
  margin: 0;
  line-height: 110%;
  letter-spacing: -0.02em;
`;

const Header: React.FC<HeaderProps> = (props) => {
  return (
    <StyledHeader as={props.type} {...props}>
      {props.children}
    </StyledHeader>
  );
};

export default Header;
