import React from 'react';
import { useTransition, animated } from '@react-spring/web';
import styled from 'styled-components';

import { StyledFC } from '../../../../types';

const Wrapper = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

const StarsAndCircles: StyledFC<{ show: boolean }> = ({ className, show }) => {
  const transition = useTransition(show, {
    from: { opacity: 0, transform: 'scale(0.1) translateY(300px)' },
    enter: { opacity: 1, transform: 'scale(1) translateY(0px)' },
    leave: { opacity: 0, transform: 'scale(0.1) translateY(300px)' },
    config: { tension: 280, friction: 60 },
    delay: 1000,
  });

  return transition(
    (styles, item) =>
      item && (
        <Wrapper style={styles}>
          <svg
            width='452'
            height='328'
            viewBox='0 0 452 328'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect width='452' height='328' fill='none' />
            <circle cx='368' cy='72' r='12' fill='#90D128' />
            <circle cx='332' cy='109' r='8' fill='#90D128' />
            <circle cx='320' cy='239' r='4' fill='#90D128' />
            <path
              d='M129.235 212.626C128.586 212.218 127.739 212.663 127.705 213.428L127.571 216.406C127.559 216.687 127.429 216.949 127.214 217.129L124.926 219.04C124.338 219.532 124.498 220.475 125.216 220.744L128.007 221.791C128.27 221.89 128.479 222.094 128.584 222.355L129.694 225.121C129.98 225.832 130.927 225.971 131.404 225.372L133.263 223.041C133.438 222.821 133.697 222.685 133.977 222.666L136.951 222.465C137.716 222.413 138.14 221.556 137.718 220.916L136.075 218.428C135.921 218.194 135.871 217.906 135.94 217.633L136.667 214.742C136.854 213.999 136.17 213.331 135.431 213.535L132.558 214.328C132.287 214.403 131.998 214.36 131.76 214.211L129.235 212.626Z'
              fill='#90D128'
            />
            <path
              d='M73.1002 90.1387C72.8918 89.4012 71.9652 89.1627 71.4266 89.7079L68.44 92.7307C68.2426 92.9305 67.9705 93.038 67.6899 93.0272L63.4437 92.8628C62.6779 92.8331 62.1647 93.6406 62.5168 94.3214L64.4688 98.0959C64.5977 98.3453 64.6159 98.6374 64.5189 98.9009L63.0504 102.888C62.7856 103.608 63.395 104.345 64.1512 104.221L68.3442 103.531C68.6212 103.485 68.9046 103.558 69.1252 103.732L72.4639 106.361C73.066 106.835 73.9558 106.483 74.0711 105.725L74.7105 101.524C74.7528 101.247 74.9098 101 75.1431 100.844L78.675 98.4808C79.312 98.0547 79.2525 97.0998 78.5675 96.7559L74.7697 94.8496C74.5188 94.7237 74.3324 94.4981 74.256 94.2279L73.1002 90.1387Z'
              fill='#90D128'
            />
            <path
              d='M366.1 100.139C365.892 99.4012 364.965 99.1627 364.427 99.7079L361.44 102.731C361.243 102.93 360.97 103.038 360.69 103.027L356.444 102.863C355.678 102.833 355.165 103.641 355.517 104.321L357.469 108.096C357.598 108.345 357.616 108.637 357.519 108.901L356.05 112.888C355.786 113.608 356.395 114.345 357.151 114.221L361.344 113.531C361.621 113.485 361.905 113.558 362.125 113.732L365.464 116.361C366.066 116.835 366.956 116.483 367.071 115.725L367.711 111.524C367.753 111.247 367.91 111 368.143 110.844L371.675 108.481C372.312 108.055 372.253 107.1 371.568 106.756L367.77 104.85C367.519 104.724 367.332 104.498 367.256 104.228L366.1 100.139Z'
              fill='#90D128'
            />
          </svg>
        </Wrapper>
      )
  );
};

export default StarsAndCircles;
