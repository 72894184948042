import React from 'react';

import { StyledFC } from '../../../../types';

const CirclesAndStarsBg: StyledFC = ({ className }) => {
  return (
    <svg
      width='350px'
      height='200px'
      viewBox='0 0 2561 1473'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Untitled</title>
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='Group'
          transform='translate(117.067480, 241.000000)'
          fillRule='nonzero'
        >
          <path
            d='M2300.3568,797.639052 L2292.23588,801.908472 C2291.09251,802.509574 2289.67835,802.069984 2289.07724,800.92662 C2288.83789,800.471326 2288.75528,799.949823 2288.84224,799.442846 L2290.39319,790.400068 C2290.52332,789.641339 2290.27177,788.867158 2289.72052,788.329824 L2283.15056,781.925692 C2282.22557,781.024039 2282.20664,779.543243 2283.10829,778.618242 C2283.46733,778.249901 2283.9378,778.010193 2284.44683,777.936226 L2293.52629,776.616901 C2294.28809,776.506205 2294.94665,776.027734 2295.28735,775.33742 L2299.3478,767.110028 C2299.91949,765.951674 2301.32196,765.476082 2302.48031,766.047764 C2302.94157,766.275411 2303.31494,766.648765 2303.54257,767.110028 L2307.60304,775.33742 C2307.94373,776.027734 2308.60229,776.506205 2309.3641,776.616901 L2318.44356,777.936226 C2319.72188,778.121976 2320.60758,779.30884 2320.42183,780.58716 C2320.34787,781.096195 2320.10815,781.566649 2319.73981,781.925692 L2313.16985,788.329824 C2312.6186,788.867158 2312.36705,789.641339 2312.49719,790.400068 L2314.04815,799.442846 C2314.26652,800.716001 2313.41144,801.925116 2312.13828,802.143479 C2311.63131,802.230432 2311.1098,802.147834 2310.6545,801.908472 L2302.53359,797.639052 C2301.85221,797.280829 2301.03818,797.280829 2300.3568,797.639052 Z'
            id='Star'
            fill='#90D128'
            transform='translate(2301.445342, 783.991665) rotate(22.000000) translate(-2301.445342, -783.991665) '
          ></path>
          <path
            d='M1764.59831,258.161611 L1754.67274,263.379791 C1753.2753,264.114471 1751.54686,263.577195 1750.81219,262.179749 C1750.51963,261.623279 1750.41867,260.985887 1750.52496,260.366248 L1752.42057,249.313964 C1752.57962,248.386629 1752.27217,247.440407 1751.59843,246.783664 L1743.56847,238.956394 C1742.43791,237.854373 1742.41478,236.04451 1743.5168,234.913953 C1743.95563,234.463759 1744.53063,234.170782 1745.15279,234.080378 L1756.24991,232.467872 C1757.181,232.332575 1757.98591,231.747778 1758.4023,230.904061 L1763.3651,220.84836 C1764.06382,219.432594 1765.77795,218.851314 1767.19372,219.550037 C1767.75748,219.828272 1768.21381,220.284595 1768.49204,220.84836 L1773.45483,230.904061 C1773.87122,231.747778 1774.67613,232.332575 1775.60724,232.467872 L1786.70435,234.080378 C1788.26674,234.307407 1789.34926,235.758018 1789.12224,237.320411 C1789.03183,237.942563 1788.73886,238.517564 1788.28866,238.956394 L1780.25872,246.783664 C1779.58496,247.440407 1779.27751,248.386629 1779.43658,249.313964 L1781.33219,260.366248 C1781.59907,261.922326 1780.55397,263.400134 1778.9979,263.667022 C1778.37825,263.773298 1777.74086,263.672345 1777.1844,263.379791 L1767.25882,258.161611 C1766.42603,257.723782 1765.43112,257.723782 1764.59831,258.161611 Z'
            id='Star'
            fill='#90D128'
            transform='translate(1765.928751, 241.481472) rotate(-3.000000) translate(-1765.928751, -241.481472) '
          ></path>
          <path
            d='M455.034683,977.870128 L447.379756,981.894567 C445.280078,982.99843 442.683092,982.191161 441.579225,980.091491 C441.13966,979.25538 440.987976,978.297693 441.147658,977.366679 L442.609618,968.842794 C442.848593,967.449457 442.386653,966.027749 441.374338,965.040984 L435.181372,959.004335 C433.482697,957.348541 433.447937,954.629204 435.103737,952.930522 C435.763087,952.254106 436.62703,951.813902 437.561821,951.678067 L446.12029,950.434449 C447.519276,950.23117 448.728652,949.352503 449.354298,948.084799 L453.181762,940.329518 C454.231601,938.202315 456.807106,937.328932 458.934312,938.378773 C459.781379,938.796823 460.467008,939.482453 460.88506,940.329518 L464.712523,948.084799 C465.338168,949.352503 466.547546,950.23117 467.946531,950.434449 L476.504999,951.678067 C478.852512,952.01918 480.479019,954.19874 480.137906,956.546254 C480.002073,957.481048 479.561871,958.344992 478.885451,959.004335 L472.692485,965.040984 C471.680168,966.027749 471.218228,967.449457 471.457202,968.842794 L472.919164,977.366679 C473.320166,979.70471 471.749899,981.925128 469.411872,982.326138 C468.480856,982.485812 467.523168,982.334126 466.687064,981.894567 L459.032138,977.870128 C457.780846,977.212288 456.285975,977.212288 455.034683,977.870128 Z'
            id='Star'
            fill='#90D128'
            transform='translate(457.033690, 960.161547) rotate(26.000000) translate(-457.033690, -960.161547) '
          ></path>
          <path
            d='M26.4147109,789.790143 L15.027706,795.776646 C13.4245014,796.619501 11.4415786,796.003115 10.5987239,794.39991 C10.2630948,793.761506 10.1472775,793.030265 10.269202,792.31939 L12.4439264,779.639774 C12.6263954,778.575899 12.2736816,777.490357 11.5007303,776.736916 L2.28844994,767.757162 C0.991430106,766.492879 0.964890735,764.416535 2.22917261,763.119514 C2.73261625,762.603034 3.39227882,762.266919 4.10603675,762.163205 L16.8370952,760.313273 C17.9052876,760.158056 18.8287043,759.487155 19.3064144,758.519207 L24.9999168,746.982912 C25.8015192,745.358688 27.7680399,744.691822 29.3922635,745.493424 C30.0390379,745.812627 30.5625491,746.336137 30.8817514,746.982912 L36.5752538,758.519207 C37.052964,759.487155 37.9763807,760.158056 39.0445731,760.313273 C41.2472923,760.633347 42.8993318,760.873402 44.0006914,761.033439 C45.7284559,761.284498 48.3201026,761.661087 51.7756315,762.163205 C53.5680687,762.423662 54.8099847,764.08786 54.5495283,765.880297 C54.4458132,766.594056 54.1096984,767.253718 53.5932183,767.757162 L44.3809378,776.736916 C43.6079866,777.490357 43.2552728,778.575899 43.4377418,779.639774 L45.6124663,792.31939 C45.918651,794.104586 44.7196767,795.799984 42.934482,796.106168 C42.2236082,796.228093 41.4923667,796.112275 40.8539621,795.776646 L29.4669574,789.790143 C28.511537,789.287849 27.3701311,789.287849 26.4147109,789.790143 Z'
            id='Star'
            fill='#90D128'
            transform='translate(27.941047, 770.654030) rotate(-3.000000) translate(-27.941047, -770.654030) '
          ></path>
          <path
            d='M1003.48486,150.378321 L995.829938,154.402754 C993.73026,155.506619 991.133273,154.699355 990.029406,152.599676 C989.589841,151.763573 989.438158,150.805882 989.597838,149.874869 L991.0598,141.350982 C991.298776,139.95765 990.836835,138.535941 989.824519,137.549178 L983.631554,131.512529 C981.932878,129.856729 981.89812,127.137389 983.553919,125.438714 C984.213267,124.762293 985.077212,124.322091 986.012003,124.186258 L994.570471,122.942639 C995.969458,122.739354 997.178834,121.860691 997.804479,120.592995 L1001.63194,112.837708 C1002.68178,110.7105 1005.25729,109.837121 1007.38449,110.886962 C1008.23156,111.305012 1008.91719,111.990643 1009.33524,112.837708 L1013.1627,120.592995 C1013.78835,121.860691 1014.99773,122.739354 1016.39671,122.942639 L1024.95518,124.186258 C1027.30269,124.527371 1028.9292,126.706933 1028.58809,129.054445 C1028.45225,129.989236 1028.01205,130.853181 1027.33563,131.512529 L1021.14266,137.549178 C1020.13035,138.535941 1019.66841,139.95765 1019.90738,141.350982 L1021.36934,149.874869 C1021.77035,152.212895 1020.20008,154.433317 1017.86205,154.83432 C1016.93104,154.994002 1015.97335,154.842319 1015.13724,154.402754 L1007.48232,150.378321 C1006.23103,149.720478 1004.73616,149.720478 1003.48486,150.378321 Z'
            id='Star'
            fill='#90D128'
            transform='translate(1005.483870, 132.669735) rotate(-3.000000) translate(-1005.483870, -132.669735) '
          ></path>
          <circle
            id='Oval'
            fill='#F64949'
            cx='918.43252'
            cy='38.5'
            r='38.5'
          ></circle>
          <circle
            id='Oval'
            fill='#F64949'
            cx='1832.93252'
            cy='187'
            r='31'
          ></circle>
          <circle
            id='Oval'
            fill='#F3AB3F'
            cx='1832.93252'
            cy='288'
            r='19'
          ></circle>
          <circle
            id='Oval'
            fill='#F3AB3F'
            cx='919.93252'
            cy='179'
            r='23'
          ></circle>
          <circle
            id='Oval'
            fill='#F64949'
            cx='1093.93252'
            cy='570'
            r='9'
          ></circle>
          <circle
            id='Oval'
            fill='#F64949'
            cx='1595.93252'
            cy='679'
            r='9'
          ></circle>
          <circle
            id='Oval'
            fill='#90D128'
            cx='1963.93252'
            cy='942'
            r='9'
          ></circle>
        </g>
      </g>
    </svg>
  );
};

export default CirclesAndStarsBg;
