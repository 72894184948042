import React from 'react';
import { StyledFC } from '../../../types';
import styled from 'styled-components';

const _ProgressBar: StyledFC<{ percentage: number; currentExp?: any }> = ({
  className,
  percentage,
  currentExp,
}) => {
  return (
    <div className={className}>
      <div className={'progress-level'} />

      {(currentExp || currentExp !== 0) && (
        <div className={'currentExp'}>{currentExp}</div>
      )}
    </div>
  );
};

const ProgressBar = styled(_ProgressBar)`
  height: 100%;
  background-color: #d5d5d5;
  width: 2px;
  position: relative;

  .currentExp {
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    border: solid 0 black;
    margin-left: -14px;
    margin-top: -10px;
    border-radius: 44.7097px;
    top: ${(props) => props.percentage}%;
    width: 36px;
    position: absolute;
    background-color: #90d128;
  }

  & > .progress-level {
    border: solid 0 black;
    border-radius: 2px;
    height: ${(props) => props.percentage}%;
    width: 3px;
    position: absolute;
    background-color: #90d128;
  }
`;

export default ProgressBar;
