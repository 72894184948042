import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import useCollapse from 'react-collapsed';
import RowItemsList from '../RowItemsList';
import { taskService } from '../../services';
import { StyledFC } from '../../types';
import { FillableButton, Button } from '../MedalsNew/Button';

export interface Task {
  isNewAdded: boolean;
  actionId: string;
  id: number;
  awardId: number;
  completed: boolean;
  isChecked: boolean;
  taken: boolean;
  linkToTask: string;
  logo: string;
  backgroundColor: string;
  completionDate: string | null;
  exp: number;
  isNew: boolean;
  isActive: boolean;
  descriptionToExecution: string;
  motivatingPrompt: string;
  nameInSmallCard: string;
  buttonText: string;
  playbookId: number;
  description: string;
  total: number;
  progress: number;
  timeLeft: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  };
}

type Brand = 'All' | 'SberAgent' | 'Logo_Welness';

const pageSize = 50;

const formatTime = (timeLeft: any) => {
  const date = moment(timeLeft);
  const now = moment();
  return {
    days: date.diff(now, 'days'),
    hours: date.diff(now, 'hours') - date.diff(now, 'days') * 24,
    minutes: date.diff(now, 'minutes') - date.diff(now, 'hours') * 60,
    seconds: date.diff(now, 'seconds') - date.diff(now, 'minutes') * 60,
  };
};

const getFormattedTasksData = (data: Task[]) =>
  data.map((t: Task) => ({
    ...t,
    id: t.playbookId,
    timeLeft: formatTime(t.timeLeft),
  }));

const compareTimeDesc = (a: Task, b: Task) => {
  if (b.timeLeft.days > a.timeLeft.days) return -1;
  if (b.timeLeft.days < a.timeLeft.days) return 1;

  if (b.timeLeft.hours > a.timeLeft.hours) return -1;
  if (b.timeLeft.hours < a.timeLeft.hours) return 1;

  if (b.timeLeft.minutes > a.timeLeft.minutes) return -1;
  if (b.timeLeft.minutes < a.timeLeft.minutes) return 1;

  return 0;
};

const _TasksNew: StyledFC = ({ className }) => {
  const [completedTasks, setCompletedTasks] = useState<Task[]>([]);
  const [startedTasks, setStartedTasks] = useState<Task[]>([]);
  const [availableTasks, setAvailableTasks] = useState<Task[]>([]);
  const [filteredText, setFilteredText] = useState<Brand>('All');

  const [isExpanded, setExpanded] = useState(true);
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
    duration: 300,
  });

  useEffect(() => {
    taskService
      .getTasks(50, 'ALL')
      .then(({ data }) => getFormattedTasksData(data))
      .then((data) => data.filter((t) => t.isActive))
      .then((data) =>
        filteredText === 'All'
          ? data
          : data.filter((t) => t.logo.includes(filteredText))
      )
      .then((formatedData) => {
        //@ts-ignore
        window.ym(88659232, 'reachGoal', 'activeTask');

        setCompletedTasks(
          formatedData.filter((t) => t.completed && !t.isChecked)
        );
        setStartedTasks(
          formatedData.filter((t) => !t.completed && t.progress > 0)
        );
        return formatedData;
      })
      .then((formatedData) => {
        const sortedArray = formatedData.sort(compareTimeDesc);
        return sortedArray;
      })
      .then((sortedData) =>
        setAvailableTasks(sortedData.filter((t) => !t.completed && !t.progress))
      )
      .catch((e) => {
        console.warn(e);
      });
  }, [filteredText]);

  const toggleButtonClickHandler = () => {
    setExpanded(false);

    const arrOfTasksIdsToBeChecked = completedTasks.map((val) => ({
      id: val.playbookId,
      actionId: val.actionId,
    }));
    const arrOfTaskAward = completedTasks.map((val) => ({
      id: val.awardId,
      actionId: val.actionId,
    }));
    for (let i = 0; i < arrOfTaskAward.length; i += 1) {
      taskService.completeTask(arrOfTaskAward[i].id);
      console.log(arrOfTaskAward);
      //@ts-ignore
      window.ym(88659232, 'reachGoal', 'LIKE_ARTICLE');
    }
    taskService.checkTasks(arrOfTasksIdsToBeChecked);
  };

  return (
    <div className={className}>
      {!!completedTasks.length && (
        <div {...getCollapseProps()} className='collapsable-block'>
          <RowItemsList className='started-tasks-block' data={completedTasks} />
          <div
            {...getToggleProps({
              onClick: toggleButtonClickHandler,
            })}
            className='button-tasks-block'
          >
            <FillableButton padding='12px 16px'>Готово</FillableButton>
          </div>
        </div>
      )}
      <div className='filter-bottons-block'>
        <Button
          fs='14px'
          lh='110%'
          ls='-0.02em'
          margin='0 6px 0 0'
          outlined={filteredText !== 'All'}
          onClick={() => setFilteredText('All')}
        >
          Все
        </Button>
        <Button
          fs='14px'
          lh='110%'
          ls='-0.02em'
          margin='0 6px 0 0'
          outlined={filteredText !== 'SberAgent'}
          onClick={() => setFilteredText('SberAgent')}
        >
          СберАгент
        </Button>
        <Button
          fs='14px'
          lh='110%'
          ls='-0.02em'
          outlined={filteredText !== 'Logo_Welness'}
          onClick={() => setFilteredText('Logo_Welness')}
        >
          Wellness
        </Button>
      </div>
      <RowItemsList className='started-tasks-block' data={startedTasks} />
      <RowItemsList data={availableTasks} />
    </div>
  );
};

const TasksNew = styled(_TasksNew)`
  display: flex;
  flex-direction: column;

  .started-tasks-block {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .collapsable-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .button-tasks-block {
    align-self: center;
  }

  .filter-bottons-block {
    align-self: center;
    margin-top: 40px;
  }
`;

export default TasksNew;
