import React from 'react';
import { StyledFC } from '../../types';

const _TaskIndicatortIcon: StyledFC = ({ className }) => {
  return (
    <svg
      width='30'
      height='31'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.9764 8.47088C27.7687 8.11367 27.3518 7.95007 26.9399 7.98266C26.7948 7.99415 26.6481 8 26.5 8C23.4624 8 21 5.53757 21 2.5C21 2.34966 20.9149 2.21043 20.7761 2.15249C18.9983 1.41005 17.047 1 15 1C6.71573 1 0 7.71573 0 16C0 19.3764 1.11557 22.4923 2.99824 24.9991C2.99866 24.9997 2.99931 25 3 25C4.65685 25 6 26.3431 6 28C6 28.0007 6.00032 28.0013 6.00088 28.0018C8.50771 29.8844 11.6236 31 15 31C23.2843 31 30 24.2843 30 16C30 13.2558 29.2631 10.6837 27.9764 8.47088Z'
        fill='#FFB341'
      />
      <circle cx='26.5' cy='2.5' r='2.5' fill='#FFB341' />
      <circle cx='3' cy='28' r='1' fill='#FFB341' />
      <path
        d='M14.14 8.44954C14.5274 7.79655 15.4726 7.79655 15.86 8.44954L17.9444 11.9626C18.0904 12.2088 18.335 12.3806 18.6161 12.4345L22.6939 13.2161C23.4745 13.3658 23.777 14.3224 23.2242 14.8936L20.4674 17.7425C20.2576 17.9593 20.1569 18.2592 20.1933 18.5587L20.6668 22.4511C20.7602 23.2186 19.9845 23.7972 19.2754 23.4889L15.3987 21.8034C15.1444 21.6928 14.8556 21.6928 14.6013 21.8034L10.7246 23.4889C10.0155 23.7972 9.23979 23.2186 9.33317 22.4511L9.8067 18.5587C9.84313 18.2592 9.74242 17.9593 9.53264 17.7425L6.77578 14.8936C6.22301 14.3224 6.52547 13.3658 7.30615 13.2161L11.3839 12.4345C11.665 12.3806 11.9096 12.2088 12.0556 11.9626L14.14 8.44954Z'
        fill='white'
      />
    </svg>
  );
};

export default _TaskIndicatortIcon;
