import React from 'react';
import { StyledFC } from '../../../types';
import styled from 'styled-components';

const _SimpleIconContainer: StyledFC<{
  size: number;
  color: string;
  disabled?: boolean;
}> = ({ className, children, color, size, disabled }) => {
  return <div className={className}>{children}</div>;
};

const SimpleIconContainer = styled(_SimpleIconContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0);
  color: ${(props) => `${props.color}`};
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  border: 0px solid black;
`;

export default SimpleIconContainer;
