import {connect} from "react-redux";
import RankPage from "./index";
import {setProfileRank} from "../../../reducers/profileReducer";





let mapStateToProps = (state:any) => {
    return{
        profileData: state.profileReducer,
    }
}


let mapDispatchToProps = (dispatch:any) => {
    return{
        setProfileRank: (data:any)=>{
dispatch(setProfileRank(data));
        }

    }
}
const RankPageContainer = connect(mapStateToProps,mapDispatchToProps)(RankPage);
export default RankPageContainer;