import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { animated } from '@react-spring/web';

import { StyledFC } from '../../../../../types';

const TickerColumnContainer = styled.div`
  position: relative;
`;
const TickerColumn = styled(animated.div)`
  position: absolute;
  height: 1000%;
  bottom: 0;
  font-weight: 800;
  background: linear-gradient(
    98.04deg,
    #2a64f6 12.71%,
    #265ce7 84.77%,
    #c214ff 92.13%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const TickerDigit = styled.div`
  height: 10%;
`;

const NumberPlaceholder = styled.div`
  visibility: hidden;
`;

const NumberColumn: StyledFC<{ digit: number; startAtHeightValue: number }> = ({
  digit,
  startAtHeightValue,
}) => {
  const [position, setPosition] = useState(startAtHeightValue);
  const columnContainer = useRef<HTMLDivElement>(null);

  const setColumnToNumber = (number: number) => {
    if (columnContainer.current && columnContainer.current.clientHeight) {
      setPosition(
        columnContainer.current.clientHeight * parseInt(String(number))
      );
    }
  };

  useEffect(() => setColumnToNumber(digit), [digit]);

  return (
    <TickerColumnContainer ref={columnContainer}>
      <TickerColumn style={{ y: position, transition: 'all .5s ease' }}>
        {[9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((n) => (
          <TickerDigit key={n}>
            <span>{n}</span>
          </TickerDigit>
        ))}
      </TickerColumn>
      <NumberPlaceholder>9</NumberPlaceholder>
    </TickerColumnContainer>
  );
};

export default NumberColumn;
