import React, { useContext } from 'react';
import styled from 'styled-components';
import { NavContext } from '../../main';
import LevelBar from '../Header/LevelBar';
import { StyledFC } from '../../types';

const _RankBox: StyledFC<any> = (props, { className }) => {
  const { push } = useContext(NavContext);

  return (
    <div className={props.className}>
      <div className={'rank-block'} onClick={() => push('/settings')}>
        <div className={'bottom-sheet-sign'}></div>
        <div className={'rank'}>
          <label>{props?.profileData.rankName}</label>
        </div>
        <div className={'name'}>{props?.profileData.nickname}</div>


        {props?.profileData ? (
          <LevelBar
            level={props.profileData.level}
            total={props.profileData.totalExp}
            gained={props.profileData.earnedExp}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const RankBox = styled(_RankBox)`
  .rank-block {
    height: fit-content;
    padding: 6px 20px 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-bottom: 0 solid black;
    border-radius: 24px;
    background: white;
    cursor: pointer;
  }

  .bottom-sheet-sign {
    width: 40px;
    height: 6px;
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: #f4f4f4;
  }

  .name {
    cursor: pointer;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 31px;
    color: #212121;
    font-weight: 600;
    font-size: 28px;
    font-style: normal;
    margin-bottom: 10px;
  }

  .rank {
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 5004;
    font-size: 22px;
    margin-bottom: 12px;
    cursor: pointer;

    img {
      display: flex;

      width: 64px;
      height: 64px;
      margin-right: 8px;
    }

    label {
      cursor: pointer;
    }
  }
`;

export default RankBox;
