import React, { createContext, useState, FC } from 'react';

import { Medal } from '../Grid';

const initialState = { medal: {} as Medal, setMedal: (_: Medal) => {} };

export const MedalsContext = createContext(initialState);

export const MedalsContextProvider: FC = ({ children }) => {
  const [medal, set] = useState<Medal>({} as Medal);

  const setMedal = (medal: Medal) => set(medal);

  const value = {
    medal,
    setMedal,
  };

  return (
    <MedalsContext.Provider value={value}>{children}</MedalsContext.Provider>
  );
};
