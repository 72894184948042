import React from 'react';

import { Medal } from './Grid';
import GridItem from './GridItem';

interface GridListData {
  data: Medal[];
}

const GridList: React.FC<GridListData> = ({ data }) => {
  return (
    <>
      {data.map((dataObj) => (
        <GridItem key={dataObj.id} data={dataObj} />
      ))}
    </>
  );
};

export default GridList;
