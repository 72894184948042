import {applyMiddleware, combineReducers, createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import profileReducer from "../reducers/profileReducer";

let reducersBatch = combineReducers({
profileReducer: profileReducer
}) ;

const store = createStore(reducersBatch, applyMiddleware(thunkMiddleware));

export default store;