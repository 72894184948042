import React from 'react';
import { StyledFC } from '../../../types';
import styled from 'styled-components';

const _LevelBar: StyledFC<{
  level: number;
  total: number;
  gained: number;
}> = ({ className, level, total, gained }) => {
  return (
    <div className={className}>
      <div className={'info'}>
        <div className={'level'}>
          <label>{level}</label>
          <label>уровень</label>
        </div>
        <div className={'xp'}>
          <label>
            {gained} из {total} XP
          </label>
        </div>
      </div>
      <div className={'bar'}>
        <div className={'progress'} />
      </div>
    </div>
  );
};

const LevelBar = styled(_LevelBar)`
  padding: 0 20px 0 20px;
  width: 100%;

  .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .level {
      color: #212121;
      label {
        font-size: 16px;
        font-weight: 400;
      }
      label:first-child {
        margin-right: 7px;
        font-size: 22px;
      }
    }

    .xp {
      font-size: 16px;
      color: #212121;
      align-self: flex-end;
    }
  }

  .bar {
    margin: 7px 0 7px 0;
    height: 8px;
    position: relative;
    background-color: #ebebeb;
    border: 0px solid black;
    border-radius: 4px;

    .progress {
      position: absolute;
      background: linear-gradient(90deg, #90d027 70%, #ffb11b 100%);
      height: 8px;
      width: ${(props) => (props.gained / props.total) * 100}%;
      border: 0px solid black;
      border-radius: 4px;
    }
  }
`;

export default LevelBar;
