import React, { useState } from 'react';
import { StyledFC } from '../../../types';
import EditAvatarIconSVG from '../../../iconsSource/EditAvatarIconSVG';
import CircleIconContainer from '../CircleIconContainer';
import styled from 'styled-components';
import ArrowLeftSVG from '../../../iconsSource/ArrowLeftSVG';

const _CloseIcon: StyledFC<{
  size: number;
  fontSize?: number;
  color?: string;
  backgroundColor?: string;
  onClick?: () => void;
}> = ({
  className,
  size,
  color,
  backgroundColor = '#ffffff',
  fontSize = 16,
  onClick,
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      <CircleIconContainer size={size} backgroundColor={backgroundColor}>
        <EditAvatarIconSVG fontSize={fontSize} color={'#212121'} />
      </CircleIconContainer>
    </div>
  );
};

const CircleCloseIcon = styled(_CloseIcon)``;

export default CircleCloseIcon;
