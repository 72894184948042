import React, { useContext, useEffect, useRef, useState } from 'react';
import { animated } from '@react-spring/web';
import styled from 'styled-components';
import { tokenCookies } from '../../utils';
import { useCookies } from 'react-cookie';
import CircleEditAvatarIcon from '../_shared/icons/CircleEditAvatarIcon';
import { Preloader } from '../_shared/Preloader';
import MainPageContainer from '../../pages/MainPage/MainPageContainer';
import { NavContext } from '../../main';
import { MedalsContext } from '../MedalsNew/MedalsContext';
import { TasksContext } from '../TasksNew/TasksContext';
import SideDrawer from '../SideDrawer';
import SettingsContainer from '../SettingsNew/SetiingsContainer';
import MedalsNew from '../MedalsNew';
import MedalNew from '../MedalNew';
import TasksNew from '../TasksNew';
import TaskNew from '../Task';
import { StyledFC } from '../../types';
import RankPageContainer from '../SettingsNew/RankPage/RankPageContainer';
import Seasons from '../Seasons';
import { SeasonReward } from '../Seasons/SeasonReward';
import { profileService } from '../../services';

const _AppContainer: StyledFC<{ onClose: () => void; isOpen: boolean }> = (
  props: any,
  { className, onClose, isOpen }
) => {
  const [onboardingViewed, _] = useCookies(['onboardingVieved']);
  const iFrameRef = useRef<any>();
  const { push, route } = useContext(NavContext);
  const { medal } = useContext(MedalsContext);
  const { task } = useContext(TasksContext);
  const [isPreloaderShown, setIsPreloaderShown] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [preload, setPreload] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  let userToken: any;
  if (!tokenCookies.get('token')) {
    userToken = localStorage.getItem('userToken');
  } else {
    userToken = tokenCookies.get('token');
  }
  useEffect(() => {
    push('/');
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (onboardingViewed.onboardingVieved) {
      timer = setTimeout(() => {
        setIsShown(true);
      }, 600);
    }

    return () => {
      setIsShown(false);
      clearTimeout(timer);
    };
  }, [isOpen, onboardingViewed.onboardingVieved]);

  const setRoute = () => {
    switch (route) {
      case '/seasons':
      case '/tasks':
      case '/medals':
      case '/settings':
      case '/settings/avatar':
      case `/medals/${medal.name}`:
      case `/tasks/${task.descriptionToExecution}`:
        push('/');
        break;

      default:
        break;
    }
    onClose();
  };
  useEffect(() => {
    if (userToken) {
      setPreload(true);
    }
  }, [userToken]);

  useEffect(() => {
    let timer: NodeJS.Timer;

    if (route === '/settings/avatar') {
      setIsPreloaderShown(true);

      timer = setTimeout(() => {
        setIsPreloaderShown(false);
      }, 6000);
    }

    return () => clearTimeout(timer);
  }, [route]);

  function sendToken() {
    const token = {
      token: userToken,
    };
    iFrameRef.current.contentWindow.postMessage(token, '*');
  }

  return (
    <AppContainer className={className}>
      <animated.div></animated.div>

      <animated.div className={'cont'}>
        <div className={'inner'}>
          <div className={'content'} ref={contentRef}>
            <SideDrawer
              routeSettings={'/seasons'}
              onClick={() => push('/')}
              header={props.profileData.seasonName}
              //header={'Сезон завершен!'}
            >
              <Seasons />
            </SideDrawer>

            <SideDrawer
              routeSettings={'/tasks'}
              onClick={() => {
                profileService.getProfile().then((data: any) => {
                  props.setProfile(data.data);
                });
                push('/');
              }}
              header={'Задания'}
            >
              <TasksNew />
            </SideDrawer>

            <SideDrawer
              routeSettings={`/tasks/${task.descriptionToExecution}`}
              onClick={() => push('/tasks')}
            >
              <TaskNew data={task} />
            </SideDrawer>

            <SideDrawer
              routeSettings={'/medals'}
              onClick={() => push('/')}
              header={'Медали'}
            >
              <MedalsNew />
            </SideDrawer>
            <SideDrawer
              routeSettings={'/reward'}
              onClick={() => push('/seasons')}
            >
              <SeasonReward />
            </SideDrawer>
            <SideDrawer
              routeSettings={`/medals/${medal.name}`}
              onClick={() => push('/medals')}
            >
              <MedalNew data={medal} />
            </SideDrawer>

            <SideDrawer
              routeSettings={`/settings`}
              onClick={() => push('/')}
              header={'Редактирование'}
            >
              <SettingsContainer />
            </SideDrawer>
            <SideDrawer
              routeSettings={`/settings/rank`}
              onClick={() => push('/settings')}
              header={'Звание'}
            >
              <RankPageContainer />
            </SideDrawer>

            <SideDrawer
              preloadable={isShown && preload}
              routeSettings={`/settings/avatar`}
              onClick={() => push('/settings')}
              header={'Аватар'}
            >
              {isPreloaderShown && <Preloader />}
              <iframe
                onLoad={sendToken}
                ref={iFrameRef}
                id='avatar_iframe'
                src='pg_icon/index.html'
                style={{
                  width: '468px',
                  height: '100%',
                  margin: 0,
                  padding: 0,
                  marginLeft: '-24px',
                  border: 'none',
                }}
                sandbox='allow-same-origin allow-scripts'
              />
            </SideDrawer>

            <div className={'share-and-edit'}>
              <div className={'edit'}>
                <CircleEditAvatarIcon
                  size={40}
                  onClick={() => push('/settings')}
                />
              </div>
            </div>
            <div className={'shtorka'}>
              <div onClick={() => onClose()} />
            </div>

            <MainPageContainer
              startWidgetPreloading={(b: boolean) => setIsShown(b)}
            />
          </div>
        </div>
      </animated.div>
    </AppContainer>
  );
};

const width = 452;
const gap = 0;

const AppContainer = styled.div`
  position: absolute;
  top: ${gap}px;
  left: ${gap}px;

  .tint {
    position: absolute;
    top: -${gap}px;
    left: -${gap}px;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .close {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 24px;
    right: -64px;
  }

  .cont {
    position: relative;
    overflow: hidden;
  }

  .inner {
    background-color: #f4f4f4;
    width: ${width}px;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
    height: calc(100vh - ${gap * 2}px);

    .content {
      padding: 0 16px;

      height: 100%;
      & > .share-and-edit {
        position: absolute;
        right: 16px;
        top: 24px;
        z-index: 1;
      }

      & > .share-and-edit > .share {
        margin-bottom: 16px;
      }

      & > .share-and-edit > div {
        cursor: pointer;
      }

      .shtorka {
        display: none;
        margin-bottom: 41px;

        & > div {
          width: 40px;
          height: 6px;
          border: 0 solid black;
          border-radius: 3px;
          background-color: #323232;
        }
      }
    }
  }
`;

export default _AppContainer;
