import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { StyledFC } from '../../types';
import HeaderContainer from '../../components/Header/headerContainer';
import Onboarding from '../../components/Onboarding';
import RankBoxContainer from '../../components/RankBox/RankBoxContainer';
import HelpIcon from '../../components/_shared/icons/HelpIcon';
import {
  profileService,
  salesServices,
  medalsService,
  seasonServices,
} from '../../services';
import { getDateAfter30Days, tokenCookies } from '../../utils';
import Modal from '../../components/About/About';
import MenuContainerComponent from '../../components/Menu/MenuContainer';
import OnboardingSecondRun from '../../components/OnboardingSecondRun';
import { Preloader } from '../../components/_shared/Preloader';
import axios from 'axios';

export const ProfileContext = React.createContext({
  refetchUser: () => undefined,
});

const AboutWrapper = styled.div<{ zIndex: number; top?: number }>`
  top: ${(props) => props.top}px;
  position: relative;
  z-index: ${(props) => props.zIndex};
  transition: 150ms;
`;
interface awardsType {
  img: string;
  name: string;
  type: string;
  lvl?: number;
  exp?: number;
}

const loadingText = 'Загружаем PlayGround. Пожалуйста подождите';
const _MainPage: StyledFC<any> = (
  props,
  { className, startWidgetPreloading }
) => {
  const [showAbout, setShowAbout] = useState(false);
  const [profileInfo, setProfileInfo] = useState(0);
  const [newMedals, setNewMedals] = useState<any>();
  const [newRanks, setNewRanks] = useState<any>();
  const [newSeasonAward, setNewSeasonAward] = useState<any>();
  const [newExp, setNewExp] = useState<number>(0);
  const [showSecondOb, setShowSecondOb] = useState(false);
  const [secondObEnabled, setSecondObEnabled] = useState(false);
  const [indexShow, setShowIndex] = useState(0);
  const [preloader, setPreloader] = useState(false);
  const [token, setToken] = useState();
  const [awardsList, setAwardsList] = useState<awardsType[]>([]);

  useEffect(() => {
    salesServices.getProfileIngo();

    async function listener(event: any) {
      if (event.data.token) {
        setToken(event.data.token);
        props.setToken(event.data.token);
        tokenCookies.set('token', event.data.token, {
          path: '/',
          sameSite: 'lax',
          expires: getDateAfter30Days(),
        });
        localStorage.setItem('userToken', event.data.token);
      }
    }
    window.addEventListener('message', listener);
    return () => document.removeEventListener('message', listener);
  }, []);

  const closeOnboarding = () => {
    salesServices.getProfileIngo();
    props.setOB(false);
    startWidgetPreloading(false);
  };

  const refetchUser = () => {
    profileService
      .getProfile()
      .then(({ data }) => {})
      .catch((e) => {
        console.error(e);
      });

    return undefined;
  };

  useEffect(() => {
    if (token) {
      salesServices.getProfileIngo();
      // seasonServices.completeAwards();
      axios
        .get(
          'https://wellnessme.ru/api/v1/content/actions/check-last-open-widget ',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.longTimeAgo === true) {
            setShowSecondOb(true);
          }
        });

      profileService
        .getProfile()
        .then(({ data }) => {
          props.setProfile(data);
          if (data.nickname === '' || !data.nickname) {
            props.setNickname(data.name.firstName);
            profileService.setNickname(data.name.firstName);
          }
          setPreloader(true);
        })
        .catch((e) => {
          console.error(e);
        });
    }

    if (!props.profileData.showOnboarding) {
      props.startWidgetPreloading(false);
    }
  }, [token]);
  //---------------- ДЛЯ АНБОРДИНГА С НОТИФИКАЦИЯМИ
  useEffect(() => {
    if (showSecondOb) {
      profileService.gottenRanks().then((response) => {
        setNewRanks(response.data);
      });
      profileService.prevUserState().then((respone) => {
        console.log(respone);
        setProfileInfo(respone.data.lastLevel);
        setNewExp(respone.data.lastExperience);
      });
      seasonServices.getSeasons().then((response) => {
        setNewSeasonAward(
          response.data.cards.filter((item: any) => item.status === 'EARNED')
        );
      });
      medalsService.getAllMedals(50, 'NEW').then((response) => {
        setNewMedals(response.data);
      });
    }
  }, [showSecondOb]);
  //--------------ДЛЯ АНБОРДИНГА С НОТИФИКАЦИЯМИ//
  useEffect(() => {
    if (false) {
      setAwardsList([
        ...awardsList,
        {
          img: newSeasonAward[0].awards[0].imageFileKey || '',
          name: newSeasonAward[0]?.awards[0].title,
          type: 'Сезонная награда',
        },
      ]);
    }
  }, [newSeasonAward]);

  useEffect(() => {
    if (profileInfo) {
      setAwardsList([
        ...awardsList,
        {
          img: 'LVL',
          name: 'Повышение',
          type: 'Уровень',
          lvl: profileInfo,
        },
      ]);
    }
  }, [profileInfo]);
  useEffect(() => {
    if (newMedals) {
      for (let i = 0; i < newMedals.length; i++) {
        setAwardsList((prev) => [
          ...prev,
          {
            img: newMedals[i].img,
            name: newMedals[i].name,
            type: 'Медаль',
          },
        ]);
      }
    }
  }, [newMedals]);
  useEffect(() => {
    if (newRanks) {
      for (let i = 0; i < newRanks.length; i++) {
        setAwardsList((prev) => [
          ...prev,
          {
            img: newRanks[i].medalSrc,
            name: newMedals[i].name,
            type: 'Звание',
          },
        ]);
      }
    }
  }, [newRanks]);
  useEffect(() => {
    if (newExp) {
      setAwardsList([
        ...awardsList,
        {
          img: 'XP',
          name: 'Повышение',
          type: 'XP',
          exp: newExp,
        },
      ]);
    }
  }, [newExp]);

  useEffect(() => {
    if (awardsList.length > 0) {
      setSecondObEnabled(true);
    }
  }, [awardsList]);
  return preloader ? (
    <div className={props.className}>
      <ProfileContext.Provider value={{ refetchUser: refetchUser }}>
        {secondObEnabled && (
          <OnboardingSecondRun
            awards={awardsList}
            onClose={() => setSecondObEnabled(false)}
          />
        )}
        <AboutWrapper
          top={indexShow === 5 ? 10 : 0}
          zIndex={indexShow === 5 ? 99999 : 0}
        >
          <HeaderContainer
            videoSize={indexShow === 5 ? 650 : 500}
            height={indexShow === 5 ? 516 : 416}
          />
        </AboutWrapper>
        {props.profileData.showOnboarding && (
          <Onboarding close={closeOnboarding} />
        )}
        {showAbout && (
          <Modal setIndexShow={setShowIndex} setActive={setShowAbout} />
        )}
        <div className={'bottom-sheet'}>
          <div className={'background'}>
            <AboutWrapper
              top={indexShow === 5 ? 200 : 0}
              zIndex={indexShow === 1 ? 99999 : 0}
            >
              <RankBoxContainer />
            </AboutWrapper>
            <AboutWrapper
              top={indexShow === 5 ? 200 : 0}
              zIndex={indexShow > 1 && indexShow < 5 ? 99999 : 0}
            >
              <MenuContainerComponent
                seasonNotify={newSeasonAward}
                token={props.profileData.token}
                indexShow={indexShow}
                setIndexShow={setShowIndex}
                activeShow={showAbout}
                setActive={setShowAbout}
              />
            </AboutWrapper>
            {!showAbout && (
              <div
                onClick={() => setShowAbout(!showAbout)}
                className={'help-section'}
              >
                <HelpIcon className={'help-icon'} color={'inherit'} size={35} />
                <p>Помощь</p>
              </div>
            )}
          </div>
        </div>
      </ProfileContext.Provider>
    </div>
  ) : (
    <>
      <Preloader loadingText={loadingText} />
    </>
  );
};

const MainPage = styled(_MainPage)`
  .bottom-sheet {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    top: 42vh;
  }

  .background {
    position: relative;
    //  z-index: 3;
    background-color: #f4f4f4;

    border-radius: 24px;
  }

  .help-section {
    display: flex;
    margin-left: 16px;
    padding-top: 34px;
    padding-bottom: 34px;
    cursor: pointer;
    @media (max-width: 768px) {
      display: none;
    }
    &:hover .help-icon {
      transform: rotate(-5deg) scale(1.09);
    }

    & > p {
      margin: 0 0 0 14px;
      align-self: center;
      font-weight: 400;
      font-size: 20px;
    }
  }

  .help-icon {
    transition: transform 0.2s ease;
  }
`;

export default MainPage;
