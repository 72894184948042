import React from 'react';

import { StyledFC } from '../../../../types';

const RedBg: StyledFC = ({ className }) => {
  return (
    <svg
      width='150px'
      height='150px'
      viewBox='0 0 2383 1504'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Untitled</title>
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M331.924514,366.052712 L299.503098,382.13285 C296.534461,383.605214 292.934318,382.392244 291.461954,379.423606 C290.888077,378.266532 290.702889,376.955164 290.933915,375.684423 L296.950787,342.589086 C297.318884,340.564394 296.623669,338.492313 295.108897,337.099352 L269.877749,313.897191 C267.438582,311.654172 267.279573,307.858511 269.522591,305.419345 C270.467517,304.391788 271.741168,303.725875 273.124213,303.536283 L308.62657,298.669495 C310.550574,298.405746 312.227941,297.227098 313.128139,295.506342 L329.274015,264.643022 C330.810059,261.706828 334.435524,260.571783 337.371718,262.107827 C338.455418,262.674755 339.339985,263.559322 339.906913,264.643022 L356.05279,295.506342 C356.952988,297.227098 358.630355,298.405746 360.554358,298.669495 L396.056716,303.536283 C399.339721,303.986328 401.636288,307.012564 401.186242,310.295569 C400.99665,311.678614 400.330737,312.952265 399.30318,313.897191 L374.072031,337.099352 C372.557259,338.492313 371.862044,340.564394 372.230142,342.589086 L378.247014,375.684423 C378.839744,378.94469 376.677279,382.06816 373.417013,382.66089 C372.146272,382.891916 370.834904,382.706727 369.67783,382.13285 L337.256414,366.052712 C335.576736,365.219637 333.604192,365.219637 331.924514,366.052712 Z'
          id='Star'
          fill='#F64949'
          transform='translate(334.590464, 327.980473) rotate(-18.000000) translate(-334.590464, -327.980473) '
        ></path>
        <path
          d='M211.924514,1381.05271 L179.503098,1397.13285 C176.534461,1398.60521 172.934318,1397.39224 171.461954,1394.42361 C170.888077,1393.26653 170.702889,1391.95516 170.933915,1390.68442 L176.950787,1357.58909 C177.318884,1355.56439 176.623669,1353.49231 175.108897,1352.09935 L149.877749,1328.89719 C147.438582,1326.65417 147.279573,1322.85851 149.522591,1320.41935 C150.467517,1319.39179 151.741168,1318.72588 153.124213,1318.53628 L188.62657,1313.6695 C190.550574,1313.40575 192.227941,1312.2271 193.128139,1310.50634 L209.274015,1279.64302 C210.810059,1276.70683 214.435524,1275.57178 217.371718,1277.10783 C218.455418,1277.67476 219.339985,1278.55932 219.906913,1279.64302 L236.05279,1310.50634 C236.952988,1312.2271 238.630355,1313.40575 240.554358,1313.6695 L276.056716,1318.53628 C279.339721,1318.98633 281.636288,1322.01256 281.186242,1325.29557 C280.99665,1326.67861 280.330737,1327.95227 279.30318,1328.89719 L254.072031,1352.09935 C252.557259,1353.49231 251.862044,1355.56439 252.230142,1357.58909 L258.247014,1390.68442 C258.839744,1393.94469 256.677279,1397.06816 253.417013,1397.66089 C252.146272,1397.89192 250.834904,1397.70673 249.67783,1397.13285 L217.256414,1381.05271 C215.576736,1380.21964 213.604192,1380.21964 211.924514,1381.05271 Z'
          id='Star'
          fill='#F64949'
          transform='translate(214.590464, 1342.980473) rotate(27.000000) translate(-214.590464, -1342.980473) '
        ></path>
        <circle id='Oval' fill='#F64949' cx='1859' cy='1220' r='29'></circle>
        <circle id='Oval' fill='#F64949' cx='2004' cy='490' r='13'></circle>
      </g>
    </svg>
  );
};

export default RedBg;
