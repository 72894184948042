import React from 'react';
import styled from 'styled-components';

import { formatForDisplay } from './utils';

import { StyledFC } from '../../../../types';
import NumberColumn from './NumberColumn';

const TickerView = styled.div`
  height: 70px;
  width: 100px;
  margin: auto;
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;
  font-size: 56px;
  position: relative;
`;

const AnimatingNumber: StyledFC<{
  value: string;
  startAtHeightValue: number;
}> = ({ children, value, startAtHeightValue }) => {
  const numArray = formatForDisplay(value);
  return (
    <TickerView>
      {numArray.map((n, i) => (
        <NumberColumn
          key={Math.random()}
          digit={+n}
          startAtHeightValue={startAtHeightValue}
        />
      ))}
    </TickerView>
  );
};

export default AnimatingNumber;
