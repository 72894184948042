import React, { useEffect, useRef, useState, useContext } from 'react';
import styled from 'styled-components';

import RoundedBox from '../_shared/RoundedBox';
import Divider from '../_shared/Divider';
import { touchNotify } from '../_shared/utils';
import ArrowRightIcon from '../_shared/icons/ArrowRightIcon';
import TaskIndicatorIcon from '../_shared/icons/TaskIndicatorIcon';
import MedalsIcon from '../_shared/icons/MedalsIcon';
import SeasonIcon from '../_shared/icons/SeasonIcon';
import { NavContext } from '../../main';

import { StyledFC } from '../../types';
import { medalsService, seasonServices, taskService } from '../../services';
import Notification from '../_shared/Notification';

const AboutWrapper = styled.span<{ zIndex?: string; position: string }>`
  position: ${(props) => props.position};
  //  z-index: ${(props) => props.zIndex};
`;
const Opacity = styled.span<{ opacity: number }>`
  z-index: ${(props) => props.opacity};
`;
const _Menu: StyledFC<{
  token: any;
  activeShow: any;
  setActive: any;
  setIndexShow: any;
  indexShow: number;
  setSeasonName: any;
  seasonNotify: boolean;
}> = ({
  seasonNotify,
  setSeasonName,
  token,
  className,
  setActive,
  setIndexShow,
  indexShow,
  activeShow,
}) => {
  const seasonRef = useRef(null);
  const tasksRef = useRef(null);
  const medalsRef = useRef(null);
  const [date, setDate] = useState(0);
  const [month, setMonth] = useState('');

  const [seasonInfo, setSeasonInfo] = useState<{
    earnedLevels: number;
    endTimestamp: string;
    header: string;
    description: string;
    notify: boolean;
    totalLevels: number;
  }>();
  const [questsInfo, setQuestsInfo] = useState<{
    completedTasks: number;
    notify: boolean;
    totalTasks: number;
  }>();
  const [medalsInfo, setMedalsInfo] = useState<{
    earnedMedals: number;
    notify: boolean;
    totalMedals: number;
  }>();

  useEffect(() => {
    if (token) {
      seasonServices.getSeasonInfo().then(({ data }) => {
        setSeasonInfo(data);
        setSeasonName(data.header);
      });
      taskService.getTasksInfo().then((response) => {
        setQuestsInfo(response.data);
      });
      medalsService.getMedalsInfo().then((response) => {
        setMedalsInfo(response.data);
      });
    }
  }, [token]);

  const { route, push } = useContext(NavContext);
  function showDisable() {
    setActive(false);
  }
  function setIndex(step: number) {
    setIndexShow(step);
  }
  useEffect(() => {
    if (seasonInfo) {
      const date = new Date(seasonInfo.endTimestamp);
      var months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря',
      ];

      setDate(date.getDate() - 1);
      setMonth(months[date.getMonth()]);
    }
  }, [seasonInfo]);

  return (
    <div className={className}>
      <AboutWrapper position={indexShow > 1 && indexShow < 5 ? 'relative' : ''}>
        <RoundedBox padding={'0 16px 0 16px'}>
          <div
            style={{
              opacity: indexShow !== 2 && activeShow ? 0.1 : 1,
            }}
            ref={seasonRef}
            className={'item'}
            onClick={() => {
              if (!activeShow) {
                touchNotify('SEASON').then(() => {
                  setSeasonInfo((v) => v && { ...v, notify: false });
                });
                push('/seasons');
              }
            }}
          >
            <div className={'row'}>
              <div className={'right-box'}>
                <SeasonIcon className={'icon'} color={'inherit'} size={35} />
                <div className={'text-block'}>
                  <label className={'title'}>{seasonInfo?.header}</label>
                  <label className={'desc'}>
                    Соберите все награды сезона до {date} {month}
                  </label>
                </div>
              </div>
              {seasonInfo?.notify && <Notification />}
              <div className={'arrow'}>
                <ArrowRightIcon color={'#212121'} size={20} />
              </div>
            </div>
          </div>
          <Divider />
          <div
            ref={tasksRef}
            className={'item'}
            onClick={() => {
              if (!activeShow) {
                touchNotify('TASKS_COMPLETED').then(() => {
                  setQuestsInfo((v) => v && { ...v, notify: false });
                });
                push('/tasks');
              }
            }}
          >
            <div
              style={{
                opacity: indexShow !== 3 && activeShow ? 0.1 : 1,
              }}
              className={'row'}
            >
              <div className={'right-box'}>
                <TaskIndicatorIcon
                  className={'icon'}
                  color={'inherit'}
                  size={35}
                />
                <Opacity opacity={indexShow === 3 ? 99999 : 1}>
                  <div className={'text-block'}>
                    <label className={'title'}>Задания</label>
                    <label className={'desc'}>
                      Выполняйте задания, получайте награды
                    </label>
                  </div>
                </Opacity>
              </div>
              {questsInfo?.notify && <Notification />}
              <div className={'arrow'}>
                <ArrowRightIcon color={'#212121'} size={20} />
              </div>
            </div>
          </div>
          <Divider />
          <div
            ref={medalsRef}
            className={'item'}
            onClick={() => {
              if (!activeShow) {
                touchNotify('MEDALS_TOTAL').then(() => {
                  setMedalsInfo((v) => v && { ...v, notify: false });
                });
                push('/medals');
              }
            }}
          >
            <div
              style={{
                opacity: indexShow !== 4 && activeShow ? 0.1 : 1,
              }}
              className={'row'}
            >
              <div className={'right-box'}>
                <MedalsIcon className={'icon'} color={'inherit'} size={35} />
                <div className={'text-block'}>
                  <label className={'title'}>Медали</label>
                  <label className={'desc'}>Соберите коллекцию медалей</label>
                </div>
              </div>
              {medalsInfo?.notify && <Notification />}
              <div className={'arrow'}>
                <ArrowRightIcon color={'#212121'} size={20} />
              </div>
            </div>
          </div>
        </RoundedBox>
      </AboutWrapper>
    </div>
  );
};

const Menu = styled(_Menu)`
  margin-top: 16px;

  .layer {
    position: absolute;
    top: -400px;
    height: 100vh;
    background-color: red;
  }

  .item {
    cursor: pointer;
    padding: 24px 0 24px 0;

    :first-child {
      padding: 36px 0 24px 0;
    }

    :last-child {
      padding: 24px 0 36px 0;
    }

    &:hover .icon {
      transform: rotate(5deg) scale(1.09);
    }

    &:hover .desc {
      opacity: 1;
    }

    &:hover .arrow {
      opacity: 1;
      transform: translateX(4px);
    }
  }

  .icon {
    transition: transform 0.2s ease;
  }

  .arrow {
    color: #212121;
    transition: all 0.2s ease;
    opacity: 0.4;
  }

  .info {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #868686;
    margin: 10px 0 37px 0;
  }

  .desc {
    cursor: inherit;
    font-weight: 400;
    font-size: 14px;
    color: inherit;
    transition: opacity 0.2s ease;
    opacity: 0.4;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .right-box {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .text-block {
    //opacity: ${(props) => props.activeShow && 0.1};
    cursor: inherit;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 14px;

    .title {
      cursor: inherit;
      font-weight: 400;
      font-size: 20px;
      color: #212121;
    }
  }
  // mobile

  .mobileTop {
    margin: 32px 0 0 18px;

    & > label {
      display: block;
    }

    label:first-child {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.02em;
      color: #f4f4f4;
    }
    label:last-child {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: #aeb1b0;
    }
  }

  .menu-options {
    background-color: #151515;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 13px 8px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
  }

  .showcase {
    background-color: #151515;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw);
    height: 100%;
    padding-top: 19px;
    margin-top: 20px;
    margin-bottom: 50px;
    border-top: 0 solid black;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow-y: hidden;
    h6 {
      margin-top: 0;
      margin-bottom: 13px;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: -0.02em;
      color: #f4f4f4;
    }
  }

  .mobile-page-head {
    border: 0 solid black;
    border-radius: 18px;
    width: calc(100vw - 28px);
    margin-left: 14px;
    position: relative;

    p {
      max-width: 300px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.3px;
      color: #aeb1b0;
    }

    .close {
      position: absolute;
      right: 8px;
      top: 8px;
      height: 34px;
      width: 34px;
    }
  }

  .mobile-top {
    margin-top: 32px;
    margin-left: 16px;
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    .title {
      margin-bottom: 4px;
    }
  }
`;

export default Menu;
