import React from 'react';
import { StyledFC } from '../../../types';
import styled from 'styled-components';
import SimpleIconContainer from '../SimpleIconContainer';
import CompletedSVG from '../../../iconsSource/CompletedSVG';

const _CompletedIcon: StyledFC<{
  size: number;
  color: string;
  fontSize: number;
}> = ({ className, size, color, fontSize }) => {
  return (
    <SimpleIconContainer color={color} size={size}>
      <CompletedSVG color={color} fontSize={fontSize} />
    </SimpleIconContainer>
  );
};

const CompletedIcon = styled(_CompletedIcon)``;

export default CompletedIcon;
