import { API } from '../../_api/axios';

export const compileName = (name?: {
  firstName: string;
  lastName: string;
  middleName: string;
}) => {
  if (!name) return '';
  if (Object.values(name).join(' ').length < 21) {
    return Object.values(name).join(' ');
  } else if ([name.firstName, name.lastName].join(' ').length < 19) {
    return (
      [name.firstName, name.lastName].join(' ') + ` ${name.middleName[0]}.`
    );
  } else {
    return `${name.firstName} ${name.lastName[0]}. ${name.middleName[0]}`;
  }
};

export const touchNotify = (
  type:
    | 'MEDALS_EARNED'
    | 'MEDALS_NEW'
    | 'MEDALS_TOTAL'
    | 'SEASON'
    | 'TASKS_COMPLETED'
    | 'TASKS_NEW'
) => {
  return API.post(`/user/touched?type=${type}`);
};

export const dateToString = (str: string, short?: boolean) => {
  let date = new Date(str);
  let days: number | string = date.getDate();
  let months: number | string = date.getMonth();
  let monthsLabels = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ];
  return `${days} ${monthsLabels[months]}${
    short ? '' : ` ${date.getFullYear()}`
  }`;
};
