import React, {
  useEffect,
  useState,
} from 'react';

import styled from 'styled-components';

import {
  animated,
  config,
  useSpring,
  useTransition,
} from '@react-spring/web';

import { StyledFC } from '../../../types';
import { FillableButton } from '../../MedalsNew/Button';
import Seasons from '../../Seasons';

const SuccessText = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 40px;
  max-width: 379px;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  z-index: 999;
`;

const ButtonWrapper = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 8%;
  width: fit-content;
  z-index: 999;
  background-color: #f4f4f4;
  @media (max-height: 720px) {
    bottom: unset;
    top: 620px;
  }
`;

const SeasonsWrapper = styled(animated.div)`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 200px;
  width: fit-content;
  height: 600px;
  overflow: hidden;
`;

const Fader = styled.div`
  position: absolute;
  width: 452px;
  height: 772px;
  left: 0px;
  top: 89px;
  z-index: 99;
  background: linear-gradient(
    180deg,
    rgba(235, 235, 235, 0) 35.13%,
    #ebebeb 90.49%
  );
`;

const GrayBlock = styled.div`
  position: absolute;
  width: 452px;
  height: 240px;
  left: 0;
  right: 0;
  background-color: #ebebeb;
  z-index: 100;
`;

const SeasonsContainer = styled(animated.div)`
  position: absolute;
  left: 0;
  right: 0;
`;

const AnimationXp = styled(animated.div)`
  position: absolute;
  left: 25px;
  right: 0;
  top: 322px;
  width: 50px;
  height: 70px;
  background-color: #ebebeb;
  z-index: 1000;
  overflow: hidden;
`;

const AnimationXpLine = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: -70px;
  width: 2px;
  height: 100px;
  background-color: #90d128;
  z-index: 1001;
`;

const AnimationXpBackLine = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 0px;
  width: 2px;
  height: 100px;
  background-color: #d7d7d7;
  z-index: 1001;
`;
const AnimationXpSign = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 20px;
  width: 36px;
  height: 20px;
  background: #90d128;
  border-radius: 44.7097px;
  z-index: 1001;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
`;

const AnimationXpContainer = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1001;
`;

const gotFirstLvlTitleText = 'Вы достигли первого уровня сезона';
const continueButtonText = 'Далее';

const OnboardingFifthPhase: StyledFC<{
  onPhaseEnd: () => void;
  onNextPhaseStart: () => void;
}> = ({ className, onPhaseEnd, onNextPhaseStart }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const [seasonsStyles, seasonsApi] = useSpring(() => ({
    from: {
      opacity: 0,
      transform: 'translateY(550px)',
    },
    config: config.default,
  }));

  const [xpSignStyles, xpSignApi] = useSpring(() => ({
    from: {
      transform: 'translateY(0px)',
    },
    config: config.slow,
  }));

  useEffect(() => {
    let timer: NodeJS.Timer;
    let timer2: NodeJS.Timer;
    setIsMounted(true);

    timer = setTimeout(() => {
      seasonsApi.start({
        to: { opacity: 1, transform: 'translateY(0px)' },
      });
    }, 700);

    timer2 = setTimeout(() => {
      xpSignApi.start({
        to: { transform: 'translateY(27px)' },
      });
    }, 1800);

    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  }, []);

  const transitionExpText = useTransition(isMounted, {
    from: { opacity: 0, transform: 'translateY(50px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(50px)' },
  });

  const [fadeAwayStyles, fadeAwayApi] = useSpring(() => ({
    from: {
      opacity: 1,
    },
    config: config.stiff,
  }));

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isButtonClicked) {
      onNextPhaseStart();
      fadeAwayApi.start({
        to: { opacity: 0 },
      });
      timer = setTimeout(() => {
        onPhaseEnd();
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [isButtonClicked]);

  return (
    <animated.div style={fadeAwayStyles}>
      {transitionExpText(
        (styles, item) =>
          item && (
            <SuccessText style={styles}>{gotFirstLvlTitleText}</SuccessText>
          )
      )}

      <Fader />
      <SeasonsContainer style={seasonsStyles}>
        <AnimationXp>
          <AnimationXpBackLine />
          <AnimationXpContainer style={xpSignStyles}>
            <AnimationXpLine />
            <AnimationXpSign>10</AnimationXpSign>
          </AnimationXpContainer>
        </AnimationXp>
        <GrayBlock />
        <SeasonsWrapper>
          <Seasons />
        </SeasonsWrapper>
      </SeasonsContainer>

      <ButtonWrapper>
        <FillableButton
          padding='12px 16px'
          onClick={() => setIsButtonClicked(true)}
        >
          {continueButtonText}
        </FillableButton>
      </ButtonWrapper>
    </animated.div>
  );
};

export default OnboardingFifthPhase;
