import {API} from "../_api/axios";


export const ranksServices = {
    getRanks: () => API.get('ranks/'),
    setRank: (id:number) => API.post('profile/rank',id ,{
        headers: {
            'Content-Type': 'application/json',
        }
    }),

};