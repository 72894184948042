import React from 'react';
import { StyledFC } from '../../types';

const _ArrowRightIcon: StyledFC<{ color?: string; fontSize?: number }> = ({
  className,
  color = '#212121',
  fontSize = 16,
}) => {
  return (
    <svg
      width={fontSize}
      height={fontSize}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_5_1596)'>
        <path
          d='M11.8135 2L10.8715 0.778667C10.6839 0.537204 10.4438 0.341611 10.1693 0.206703C9.89489 0.0717943 9.59335 0.0011087 9.28755 0L6.71155 0C6.40575 0.0011087 6.10421 0.0717943 5.82977 0.206703C5.55533 0.341611 5.31519 0.537204 5.12755 0.778667L4.18555 2H11.8135Z'
          fill={color}
        />
        <path
          d='M8.00065 12.0001C9.47341 12.0001 10.6673 10.8062 10.6673 9.33341C10.6673 7.86066 9.47341 6.66675 8.00065 6.66675C6.52789 6.66675 5.33398 7.86066 5.33398 9.33341C5.33398 10.8062 6.52789 12.0001 8.00065 12.0001Z'
          fill={color}
        />
        <path
          d='M12.6667 3.33325H3.33333C2.4496 3.33431 1.60237 3.68584 0.97748 4.31073C0.352588 4.93562 0.00105857 5.78286 0 6.66659L0 12.6666C0.00105857 13.5503 0.352588 14.3975 0.97748 15.0224C1.60237 15.6473 2.4496 15.9989 3.33333 15.9999H12.6667C13.5504 15.9989 14.3976 15.6473 15.0225 15.0224C15.6474 14.3975 15.9989 13.5503 16 12.6666V6.66659C15.9989 5.78286 15.6474 4.93562 15.0225 4.31073C14.3976 3.68584 13.5504 3.33431 12.6667 3.33325ZM8 13.3333C7.20887 13.3333 6.43552 13.0987 5.77772 12.6591C5.11992 12.2196 4.60723 11.5949 4.30448 10.864C4.00173 10.1331 3.92252 9.32881 4.07686 8.55289C4.2312 7.77697 4.61216 7.06424 5.17157 6.50483C5.73098 5.94542 6.44372 5.56445 7.21964 5.41011C7.99556 5.25577 8.79983 5.33498 9.53073 5.63773C10.2616 5.94048 10.8864 6.45317 11.3259 7.11097C11.7654 7.76877 12 8.54213 12 9.33325C11.9989 10.3938 11.5772 11.4106 10.8273 12.1605C10.0773 12.9104 9.06054 13.3322 8 13.3333Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_5_1596'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default _ArrowRightIcon;
