import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { animated, config, useSpring, useTransition } from '@react-spring/web';
import { debounce } from 'lodash';

import { profileService } from '../../../services';
import Typography from '../../_shared/Typography/Typography';
import TextInput from '../../_shared/TextInput';

import { StyledFC } from '../../../types';
import { FillableButton } from '../../MedalsNew/Button';
import { API } from '../../../_api/axios';

const ButtonWrapper = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 8%;
  width: fit-content;
  z-index: 9999;
  @media (max-height: 720px) {
    bottom: unset;
    top: 620px;
  }
`;

const Wrapper = styled(animated.div)``;

const SelectAvatarsSex = styled(animated.p)`
  position: absolute;
  z-index: -1;
  margin-left: auto;
  margin-right: auto;
  top: 230px;
  left: 0;
  right: 0;
  width: 339px;
  min-height: 100px;
  text-align: center;
  font-weight: 600;
  line-height: 25px;
  font-size: 20px;
  background-color: #ebebeb;
  padding: 0 16px;
`;

const SelectAvatarsName = styled(animated.p)`
  position: absolute;
  z-index: -1;
  margin-left: auto;
  margin-right: auto;
  top: 60px;
  left: 0;
  right: 0;
  width: 339px;
  min-height: 100px;
  text-align: center;
  font-weight: 600;
  line-height: 25px;
  font-size: 20px;
  background-color: #ebebeb;
  padding: 0 16px;
`;

const CharactersBlock = styled(animated.div)`
  position: absolute;
  display: flex;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  top: 310px;
  left: 0;
  right: 0;
  width: 90%;
  height: 270px;
`;

const ImgFemale = styled(animated.img)`
  position: absolute;
  pointer-events: none;
  left: -25px;
  top: -81px;
  width: 250px;
  height: 700px;
  object-fit: contain;
`;

const ImgMale = styled(animated.img)`
  position: absolute;
  pointer-events: none;
  right: -3px;
  top: 0;
  width: 200px;
  height: 700px;
  object-fit: contain;
`;

const Icon = styled(animated.div)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -25px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  background-color: #000;
  border: 4px solid #ebebeb;
  transition: all 0.35s;
`;

const FemaleCharBlock = styled(animated.div)`
  height: 260px;
  width: 190px;
  border-radius: 32px;
  overflow: hidden;
  position: relative;
  background-color: #d9d9d9;
  opacity: 0.6;
  transition: all 0.35s;
`;

const FemaleAndIcon = styled(animated.div)`
  transition: all 0.35s;
  &:hover {
    transform: scale(1.03) translateY(-10px) !important;
  }

  &:hover ${FemaleCharBlock} {
    opacity: 1;
  }

  &:hover ${Icon} {
    transform: rotate(-30deg);
  }
`;

const MaleCharBlock = styled(animated.div)`
  height: 260px;
  width: 190px;
  border-radius: 32px;
  overflow: hidden;
  position: relative;
  background-color: #d9d9d9;
  transition: all 0.35s;
  opacity: 0.6;
`;

const MaleAndIcon = styled(animated.div)`
  transition: all 0.35s;

  &:hover {
    transform: scale(1.03) translateY(-10px) !important;
  }

  &:hover ${MaleCharBlock} {
    opacity: 1;
  }

  &:hover ${Icon} {
    transform: rotate(-30deg);
  }
`;

const CheckIconWrapper = styled(animated.div)`
  position: absolute;
  z-index: 100;
  top: 15px;
  left: 15px;
`;

const SexBlockWrapper = styled(animated.div)`
  cursor: pointer;
`;

const Divider = styled.div`
  transition: all 0.3s;
  height: 1px;
  background-color: #393736;
  opacity: 100%;
  margin: 10px 0px 40px 0px;
`;

const InputBlockWrapper = styled(animated.div)`
  position: absolute;
  z-index: 1000;
  top: 520px;
  width: 100%;
  height: 400px;
  box-sizing: border-box;
  padding: 20px 40px;
  background-color: #ebebeb;
`;

const TextInputWrapper = styled(animated.div)`
  &:hover ${Divider} {
    height: 3px;
  }
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const selectAvatarsSexText = 'Выберите пол аватара';
const selectAvatarsNameText = 'Введите имя будущего аватара';
const continueButtonText = 'Продолжить';
const allSiReadyText = 'Готово!';
const regex =
  /(?:\/)([^#]+)(?=#|$)([^а-я])(([уyu]|[нзnz3][аa]|(хитро|не)?[вvwb][зz3]?[ыьъi]|[сsc][ьъ']|(и|[рpr][аa4])[зсzs]ъ?|([оo0][тбtb6]|[пp][оo0][дd9])[ьъ']?|(.\B)+?[оаеиeo])?-?([еёe][бb6](?!о[рй])|и[пб][ае][тц]).*?|([нn][иеаaie]|([дпdp]|[вv][еe3][рpr][тt])[оo0]|[рpr][аa][зсzc3]|[з3z]?[аa]|с(ме)?|[оo0]([тt]|дно)?|апч)?-?[хxh][уuy]([яйиеёюuie]|ли(?!ган)).*?|([вvw][зы3z]|(три|два|четыре)жды|(н|[сc][уuy][кk])[аa])?-?[бb6][лl]([яy](?!(х|ш[кн]|мб)[ауеыио]).*?|[еэe][дтdt][ь']?)|([рp][аa][сзc3z]|[знzn][аa]|[соsc]|[вv][ыi]?|[пp]([еe][рpr][еe]|[рrp][оиioеe]|[оo0][дd])|и[зс]ъ?|[аоao][тt])?[пpn][иеёieu][зz3][дd9].*?|([зz3][аa])?[пp][иеieu][дd][аоеaoe]?[рrp](ну.*?|[оаoa][мm]|([аa][сcs])?([иiu]([лl][иiu])?[нщктлtlsn]ь?)?|([оo](ч[еиei])?|[аa][сcs])?[кk]([оo]й)?|[юu][гg])[ауеыauyei]?|[мm][аa][нnh][дd]([ауеыayueiи]([лl]([иi][сзc3щ])?[ауеыauyei])?|[оo][йi]|[аоao][вvwb][оo](ш|sh)[ь']?([e]?[кk][ауеayue])?|юк(ов|[ауи])?)|[мm][уuy][дd6]([яyаиоaiuo0].*?|[еe]?[нhn]([ьюия'uiya]|ей))|мля([тд]ь)?|лять|([нз]а|по)х|м[ао]л[ао]фь([яию]|[её]й))(?=($|[^а-я]))/;

const modelFemale = {
  account: {
    _uid: { _str: '00000000-0000-0000-0000-000000000000' },
    _username: '',
    _displayName: '',
    _avatarUrl: '',
    _wallet: { Hard: 0, Soft: 500000, Tickets: 0 },
    _isCharacterCreated: true,
    _isRegistered: false,
    _userId: '00000000-0000-0000-0000-000000000000',
  },
  gender: 'female',
  bodyShapeData: { Body: { Fat: 0, Height: 0, Breast: 0, Butt: 0 } },
  equipedItems: [
    'G18_Hair_Color3',
    'G18_Shorts_BicycleMicro_02_Color2',
    'G18_Singlet_001_Color1',
    'B18_Lips_Color19',
    'B18_Shirt_Sleeveless_001_Color1',
    'B18_Pants_ClassicTrousers_Color1',
    'G18_Pixie_FringeSide',
    'G18_Face_Jaw_00',
    'G18_Face_Brows_01',
    'G18_Face_Eyes_00',
    'G18_Face_Lips_00',
    'G18_Face_Nose_00',
    'G18_Eyes_Color1',
    'G18_Lips_Color1',
    'G18_Brows_Color1',
  ],
};
const modelMale = {
  account: {
    _uid: { _str: '00000000-0000-0000-0000-000000000000' },
    _username: '',
    _displayName: '',
    _avatarUrl: '',
    _wallet: { Hard: 0, Soft: 500000, Tickets: 0 },
    _isCharacterCreated: true,
    _isRegistered: false,
    _userId: '00000000-0000-0000-0000-000000000000',
  },
  gender: 'male',
  bodyShapeData: { Body: { Fat: 50, Height: 0, Breast: 0, Butt: 0 } },
  equipedItems: [
    'G18_Hair_Color3',
    'G18_Shorts_BicycleMicro_02_Color2',
    'G18_Singlet_001_Color1',
    'B18_Lips_Color19',
    'B18_Shirt_Sleeveless_001_Color1',
    'B18_Pants_ClassicTrousers_Color1',
    'B18_Buzz',
    'B18_Jaw_00',
    'B18_Brows_00',
    'B18_Eyes_00',
    'B18_Lips_00',
    'B18_Face_Nose_01',
    'B18_Panties_Boxer_Color1',
    'B18_Eyes_Color1',
    'B18_Hair_Color1',
    'B18_Brows_Color1',
  ],
};

const OnboardingFirstNewPhase: StyledFC<{
  onPhaseEnd: () => void;
  onNextPhaseStart: () => void;
}> = ({ className, onPhaseEnd, onNextPhaseStart }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isFemaleSelected, setIsFemaleSelected] = useState(false);
  const [isMaleSelected, setIsMaleSelected] = useState(false);

  const [isFemaleHidden, setIsFemaleHidden] = useState(true);
  const [isMaleHidden, setIsMaleHidden] = useState(true);

  const [isBlockShown, setIsBlockShown] = useState(true);

  const [isTextChanged, setIsTextChanged] = useState(false);

  const [inputValue, setInputValue] = useState('');

  const [isAvatarImgTransition, setAvatarImgTransition] = useState(false);

  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const debouncedChangeHandler = useMemo(
    () =>
      debounce((newValue: string) => {
        profileService
          .setNickname(newValue)
          .then(() => {
            //@ts-ignore
            window.ym(88659232, 'reachGoal', 'changeNickname');
          })
          .catch((e: any) => {
            console.log(e);
          });
      }, 700),
    []
  );

  const validateString = (str: string) => {
    return str.split('').every((letter) => letter.charCodeAt(0) !== 9);
  };

  const nickNameChangeHandler = (newValue: string) => {
    if (
      (newValue.length !==
        newValue.split('').filter((el) => el === ' ').length ||
        newValue === '') &&
      !regex.test(newValue) &&
      validateString(newValue) &&
      newValue.length <= 30
    ) {
      setInputValue(newValue);
      if (newValue !== '') {
        debouncedChangeHandler(inputValue);
      }
    }
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const transitionSelectAvatarsSexText = useTransition(isMounted, {
    from: { opacity: 0, transform: 'translateY(500px)', delay: 0 },
    enter: { opacity: 1, transform: 'translateY(0px)', delay: 0 },
    leave: { opacity: 0, transform: 'translateY(0px)', delay: 0 },
    config: { tension: 170, friction: 26 },
  });

  const transitionSelectAvatarsNameText = useTransition(isTextChanged, {
    from: { opacity: 0, transform: 'translateY(200px)', delay: 0 },
    enter: { opacity: 1, transform: 'translateY(0px)', delay: 0 },
    leave: { opacity: 0, transform: 'translateY(0px)', delay: 0 },
    config: { tension: 170, friction: 26 },
  });

  const transitionRightSex = useTransition(isMounted, {
    from: { opacity: 0, transform: 'translateX(500px)', delay: 0 },
    enter: { opacity: 1, transform: 'translateX(0px)', delay: 0 },
    leave: { opacity: 0, transform: 'translateX(0px)', delay: 0 },
    config: config.default,
  });

  const transitionLeftSex = useTransition(isMounted, {
    from: { opacity: 0, transform: 'translateX(-500px)', delay: 0 },
    enter: { opacity: 1, transform: 'translateX(0px)', delay: 0 },
    leave: { opacity: 0, transform: 'translateX(0px)', delay: 0 },
    config: config.default,
  });

  const transitionLastStage = useTransition(isAvatarImgTransition, {
    from: { opacity: 0, transform: 'translateX(0px)', delay: 0 },
    enter: { opacity: 1, transform: 'translateX(0px)', delay: 0 },
    leave: { opacity: 0, transform: 'translateX(0px)', delay: 0 },
    config: config.default,
  });

  const transitionFemaleSelectedIcon = useTransition(isFemaleSelected, {
    from: {
      opacity: 0,
      transform: 'translateX(50px) translateY(50px) rotate(210deg)',
      delay: 0,
    },
    enter: {
      opacity: 1,
      transform: 'translateX(0px) translateY(0px) rotate(0deg)',
      delay: 0,
    },
    leave: {
      opacity: 0,
      transform: 'translateX(0px) translateY(0px) rotate(0deg)',
      delay: 0,
    },
    config: config.default,
  });

  const transitionMaleSelectedIcon = useTransition(isMaleSelected, {
    from: {
      opacity: 0,
      transform: 'translateX(50px) translateY(50px) rotate(210deg)',
      delay: 0,
    },
    enter: {
      opacity: 1,
      transform: 'translateX(0px) translateY(0px) rotate(0deg)',
      delay: 0,
    },
    leave: {
      opacity: 0,
      transform: 'translateX(0px) translateY(0px) rotate(0deg)',
      delay: 0,
    },
    config: config.default,
  });

  const [fadeAwayStyles, fadeAwayApi] = useSpring(() => ({
    from: {
      opacity: 1,
    },
    config: config.stiff,
  }));

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isButtonClicked) {
      onNextPhaseStart();
      fadeAwayApi.start({
        to: { opacity: 0 },
      });
      timer = setTimeout(() => {
        onPhaseEnd();
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [isButtonClicked]);

  const [fadeAwayTextStyles, fadeAwayTextApi] = useSpring(() => ({
    from: {
      opacity: 1,
      transform: 'translateY(0px)',
    },
    config: config.stiff,
  }));

  const [fadeInInputBlockStyles, fadeInInputBlockApi] = useSpring(() => ({
    from: {
      opacity: 0,
    },
    config: config.default,
  }));

  // Female animations VVV ///

  const [fadeAwayMaleStyles, fadeAwayMaleApi] = useSpring(() => ({
    from: {
      opacity: 1,
    },
    config: config.stiff,
  }));

  const [moveAwayIconsLeftStyles, moveAwayIconsLeftApi] = useSpring(() => ({
    from: {
      opacity: 1,
      transform: 'translateX(0px)',
    },
    config: config.stiff,
  }));

  const [moveFemaleToTopStyles, moveFemaleToTopApi] = useSpring(() => ({
    from: {
      opacity: 0,
      transform: 'translateY(0px) translateX(0px) scale(1)',
    },
    config: config.default,
  }));

  const [fadeAwayFemaleBlockStyles, fadeAwayFemaleBlockApi] = useSpring(() => ({
    from: {
      transform: 'translateX(0px)',
      opacity: 1,
    },
    config: config.default,
  }));

  const [scaleUpFemaleImgStyles, scaleUpFemaleImgApi] = useSpring(() => ({
    from: {
      transform: 'scale(1) translateY(0px) translateX(0px)',
    },
    config: config.slow,
  }));

  useEffect(() => {
    let timer: NodeJS.Timeout;
    let timer1: NodeJS.Timeout;
    let timer2: NodeJS.Timeout;
    let timer3: NodeJS.Timeout;

    if (isFemaleSelected) {
      timer = setTimeout(() => {
        fadeAwayMaleApi.start({
          to: { opacity: 0 },
        });
        moveAwayIconsLeftApi.start({
          to: { opacity: 0, transform: 'translateX(-300px)' },
        });
        moveFemaleToTopApi.start({
          to: {
            opacity: 1,
            transform: 'translateY(-300px) translateX(110px) scale(0.6)',
          },
        });
        fadeAwayFemaleBlockApi.start({
          to: { transform: 'translateX(100px)', opacity: 0 },
        });
        fadeAwayTextApi.start({
          to: { transform: 'translateY(-100px)', opacity: 0 },
        });
        setIsTextChanged(true);
      }, 1000);
      timer1 = setTimeout(() => {
        setIsFemaleHidden(false);
      }, 1055);
      timer2 = setTimeout(() => {
        fadeInInputBlockApi.start({
          to: {
            opacity: 1,
          },
        });
      }, 1400);
      timer3 = setTimeout(() => {
        setIsBlockShown(false);
      }, 1500);
      if (isAvatarImgTransition) {
        scaleUpFemaleImgApi.start({
          to: { transform: 'scale(2) translateY(20px) translateX(-110px)' },
        });
      }
    }

    return () => {
      clearTimeout(timer);
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, [isFemaleSelected, isAvatarImgTransition]);

  // Female animations ^^^ ///

  // Male animations VVV ///

  const [fadeAwayFemaleStyles, fadeAwayFemaleApi] = useSpring(() => ({
    from: {
      opacity: 1,
    },
    config: config.stiff,
  }));

  const [moveAwayIconsRightStyles, moveAwayIconsRightApi] = useSpring(() => ({
    from: {
      opacity: 1,
      transform: 'translateX(0px)',
    },
    config: config.stiff,
  }));

  const [moveMaleToTopStyles, moveMaleToTopApi] = useSpring(() => ({
    from: {
      opacity: 0,
      transform: 'translateY(0px) translateX(0px) scale(1)',
    },
    config: config.default,
  }));

  const [fadeAwayMaleBlockStyles, fadeAwayMaleBlockApi] = useSpring(() => ({
    from: {
      opacity: 1,
    },
    config: config.default,
  }));

  const [scaleUpImgMaleStyles, scaleUpMaleImgApi] = useSpring(() => ({
    from: {
      transform: 'scale(1) translateY(0px) translateX(0px)',
    },
    config: config.slow,
  }));

  useEffect(() => {
    let timer: NodeJS.Timeout;
    let timer1: NodeJS.Timeout;
    let timer2: NodeJS.Timeout;
    let timer3: NodeJS.Timeout;

    if (isMaleSelected) {
      timer = setTimeout(() => {
        fadeAwayFemaleApi.start({
          to: { opacity: 0 },
        });
        moveAwayIconsRightApi.start({
          to: { opacity: 0, transform: 'translateX(300px)' },
        });
        moveMaleToTopApi.start({
          to: {
            opacity: 1,
            transform: 'translateY(-330px) translateX(-110px) scale(0.57)',
          },
        });
        fadeAwayMaleBlockApi.start({
          to: { opacity: 0 },
        });
        fadeAwayTextApi.start({
          to: { transform: 'translateY(-100px)', opacity: 0 },
        });
        setIsTextChanged(true);
      }, 1000);
      timer1 = setTimeout(() => {
        setIsMaleHidden(false);
      }, 1055);
      timer2 = setTimeout(() => {
        fadeInInputBlockApi.start({
          to: {
            opacity: 1,
          },
        });
      }, 1400);
      timer3 = setTimeout(() => {
        setIsBlockShown(false);
      }, 1500);
      if (isAvatarImgTransition) {
        scaleUpMaleImgApi.start({
          to: { transform: 'scale(2) translateY(10px) translateX(110px)' },
        });
      }
    }

    return () => {
      clearTimeout(timer);
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, [isMaleSelected, isAvatarImgTransition]);

  // Male animations ^^^ ///

  function createAvatar(avatarMdl: any) {
    const avatarModel = JSON.stringify(avatarMdl);
    const formData = new FormData();
    formData.append('gender', avatarMdl.gender === 'female' ? '1' : '0');
    formData.append(
      'avatar',
      new Blob([JSON.stringify(avatarModel)], { type: 'text/plain' })
    );
    API.post(`profile/avatar`, formData);
  }

  return (
    <Wrapper style={fadeAwayStyles}>
      <animated.div style={fadeAwayTextStyles}>
        {transitionSelectAvatarsSexText(
          (styles, item) =>
            item && (
              <SelectAvatarsSex style={styles}>
                {selectAvatarsSexText}
              </SelectAvatarsSex>
            )
        )}
      </animated.div>
      {transitionSelectAvatarsNameText(
        (styles, item) =>
          item && (
            <SelectAvatarsName style={styles}>
              {selectAvatarsNameText}
            </SelectAvatarsName>
          )
      )}
      {transitionLastStage(
        (styles, item) =>
          item && (
            <SelectAvatarsName style={styles}>
              {allSiReadyText}
            </SelectAvatarsName>
          )
      )}

      <CharactersBlock>
        {isBlockShown && (
          <>
            {transitionLeftSex(
              (styles, item) =>
                item && (
                  <SexBlockWrapper
                    style={styles}
                    onClick={
                      isMaleSelected
                        ? () => {}
                        : () => {
                            setIsFemaleSelected(true);
                            createAvatar(modelFemale);
                          }
                    }
                  >
                    <FemaleAndIcon style={fadeAwayFemaleStyles}>
                      {transitionFemaleSelectedIcon(
                        (styles, item) =>
                          item && (
                            <CheckIconWrapper style={styles}>
                              <animated.div style={moveAwayIconsLeftStyles}>
                                <svg
                                  width='44'
                                  height='44'
                                  viewBox='0 0 40 40'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <rect
                                    width='40'
                                    height='40'
                                    rx='20'
                                    fill='#90D128'
                                  />
                                  <g clipPath='url(#clip0_4189_12725)'>
                                    <path
                                      d='M17.1661 25.7751C16.712 25.7753 16.2764 25.5948 15.9555 25.2734L12.2954 21.6146C11.9015 21.2207 11.9015 20.582 12.2954 20.1881C12.6893 19.7943 13.3279 19.7943 13.7219 20.1881L17.1661 23.6323L26.2781 14.5203C26.6721 14.1265 27.3107 14.1265 27.7046 14.5203C28.0985 14.9143 28.0985 15.5529 27.7046 15.9469L18.3767 25.2734C18.0559 25.5948 17.6203 25.7753 17.1661 25.7751Z'
                                      fill='white'
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id='clip0_4189_12725'>
                                      <rect
                                        width='16'
                                        height='16'
                                        fill='white'
                                        transform='translate(12 12)'
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </animated.div>
                            </CheckIconWrapper>
                          )
                      )}
                      <animated.div style={fadeAwayFemaleBlockStyles}>
                        <FemaleCharBlock>
                          {isFemaleHidden && (
                            <ImgFemale src='onboarding_images/female-avatar.png' />
                          )}
                        </FemaleCharBlock>
                      </animated.div>

                      <animated.div style={moveAwayIconsLeftStyles}>
                        <Icon>
                          <svg
                            width='25'
                            height='25'
                            viewBox='0 0 22 22'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M17 8C17 10.973 14.8377 13.441 12 13.917V16L14 16C14.5523 16 15 16.4477 15 17C15 17.5523 14.5523 18 14 18L12 18V20C12 20.5523 11.5523 21 11 21C10.4477 21 10 20.5523 10 20V18L8 18C7.44772 18 7 17.5523 7 17C7 16.4477 7.44772 16 8 16L10 16V13.917C7.16229 13.441 5 10.973 5 8C5 4.68629 7.68629 2 11 2C14.3137 2 17 4.68629 17 8ZM11 12C13.2091 12 15 10.2091 15 8C15 5.79086 13.2091 4 11 4C8.79086 4 7 5.79086 7 8C7 10.2091 8.79086 12 11 12Z'
                              fill='white'
                            />
                          </svg>
                        </Icon>
                      </animated.div>
                    </FemaleAndIcon>
                  </SexBlockWrapper>
                )
            )}
          </>
        )}
        {!isAvatarImgTransition ? (
          <ImgFemale
            style={moveFemaleToTopStyles}
            src='onboarding_images/female-avatar.png'
          />
        ) : (
          <animated.div style={scaleUpFemaleImgStyles}>
            <ImgFemale
              style={moveFemaleToTopStyles}
              src='onboarding_images/female-avatar.png'
            />
          </animated.div>
        )}

        {isBlockShown && (
          <>
            {transitionRightSex(
              (styles, item) =>
                item && (
                  <SexBlockWrapper
                    style={styles}
                    onClick={
                      isFemaleSelected
                        ? () => {}
                        : () => {
                            setIsMaleSelected(true);
                            createAvatar(modelMale);
                          }
                    }
                  >
                    <MaleAndIcon style={fadeAwayMaleStyles}>
                      {transitionMaleSelectedIcon(
                        (styles, item) =>
                          item && (
                            <CheckIconWrapper style={styles}>
                              <animated.div style={moveAwayIconsRightStyles}>
                                <svg
                                  width='44'
                                  height='44'
                                  viewBox='0 0 40 40'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <rect
                                    width='40'
                                    height='40'
                                    rx='20'
                                    fill='#90D128'
                                  />
                                  <g clipPath='url(#clip0_4189_12725)'>
                                    <path
                                      d='M17.1661 25.7751C16.712 25.7753 16.2764 25.5948 15.9555 25.2734L12.2954 21.6146C11.9015 21.2207 11.9015 20.582 12.2954 20.1881C12.6893 19.7943 13.3279 19.7943 13.7219 20.1881L17.1661 23.6323L26.2781 14.5203C26.6721 14.1265 27.3107 14.1265 27.7046 14.5203C28.0985 14.9143 28.0985 15.5529 27.7046 15.9469L18.3767 25.2734C18.0559 25.5948 17.6203 25.7753 17.1661 25.7751Z'
                                      fill='white'
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id='clip0_4189_12725'>
                                      <rect
                                        width='16'
                                        height='16'
                                        fill='white'
                                        transform='translate(12 12)'
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </animated.div>
                            </CheckIconWrapper>
                          )
                      )}
                      <animated.div style={fadeAwayMaleBlockStyles}>
                        <MaleCharBlock>
                          {isMaleHidden && (
                            <ImgMale src='onboarding_images/male-avatar.png' />
                          )}
                        </MaleCharBlock>
                      </animated.div>

                      <animated.div style={moveAwayIconsRightStyles}>
                        <Icon>
                          <svg
                            width='25'
                            height='25'
                            viewBox='0 0 22 22'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M12.4769 7.62363C10.1337 5.9537 6.85958 6.16985 4.75736 8.27208C2.41421 10.6152 2.41421 14.4142 4.75736 16.7574C7.10051 19.1005 10.8995 19.1005 13.2426 16.7574C15.3449 14.6551 15.561 11.381 13.8911 9.03785L16.4853 6.44365L16.4853 8.27208C16.4853 8.82436 16.933 9.27208 17.4853 9.27208C18.0376 9.27208 18.4853 8.82436 18.4853 8.27208L18.4853 4.02944C18.4853 3.47715 18.0376 3.02944 17.4853 3.02944L13.2426 3.02944C12.6904 3.02944 12.2426 3.47715 12.2426 4.02944C12.2426 4.58172 12.6904 5.02944 13.2426 5.02944H15.0711L12.4769 7.62363ZM6.17157 9.68629C7.73367 8.12419 10.2663 8.12419 11.8284 9.68629C13.3905 11.2484 13.3905 13.781 11.8284 15.3431C10.2663 16.9052 7.73367 16.9052 6.17157 15.3431C4.60948 13.781 4.60948 11.2484 6.17157 9.68629Z'
                              fill='white'
                            />
                          </svg>
                        </Icon>
                      </animated.div>
                    </MaleAndIcon>
                  </SexBlockWrapper>
                )
            )}
          </>
        )}
        {!isAvatarImgTransition ? (
          <ImgMale
            style={moveMaleToTopStyles}
            src='onboarding_images/male-avatar.png'
          />
        ) : (
          <animated.div style={scaleUpImgMaleStyles}>
            <ImgMale
              style={moveMaleToTopStyles}
              src='onboarding_images/male-avatar.png'
            />
          </animated.div>
        )}
      </CharactersBlock>

      {(!isMaleHidden || !isFemaleHidden) && (
        <InputBlockWrapper style={fadeInInputBlockStyles}>
          <FlexBox>
            <Typography mb={8} lh={18} ls={'-0.02em'} fw={400} fz={14}>
              Отображаемое имя
            </Typography>
            <Typography lh={18} ls={'-0.02em'} fw={400} fz={14} color='red'>
              Макс. 23 символа
            </Typography>
          </FlexBox>
          <TextInputWrapper>
            <TextInput
              maxLength={23}
              placeholder='Имя Фамилия'
              value={inputValue}
              onChange={(e) => {
                nickNameChangeHandler(e.target.value);
                setAvatarImgTransition(true);
              }}
            />

            <Divider />
          </TextInputWrapper>
        </InputBlockWrapper>
      )}
      {transitionLastStage(
        (styles, item) =>
          item && (
            <ButtonWrapper style={styles}>
              <FillableButton
                padding='12px 16px'
                onClick={() => {
                  setIsButtonClicked(true);
                }}
              >
                {continueButtonText}
              </FillableButton>
            </ButtonWrapper>
          )
      )}
    </Wrapper>
  );
};

export default OnboardingFirstNewPhase;
