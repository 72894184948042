import React from 'react';
import styled from 'styled-components';

import { StyledFC } from '../../../../types';

const Img = styled.img`
  width: 80px;
  height: 80px;
`;

const Spinner: StyledFC = ({ className }) => {
  return <Img src='onboarding_images/gamepad.gif' />;
};

export default Spinner;
