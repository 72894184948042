import React from 'react';
import styled from 'styled-components';

interface StyledButtonProps {
  margin?: string;
  outlined?: boolean;
  padding?: string;
  fs?: string;
  lh?: string;
  ls?: string;
}

interface ChildrenProp {
  children?: any;
  onClick?: () => void;
}

interface ButtonProps extends ChildrenProp, StyledButtonProps {}

const DefaultButton = styled.button<StyledButtonProps>`
  outline: none;
  border: none;
  cursor: pointer;
`;

const StyledOutlinedButton = styled(DefaultButton)`
  border: ${({ outlined }) =>
    outlined ? '1px solid #d5d5d5' : '1px solid #3e4149'};
  padding: ${({ padding }) => padding || '8px'};
  margin: ${({ margin }) => margin || '0'};
  font-size: ${({ fs }) => fs || '16px'};
  font-weight: 400;
  border-radius: 6px;
  color: ${({ outlined }) => (outlined ? '#3e4149' : 'white')};
  background: ${({ outlined }) => (outlined ? 'transparent' : '#3e4149')};
  text-decoration: none;
  transition: border 0.3s ease;
  line-height: ${({ lh }) => {
    if (String(lh).includes('%')) return lh;
    return `${lh ?? 15.5}px;`;
  }};
  letter-spacing: ${({ ls }) => `${ls ?? '0px'};`};
  &:hover {
    border: 1px solid #3e4149;
  }
`;

const StyledFillableButton = styled(DefaultButton)`
  padding: ${({ padding }) => padding || '8px'};
  border: 2px solid #3e4149;
  border-radius: 11px;
  width: fit-content;
  font-weight: 600;
  font-size: 14px;
  color: #3e4149;
  background-color: transparent;
  transition: all 0.3s ease;

  &:hover {
    background-color: #3e4149;
    color: #ffffff;
  }
`;

const StyledRoundButton = styled(DefaultButton)`
  position: absolute;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  background-color: white;

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.15s linear;
  }

  &:hover {
    & svg {
      transform: translate(-75%, -50%);
    }
  }
`;

const StyledCarouselButton = styled(DefaultButton)`
  padding: ${({ padding }) => padding || '8px'};
  border: none;
  border-radius: 11px;
  width: fit-content;
  font-weight: 600;
  font-size: 14px;
  color: #3e4149;
  background-color: #ebebeb;
  transition: all 0.3s ease;

  &:hover {
    background-color: #626262;
    color: #ffffff;
  }
`;

export const Button: React.FC<ButtonProps> = (props) => {
  return (
    <StyledOutlinedButton {...props}>{props.children}</StyledOutlinedButton>
  );
};

export const RoundButton: React.FC<ButtonProps> = (props) => {
  return <StyledRoundButton {...props}>{props.children}</StyledRoundButton>;
};

export const FillableButton: React.FC<ButtonProps> = (props) => {
  return (
    <StyledFillableButton {...props}>{props.children}</StyledFillableButton>
  );
};

export const CarouselButton: React.FC<ButtonProps> = (props) => {
  return (
    <StyledCarouselButton {...props}>{props.children}</StyledCarouselButton>
  );
};
