import React, { useEffect, useState } from 'react';
import { useSpring, useTransition, animated, easings } from '@react-spring/web';
import styled from 'styled-components';

import { FillableButton } from '../../MedalsNew/Button';
import { GamePad, GamePadAnimated } from './GamePad';
import {
  StarParticle,
  PlusParticle,
  CircleParticle,
  PadParticle,
  HeartParticle,
  BagParticle,
  LabelParticle,
  GiftParticle,
} from './GamePad/Particles';
import { Playground } from './Playground';

import { StyledFC } from '../../../types';
import { profileService, settingsService } from '../../../services';
import { API } from '../../../_api/axios';

const GamePadWrapper = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 245px;
  left: 0;
  right: 0;
  width: fit-content;
`;

const Particle = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 307px;
  left: -175px;
  right: 0;
  z-index: -2;
  width: fit-content;
`;

const StartedTitle = styled(animated.h1)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 435px;
  left: 0;
  right: 0;
  width: fit-content;
  font-weight: 400;
  font-size: 22px;
  color: #212121;
`;

const PlaygroundTitle = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 283px;
  left: -21px;
  right: 0;
  width: fit-content;
`;

const GreetingsText = styled(animated.p)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 369px;
  left: 0;
  right: 0;
  width: 339px;
  min-height: 100px;
  text-align: center;
  font-weight: 400;
  line-height: 20px;
  font-size: 16px;
  background-color: #ebebeb;
  padding: 0 16px;
`;

const StartAvatarText = styled(animated.p)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 420px;
  left: 0;
  right: 0;
  width: 200px;
  min-height: 100px;
  text-align: center;
  font-weight: 400;
  line-height: 20px;
  font-size: 16px;
  background-color: #ebebeb;
  padding: 0 16px;
`;

const GreetingsTextBold = styled(animated.p)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 369px;
  left: 0;
  right: 0;
  width: 339px;
  min-height: 100px;
  text-align: center;
  font-weight: 600;
  line-height: 25px;
  font-size: 20px;
  background-color: #ebebeb;
  padding: 0 16px;
`;

const ButtonWrapper = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 520px;
  left: 0;
  right: 0;
  width: fit-content;
`;

const TopPartWrapper = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
`;

const BottomPartWrapper = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
`;

const Img = styled(animated.img)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 280px;
  width: 250px;
  height: 360px;
  object-fit: contain;
`;

const CharacterSelectButtons = styled(animated.div)`
  position: absolute;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 480px;
  width: 200px;
`;

const ChooseCharacterText = styled(animated.p)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 410px;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #212121;
`;

const gameHasStartedText = 'Игра началась!';
const greetingsTextFirst =
  'Привет! Ты вошёл в Playground — платформу, где каждый получает награды за простые действия. Сейчас быстро покажем, как всё работает.';
const greetingsTextSecond =
  'Чтобы получать награды на Playground, нужно выполнять задания.';
const startAvatarText = 'Давайте начнем с создания твоего аватара.';
const completeTaskButtonText = 'Начать задание';
const selectGirlButtonText = 'Девушка';
const selectBoyButtonText = 'Парень';
const selectAvatarSexText = 'Выберите аватара';
const continueButtonText = 'Далее';

const OnboardingFirstPhase: StyledFC<{
  onPhaseEnd: () => void;
  onNextPhaseStart: () => void;
}> = ({ className, onPhaseEnd, onNextPhaseStart }) => {
  const [gamepadMountingFinished, setGamepadMountingFinished] = useState(false);
  const [_1, setGamepadShowingFinished] = useState(false);
  const [startedTitleFinished, setStartedTitleFinished] = useState(false);
  const [playgtoundTitleFinished, setPlaygtoundTitleFinished] = useState(false);
  const [partsStarted, setPartsStarted] = useState(false);
  const [firstStageFadeAway, setFirstStageFadeAway] = useState(false);
  const [selectedCharacter, setSelectedCharacter] = useState(false);
  const [isParticlesStarted, setIsParticlesStarted] = useState(false);
  const modelFemale = {
    account: {
      _uid: { _str: '00000000-0000-0000-0000-000000000000' },
      _username: '',
      _displayName: '',
      _avatarUrl: '',
      _wallet: { Hard: 0, Soft: 500000, Tickets: 0 },
      _isCharacterCreated: true,
      _isRegistered: false,
      _userId: '00000000-0000-0000-0000-000000000000',
    },
    gender: 'female',
    bodyShapeData: { Body: { Fat: 0, Height: 0, Breast: 0, Butt: 0 } },
    equipedItems: [
      'G18_Hair_Color3',
      'G18_Shorts_BicycleMicro_02_Color2',
      'G18_Singlet_001_Color1',
      'B18_Lips_Color19',
      'B18_Shirt_Sleeveless_001_Color1',
      'B18_Pants_ClassicTrousers_Color1',
      'G18_Pixie_FringeSide',
      'G18_Face_Jaw_00',
      'G18_Face_Brows_01',
      'G18_Face_Eyes_00',
      'G18_Face_Lips_00',
      'G18_Face_Nose_00',
      'G18_Eyes_Color1',
      'G18_Lips_Color1',
      'G18_Brows_Color1',
    ],
  };
  const modelMale = {
    account: {
      _uid: { _str: '00000000-0000-0000-0000-000000000000' },
      _username: '',
      _displayName: '',
      _avatarUrl: '',
      _wallet: { Hard: 0, Soft: 500000, Tickets: 0 },
      _isCharacterCreated: true,
      _isRegistered: false,
      _userId: '00000000-0000-0000-0000-000000000000',
    },
    gender: 'male',
    bodyShapeData: { Body: { Fat: 50, Height: 0, Breast: 0, Butt: 0 } },
    equipedItems: [
      'G18_Hair_Color3',
      'G18_Shorts_BicycleMicro_02_Color2',
      'G18_Singlet_001_Color1',
      'B18_Lips_Color19',
      'B18_Shirt_Sleeveless_001_Color1',
      'B18_Pants_ClassicTrousers_Color1',
      'B18_Buzz',
      'B18_Jaw_00',
      'B18_Brows_00',
      'B18_Eyes_00',
      'B18_Lips_00',
      'B18_Face_Nose_01',
      'B18_Panties_Boxer_Color1',
      'B18_Eyes_Color1',
      'B18_Hair_Color1',
      'B18_Brows_Color1',
    ],
  };

  const [gamePadStyles, gamePadApi] = useSpring(() => ({
    from: {
      opacity: 0,
      transform: 'translateY(500px) rotate(-50deg)',
    },
    config: { tension: 120, friction: 14 },
    onRest: () => setGamepadShowingFinished(true),
  }));

  useEffect(() => {
    let timer = setTimeout(() => {
      gamePadApi.start({
        to: {
          opacity: 1,
          transform: 'translateY(0px) translateX(0px) rotate(0deg)',
        },
      });
      setGamepadMountingFinished(true);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const transitionStartedTitle = useTransition(gamepadMountingFinished, {
    from: { opacity: 0, transform: 'translateY(-40px)', delay: 0 },
    enter: { opacity: 1, transform: 'translateY(0px)', delay: 1100 },
    leave: { opacity: 0, transform: 'translateY(-40px)', delay: 0 },
    config: { tension: 120, friction: 14 },
    onRest: () => setStartedTitleFinished(true),
  });

  const transitionGamePad = useTransition(startedTitleFinished, {
    from: { transform: 'translateY(0px) translateX(0px) scale(1)' },
    enter: { transform: 'translateY(-49px) translateX(-74px) scale(0.25)' },
    leave: { transform: 'translateY(0px) translateX(0px) scale(1)' },
    delay: 200,
    onStart: () => setGamepadMountingFinished(false),
    onRest: () => setPlaygtoundTitleFinished(true),
  });

  const transitionPlaygroundTitle = useTransition(playgtoundTitleFinished, {
    from: { opacity: 0, transform: 'translateX(70px)', delay: 1300 },
    enter: { opacity: 1, transform: 'translateX(0px)', delay: 2400 },
    leave: { opacity: 0, transform: 'translateX(-70px)', delay: 0 },
    config: { tension: 170, friction: 26 },
  });

  const transitionGreetingsText = useTransition(playgtoundTitleFinished, {
    from: { opacity: 0, transform: 'translateY(300px)', delay: 1300 },
    enter: { opacity: 1, transform: 'translateY(0px)', delay: 2400 },
    leave: { opacity: 0, transform: 'translateY(50px)', delay: 0 },
    config: { tension: 170, friction: 26 },
  });

  const transitionButton = useTransition(playgtoundTitleFinished, {
    from: { opacity: 0, transform: 'translateY(400px)', delay: 1300 },
    enter: { opacity: 1, transform: 'translateY(0px)', delay: 2400 },
    leave: { opacity: 0, transform: 'translateY(-400px)', delay: 0 },
    config: { tension: 170, friction: 26 },
  });

  const [topPartStyles, topPartApi] = useSpring(() => ({
    from: {
      transform: 'translateY(0px)',
    },
    config: { tension: 170, friction: 26 },
  }));

  const [bottomPartStyles, bottomPartApi] = useSpring(() => ({
    from: {
      transform: 'translateY(0px)',
    },
    config: { tension: 170, friction: 26 },
  }));

  useEffect(() => {
    partsStarted &&
      topPartApi.start({
        to: {
          transform: 'translateY(-230px)',
        },
      });

    partsStarted &&
      bottomPartApi.start({
        to: {
          transform: 'translateY(250px)',
        },
      });
  }, [partsStarted]);

  const transitionButtonChange = useTransition(!partsStarted, {
    from: { opacity: 1, delay: 0 },
    enter: { opacity: 1, delay: 0 },
    leave: { opacity: 0, delay: 0 },
    config: { tension: 170, friction: 26 },
  });

  const transitionButtonNewChange = useTransition(partsStarted, {
    from: { opacity: 0, delay: 0 },
    enter: { opacity: 1, delay: 0 },
    leave: { opacity: 0, delay: 0 },
    config: { tension: 170, friction: 26 },
  });

  const transitionGreetingsNewText = useTransition(partsStarted, {
    from: { opacity: 0, delay: 0 },
    enter: { opacity: 1, delay: 0 },
    leave: { opacity: 0, delay: 0 },
    config: { tension: 170, friction: 26 },
  });

  const transitionStartAvatarText = useTransition(partsStarted, {
    from: { opacity: 0, delay: 0 },
    enter: { opacity: 1, delay: 0 },
    leave: { opacity: 0, delay: 0 },
    config: { tension: 170, friction: 26 },
  });

  const transitionImg = useTransition(partsStarted, {
    from: { opacity: 0, transform: 'scale(0.75)', delay: 0 },
    enter: { opacity: 1, transform: 'scale(1)', delay: 0 },
    leave: { opacity: 0, transform: 'scale(0.75)', delay: 0 },
    config: { tension: 170, friction: 26 },
  });

  const [firstStageFadeAwayStyles, firstStageFadeAwayApi] = useSpring(() => ({
    from: {
      opacity: 1,
    },
    config: { tension: 170, friction: 26 },
  }));
  localStorage.removeItem('avatarLoaded');
  useEffect(() => {
    firstStageFadeAway &&
      firstStageFadeAwayApi.start({
        to: {
          opacity: 0,
        },
      });
  }, [firstStageFadeAway]);

  const [imgFadeAwayStyles, imgFadeAwayApi] = useSpring(() => ({
    from: {
      display: 'block',
    },
  }));

  const [imgSecondStageStyles, imgSecondStageApi] = useSpring(() => ({
    from: {
      opacity: 1,
      transform: 'scale(1) translateX(0px)',
    },
    config: { tension: 170, friction: 26 },
  }));

  useEffect(() => {
    firstStageFadeAway &&
      imgFadeAwayApi.start({
        to: {
          display: 'none',
        },
      });

    firstStageFadeAway &&
      imgSecondStageApi.start({
        to: {
          opacity: 1,
          transform: 'scale(1) translateX(100px)',
        },
      });
  }, [firstStageFadeAway]);

  const transitionCharacter = useTransition(firstStageFadeAway, {
    from: { opacity: 0, transform: 'translateY(400px) scale(0.7)', delay: 0 },
    enter: { opacity: 1, transform: 'translateY(0px) scale(1)', delay: 0 },
    leave: { opacity: 0, transform: 'translateY(400px) scale(0.7)', delay: 0 },
    config: { tension: 170, friction: 26 },
  });

  const [imgSecondStageFadeAwayStyles, imgSecondStageFadeAwayApi] = useSpring(
    () => ({
      from: {
        opacity: 1,
        transform: 'translateX(0px)',
      },
      config: { tension: 170, friction: 26 },
    })
  );

  const [
    charracterBtnsSecondStageFadeAwayStyles,
    charracterBtnsSecondStageFadeAwayApi,
  ] = useSpring(() => ({
    from: {
      opacity: 1,
      transform: 'translateY(0px)',
    },
    config: { tension: 170, friction: 26 },
  }));

  useEffect(() => {
    selectedCharacter &&
      imgSecondStageFadeAwayApi.start({
        to: {
          opacity: 0,
          transform: 'translateX(200px)',
        },
      });
    selectedCharacter &&
      charracterBtnsSecondStageFadeAwayApi.start({
        to: {
          opacity: 0,
          transform: 'translateY(100px)',
        },
      });

    let timer: NodeJS.Timeout;
    if (selectedCharacter) {
      onNextPhaseStart();
      timer = setTimeout(() => {
        onPhaseEnd();
      }, 500);
    }
    return () => clearTimeout(timer);
  }, [selectedCharacter]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (startedTitleFinished) {
      timer = setTimeout(() => {
        setIsParticlesStarted(true);
      }, 500);
    }
    return () => clearTimeout(timer);
  }, [startedTitleFinished]);

  const transitionParticles = useTransition(
    isParticlesStarted && !partsStarted,
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: { tension: 170, friction: 26 },
    }
  );

  const [starStyles] = useSpring(() => ({
    from: {
      transform: 'translateX(0px) translateY(0px) rotate(0deg)',
    },

    to: {
      transform: 'translateX(-200px) translateY(29px) rotate(79deg)',
    },

    loop: true,
    config: { duration: 1800, easing: easings.easeInCubic },
  }));

  const [plusStyles] = useSpring(() => ({
    from: {
      transform: 'translateX(0px) translateY(0px) rotate(0deg)',
    },

    to: {
      transform: 'translateX(-200px) translateY(-27px) rotate(109deg)',
    },

    loop: true,
    config: { duration: 2500, easing: easings.easeInExpo },
  }));

  const [circleStyles] = useSpring(() => ({
    from: {
      transform: 'translateX(0px) translateY(0px) rotate(0deg)',
    },

    to: {
      transform: 'translateX(-200px) translateY(32px) rotate(299deg)',
    },

    loop: true,
    config: { duration: 1800, easing: easings.easeInCirc },
  }));

  const [padStyles] = useSpring(() => ({
    from: {
      transform: 'translateX(0px) translateY(0px) rotate(0deg)',
    },

    to: {
      transform: 'translateX(-200px) translateY(24px) rotate(179deg)',
    },

    loop: true,
    config: { duration: 2100, easing: easings.easeInQuint },
  }));

  const [heartStyles] = useSpring(() => ({
    from: {
      transform: 'translateX(0px) translateY(0px) rotate(0deg)',
    },

    to: {
      transform: 'translateX(-200px) translateY(30px) rotate(-409deg)',
    },

    loop: true,
    config: { duration: 1900, easing: easings.easeInSine },
  }));

  const [bagStyles] = useSpring(() => ({
    from: {
      transform: 'translateX(0px) translateY(0px) rotate(0deg)',
    },

    to: {
      transform: 'translateX(-200px) translateY(-26px) rotate(-209deg)',
    },

    loop: true,
    config: { duration: 2300, easing: easings.easeInExpo },
  }));

  const [labelStyles] = useSpring(() => ({
    from: {
      transform: 'translateX(0px) translateY(0px) rotate(0deg)',
    },

    to: {
      transform: 'translateX(-200px) translateY(36px) rotate(-59deg)',
    },

    loop: true,
    config: { duration: 2200, easing: easings.easeInQuart },
  }));

  const [giftStyles] = useSpring(() => ({
    from: {
      transform: 'translateX(0px) translateY(0px) rotate(0deg)',
    },

    to: {
      transform: 'translateX(-200px) translateY(-33px) rotate(241deg)',
    },

    loop: true,
    config: { duration: 2000, easing: easings.linear },
  }));
  function createAvatar(avatarMdl: any) {
    const avatarModel = JSON.stringify(avatarMdl);
    const formData = new FormData();
    formData.append('gender', avatarMdl.gender === 'female' ? '1' : '0');
    formData.append(
      'avatar',
      new Blob([JSON.stringify(avatarModel)], { type: 'text/plain' })
    );
    API.post(`profile/avatar`, formData).finally(() => {
      setSelectedCharacter(true);
    });
  }

  return (
    <>
      {transitionParticles(
        (styles, item) =>
          item && (
            <animated.div style={styles}>
              <Particle style={starStyles}>
                <StarParticle />
              </Particle>
              <Particle style={plusStyles}>
                <PlusParticle />
              </Particle>
              <Particle style={circleStyles}>
                <CircleParticle />
              </Particle>
              <Particle style={padStyles}>
                <PadParticle />
              </Particle>
              <Particle style={heartStyles}>
                <HeartParticle />
              </Particle>
              <Particle style={bagStyles}>
                <BagParticle />
              </Particle>
              <Particle style={labelStyles}>
                <LabelParticle />
              </Particle>
              <Particle style={giftStyles}>
                <GiftParticle />
              </Particle>
            </animated.div>
          )
      )}

      <animated.div style={firstStageFadeAwayStyles}>
        <TopPartWrapper style={topPartStyles}>
          {transitionGamePad(
            (styles, item) =>
              item && (
                <GamePadWrapper style={styles}>
                  <GamePadAnimated />
                </GamePadWrapper>
              )
          )}

          {!startedTitleFinished && (
            <GamePadWrapper style={gamePadStyles}>
              <GamePad />
            </GamePadWrapper>
          )}

          {transitionStartedTitle(
            (styles, item) =>
              item && (
                <StartedTitle style={styles}>{gameHasStartedText}</StartedTitle>
              )
          )}

          {transitionPlaygroundTitle(
            (styles, item) =>
              item && (
                <PlaygroundTitle style={styles}>
                  <Playground />
                </PlaygroundTitle>
              )
          )}

          {transitionGreetingsText(
            (styles, item) =>
              item && (
                <GreetingsText style={styles}>
                  {greetingsTextFirst}
                </GreetingsText>
              )
          )}
          {transitionGreetingsNewText(
            (styles, item) =>
              item && (
                <GreetingsTextBold style={styles}>
                  {greetingsTextSecond}
                </GreetingsTextBold>
              )
          )}
        </TopPartWrapper>
      </animated.div>
      <animated.div style={imgSecondStageFadeAwayStyles}>
        {firstStageFadeAway && (
          <animated.div style={imgSecondStageStyles}>
            <Img src='onboarding_images/yoga.png' />
          </animated.div>
        )}
      </animated.div>
      <animated.div style={imgFadeAwayStyles}>
        {transitionImg(
          (styles, item) =>
            item && <Img style={styles} src='onboarding_images/yoga.png' />
        )}
      </animated.div>
      {firstStageFadeAway && (
        <animated.div style={charracterBtnsSecondStageFadeAwayStyles}>
          {/* {transitionCharacter(
            (styles, item) =>
              item && (
                <ChooseCharacterText style={styles}>
                  {selectAvatarSexText}
                </ChooseCharacterText>
              )
          )} */}

          {/* {transitionCharacter(
            (styles, item) =>
              item && (
                <CharacterSelectButtons style={styles}>
                  <FillableButton
                    onClick={() => {
                      createAvatar(modelFemale);
                    }}
                    padding='12px 16px'
                  >
                    {selectGirlButtonText}
                  </FillableButton>
                  <FillableButton
                    onClick={() => {
                      createAvatar(modelMale);
                    }}
                    padding='12px 16px'
                  >
                    {selectBoyButtonText}
                  </FillableButton>
                </CharacterSelectButtons>
              )
          )} */}
        </animated.div>
      )}
      <animated.div style={firstStageFadeAwayStyles}>
        <BottomPartWrapper style={bottomPartStyles}>
          {transitionStartAvatarText(
            (styles, item) =>
              item && (
                <StartAvatarText style={styles}>
                  {startAvatarText}
                </StartAvatarText>
              )
          )}
          {transitionButton(
            (styles, item) =>
              item && (
                <ButtonWrapper
                  style={styles}
                  onClick={(e) => {
                    setPartsStarted(true);
                  }}
                >
                  {transitionButtonChange(
                    (styles, item) =>
                      item && (
                        <animated.div style={styles}>
                          <FillableButton padding='12px 16px'>
                            {continueButtonText}
                          </FillableButton>
                        </animated.div>
                      )
                  )}
                </ButtonWrapper>
              )
          )}
          {transitionButton(
            (styles, item) =>
              item && (
                <ButtonWrapper
                  style={styles}
                  onClick={() => {
                    setFirstStageFadeAway(true);
                  }}
                >
                  {transitionButtonNewChange(
                    (styles, item) =>
                      item && (
                        <animated.div style={styles}>
                          <FillableButton
                            padding='12px 16px'
                            onClick={() => setSelectedCharacter(true)}
                          >
                            {completeTaskButtonText}
                          </FillableButton>
                        </animated.div>
                      )
                  )}
                </ButtonWrapper>
              )
          )}
        </BottomPartWrapper>
      </animated.div>
    </>
  );
};

export default OnboardingFirstPhase;
