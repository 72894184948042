import React from 'react';

import { StyledFC } from '../../../../types';

const Bg: StyledFC = ({ className }) => {
  return (
    <svg
      width='150px'
      height='160px'
      viewBox='0 0 2383 1504'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Untitled</title>
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M1798.4956,1247.35015 L1780.71591,1256.16839 C1777.74727,1257.64076 1774.14713,1256.42779 1772.67477,1253.45915 C1772.10089,1252.30207 1771.9157,1250.99071 1772.14673,1249.71997 L1775.36728,1232.00558 C1775.73537,1229.98089 1775.04016,1227.9088 1773.52539,1226.51584 L1760.13965,1214.20653 C1757.70048,1211.96351 1757.54147,1208.16785 1759.78449,1205.72869 C1760.72942,1204.70113 1762.00307,1204.03522 1763.38611,1203.84562 L1782.51852,1201.22289 C1784.44252,1200.95914 1786.11989,1199.78049 1787.02009,1198.05973 L1795.8451,1181.19046 C1797.38114,1178.25427 1801.00661,1177.11922 1803.9428,1178.65527 C1805.0265,1179.2222 1805.91107,1180.10676 1806.47799,1181.19046 L1815.30301,1198.05973 C1816.2032,1199.78049 1817.88057,1200.95914 1819.80457,1201.22289 L1838.93698,1203.84562 C1842.21998,1204.29567 1844.51655,1207.3219 1844.06651,1210.60491 C1843.87691,1211.98795 1843.211,1213.26161 1842.18344,1214.20653 L1828.7977,1226.51584 C1827.28293,1227.9088 1826.58772,1229.98089 1826.95581,1232.00558 L1830.17636,1249.71997 C1830.76909,1252.98023 1828.60663,1256.1037 1825.34636,1256.69643 C1824.07562,1256.92746 1822.76425,1256.74227 1821.60718,1256.16839 L1803.8275,1247.35015 C1802.14782,1246.51708 1800.17527,1246.51708 1798.4956,1247.35015 Z'
          id='Star'
          fill='#248932'
          transform='translate(1801.161546, 1221.027915) rotate(23.000000) translate(-1801.161546, -1221.027915) '
        ></path>
        <path
          d='M2168.4956,478.350153 L2150.71591,487.168393 C2147.74727,488.640756 2144.14713,487.427786 2142.67477,484.459148 C2142.10089,483.302075 2141.9157,481.990706 2142.14673,480.719966 L2145.36728,463.005578 C2145.73537,460.980886 2145.04016,458.908804 2143.52539,457.515844 L2130.13965,445.206531 C2127.70048,442.963513 2127.54147,439.167852 2129.78449,436.728686 C2130.72942,435.701129 2132.00307,435.035216 2133.38611,434.845623 L2152.51852,432.222886 C2154.44252,431.959137 2156.11989,430.780489 2157.02009,429.059734 L2165.8451,412.190464 C2167.38114,409.25427 2171.00661,408.119224 2173.9428,409.655269 C2175.0265,410.222197 2175.91107,411.106764 2176.47799,412.190464 L2185.30301,429.059734 C2186.2032,430.780489 2187.88057,431.959137 2189.80457,432.222886 L2208.93698,434.845623 C2212.21998,435.295669 2214.51655,438.321905 2214.06651,441.60491 C2213.87691,442.987954 2213.211,444.261606 2212.18344,445.206531 L2198.7977,457.515844 C2197.28293,458.908804 2196.58772,460.980886 2196.95581,463.005578 L2200.17636,480.719966 C2200.76909,483.980232 2198.60663,487.103702 2195.34636,487.696432 C2194.07562,487.927458 2192.76425,487.74227 2191.60718,487.168393 L2173.8275,478.350153 C2172.14782,477.517079 2170.17527,477.517079 2168.4956,478.350153 Z'
          id='Star'
          fill='#248932'
          transform='translate(2171.161546, 452.027915) rotate(-18.000000) translate(-2171.161546, -452.027915) '
        ></path>
        <circle
          id='Oval'
          fill='#248932'
          cx='1818.5'
          cy='740.5'
          r='11.5'
        ></circle>
        <circle
          id='Oval'
          fill='#248932'
          cx='197.5'
          cy='586.5'
          r='40.5'
        ></circle>
      </g>
    </svg>
  );
};

export default Bg;
