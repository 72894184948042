import React from 'react';
import { StyledFC } from '../../types';

const _HelpIcon: StyledFC = ({ className }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 14.4402 0.728382 16.7104 1.97959 18.6048C2.17351 18.8984 2.21915 19.2689 2.08173 19.5929L0.918075 22.3359C0.57371 23.1476 1.37216 23.9754 2.19579 23.6605L5.35017 22.4544C5.63797 22.3444 5.95916 22.3755 6.22919 22.5239C7.94148 23.4648 9.90824 24 12 24Z'
        fill='#8E8E96'
      />
      <path
        d='M12.9921 13.7964C12.8662 14.3291 12.4408 14.7755 11.8935 14.7755H11.7188C11.1624 14.7755 10.7018 14.3195 10.7697 13.7672C10.7898 13.604 10.8147 13.4509 10.8445 13.3078C10.9354 12.9001 11.0839 12.5332 11.2899 12.207C11.496 11.8746 11.7748 11.5358 12.1263 11.1909C12.4051 10.9211 12.6536 10.6671 12.8718 10.4288C13.096 10.1841 13.2718 9.93011 13.399 9.66667C13.5263 9.39695 13.5899 9.09274 13.5899 8.75403C13.5899 8.37142 13.5293 8.05152 13.4081 7.79435C13.293 7.53719 13.1202 7.34274 12.8899 7.21102C12.6657 7.07303 12.3839 7.00403 12.0445 7.00403C11.7657 7.00403 11.5021 7.06362 11.2536 7.1828C11.0051 7.30197 10.8051 7.48701 10.6536 7.7379C10.648 7.74696 10.6424 7.75611 10.637 7.76537C10.3642 8.23178 9.97867 8.69758 9.43832 8.69758H9.0214C8.46045 8.69758 7.99189 8.23001 8.12359 7.68475C8.21748 7.29601 8.36384 6.94992 8.56266 6.64651C8.92024 6.09453 9.40205 5.68369 10.0081 5.41398C10.6142 5.13799 11.293 5 12.0445 5C12.8748 5 13.5839 5.14427 14.1718 5.4328C14.7657 5.71505 15.2172 6.12903 15.5263 6.67473C15.8414 7.22043 15.999 7.87903 15.999 8.65054C15.999 9.20251 15.896 9.69803 15.6899 10.1371C15.4839 10.5699 15.2111 10.9745 14.8718 11.3508C14.5324 11.7272 14.1657 12.1066 13.7718 12.4892C13.4263 12.8091 13.193 13.151 13.0718 13.5148C13.0416 13.6052 13.0151 13.6991 12.9921 13.7964ZM10.4808 17.7016C10.4808 17.3253 10.6021 17.0116 10.8445 16.7608C11.093 16.5099 11.4263 16.3844 11.8445 16.3844C12.2627 16.3844 12.593 16.5099 12.8354 16.7608C13.0839 17.0116 13.2081 17.3253 13.2081 17.7016C13.2081 18.0654 13.0839 18.3728 12.8354 18.6237C12.593 18.8746 12.2627 19 11.8445 19C11.4263 19 11.093 18.8746 10.8445 18.6237C10.6021 18.3728 10.4808 18.0654 10.4808 17.7016Z'
        fill='white'
      />
    </svg>
  );
};

export default _HelpIcon;
