import React from 'react';
import styled from 'styled-components';

interface StyledFlexProps {
  direction?: string;
  align?: string;
  justify?: string;
  justifyContent?: string;
  margin?: string;
}

interface ChildrenProp {
  children?: any;
}

interface FlexProps extends ChildrenProp, StyledFlexProps {}

const StyledFlex = styled.div<StyledFlexProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${({ align }) => align || 'stretch'};
  justify-items: ${({ justify }) => justify || 'stretch'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  margin: ${({ margin }) => margin || '0'};
`;

const Flex: React.FC<FlexProps> = (props) => {
  return <StyledFlex {...props}>{props.children}</StyledFlex>;
};

export default Flex;
