import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { animated, config, useSpring, useTransition } from '@react-spring/web';

import { StyledFC } from '../../../types';
import CompletedIcon from '../../_shared/icons/CompletedIcon';
import RoundedBox from '../../_shared/RoundedBox';
import { FillableButton } from '../../MedalsNew/Button';
import Circles from './Circles';

const SuccessText = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 40px;
  max-width: 379px;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
`;

const TaskCompleted = styled(animated.div)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 50%;
  width: 300px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CompletedIconBlock = styled.div`
  margin-left: 32px;
`;

const CompletedText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: -0.02em;
  margin: 0;
`;

const CompletedTextSecondary = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #bdbdbd;
  margin: 6px 0 0 0;
`;

const ButtonWrapper = styled(animated.div)`
  position: absolute;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 8%;
  width: fit-content;
`;

const IconMock = styled.div`
  width: 40px;
  height: 40px;
`;

const IconContainer = styled(animated.div)`
  position: relative;
`;

const CirclesContainer = styled(animated.div)`
  position: absolute;
  left: -15px;
  top: -15px;
`;

const firstTaskCompletedText = 'Задание выполнено! Пора собирать награды';
const avatarCreationCompletedText = 'Создать аватара';
const avatarCreationCompletedText2 = 'Задание выполнено!';
const continueButtonText = 'Далее';

const OnboardingThirdPhase: StyledFC<{
  onPhaseEnd: () => void;
  onNextPhaseStart: () => void;
}> = ({ className, onPhaseEnd, onNextPhaseStart }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isTaskCompletedIconShown, setIsTaskCompletedIconShown] =
    useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timer;

    timer = setTimeout(() => {
      setIsMounted(true);
    }, 600);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const transitionSuccessText = useTransition(isMounted, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const transitionTaskCompleted = useTransition(isMounted, {
    from: { opacity: 0, transform: 'scale(0.75) translateY(-50%)' },
    enter: { opacity: 1, transform: 'scale(1) translateY(-50%)' },
    leave: { opacity: 0, transform: 'scale(0.75) translateY(-50%)' },
    config: config.gentle,
    onRest: () => setIsTaskCompletedIconShown(true),
  });

  const transitionBottomButton = useTransition(isMounted, {
    from: { opacity: 0, transform: 'translateY(-150px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(-150px)' },
    config: config.gentle,
  });

  const transitionTaskCompletedIcon = useTransition(isTaskCompletedIconShown, {
    from: { opacity: 0, transform: 'scale(0)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0)' },
    config: config.stiff,
  });

  const transitionCircles = useTransition(isTaskCompletedIconShown, {
    from: { opacity: 0, transform: 'scale(0) rotate(-20deg)' },
    enter: { opacity: 1, transform: 'scale(1) rotate(-20deg)' },
    leave: { opacity: 0, transform: 'scale(0) rotate(-20deg)' },
    config: config.slow,
  });

  const [fadeAwayStyles, fadeAwayApi] = useSpring(() => ({
    from: {
      opacity: 1,
    },
    config: config.stiff,
  }));

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isButtonClicked) {
      onNextPhaseStart();
      fadeAwayApi.start({
        to: { opacity: 0 },
      });
      timer = setTimeout(() => {
        onPhaseEnd();
      }, 1500);
    }
    return () => clearTimeout(timer);
  }, [isButtonClicked]);

  return (
    <animated.div style={fadeAwayStyles}>
      {transitionSuccessText(
        (styles, item) =>
          item && (
            <SuccessText style={styles}>{firstTaskCompletedText}</SuccessText>
          )
      )}
      {transitionTaskCompleted(
        (styles, item) =>
          item && (
            <TaskCompleted style={styles}>
              <RoundedBox padding='32px 24px'>
                <FlexWrapper>
                  {transitionTaskCompletedIcon(
                    (styles, item) =>
                      item && (
                        <IconContainer style={styles}>
                          {transitionCircles(
                            (styles, item) =>
                              item && (
                                <CirclesContainer style={styles}>
                                  <Circles />
                                </CirclesContainer>
                              )
                          )}

                          <CompletedIcon
                            color='inherit'
                            size={40}
                            fontSize={40}
                          />
                        </IconContainer>
                      )
                  )}
                  {!isTaskCompletedIconShown && <IconMock />}
                  <CompletedIconBlock>
                    <CompletedText>{avatarCreationCompletedText}</CompletedText>
                    <CompletedTextSecondary>
                      {avatarCreationCompletedText2}
                    </CompletedTextSecondary>
                  </CompletedIconBlock>
                </FlexWrapper>
              </RoundedBox>
            </TaskCompleted>
          )
      )}
      {transitionBottomButton(
        (styles, item) =>
          item && (
            <ButtonWrapper style={styles}>
              <FillableButton
                padding='12px 16px'
                onClick={() => setIsButtonClicked(true)}
              >
                {continueButtonText}
              </FillableButton>
            </ButtonWrapper>
          )
      )}
    </animated.div>
  );
};

export default OnboardingThirdPhase;
