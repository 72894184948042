import React from 'react';
import { StyledFC } from '../../../types';
import styled from 'styled-components';
import SimpleIconContainer from '../SimpleIconContainer';
import AcceptIconSVG from '../../../iconsSource/AcceptIconSVG';

const _AcceptIcon: StyledFC<{ size: number; color: string }> = ({
  className,
  size,
  color,
}) => {
  return (
    <SimpleIconContainer color={color} size={size}>
      <AcceptIconSVG color={color} />
    </SimpleIconContainer>
  );
};

const AcceptIcon = styled(_AcceptIcon)``;

export default AcceptIcon;
