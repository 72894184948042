import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTransition, animated } from '@react-spring/web';
import moment from 'moment';
import 'moment/locale/ru';

import { Medal } from '../MedalsNew/Grid';
import StarsAndCircles from './StarsAndCircles';

import { StyledFC } from '../../types';

const Badge = styled(animated.div)<{ data: Medal }>`
  margin: -49px auto;
  width: 155px;
  height: 330px;
  background: ${({ data }) =>
    data.earned
      ? `linear-gradient(180deg, #FFF386 12.71%, #F3C13F 59.4%, #F3AB3F 86.79%)`
      : 'transparent'};
  border-bottom-right-radius: 28px;
  border-bottom-left-radius: 28px;
`;

const MedalImg = styled(animated.img)<{ data: Medal }>`
  position: absolute;
  top: 95px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
  filter: ${({ data }) =>
    data.earned
      ? `drop-shadow(0px 33px 33px rgba(0, 0, 0, 0.38))
    drop-shadow(0px 8px 18px rgba(0, 0, 0, 0.43))
    drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.44))`
      : 'grayscale(1)'};
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`;

const Text = styled.p`
  font-size: 16.8px;
  margin: 0;
  padding-top: 24px;
  margin-bottom: 10px;
`;

const LevelBlock = styled.div`
  height: 8px;
  position: relative;
  background-color: #d5d5d5;
  border: 0 solid black;
  border-radius: 4px;
  width: 155px;
`;

const LevelProgress = styled.div<{ data: Medal }>`
  position: absolute;
  background: #90d128;
  height: 8px;
  width: ${({ data }) => (data.progress / data.total) * 100}%;
  border: 0 solid black;
  border-radius: 4px;
`;

const NameText = styled(animated.p)<{ data: Medal }>`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-top: ${({ data }) =>
    !data.earned && data.progress > 0 ? `55px` : '80px'};
  margin-bottom: 32px;
  width: 320px;
`;

const DescriptionText = styled(animated.p)`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin: 0;
  width: 320px;
  .afterReceive {
    margin-top: 15px;
  }
`;

const AchievedDataText = styled(animated.p)`
  margin: 24px 0 0;
  font-size: 16px;
  font-weight: 400;
  color: #bdbdbd;
`;

const MedalNew: StyledFC<{ data: Medal }> = ({ className, data }) => {
  const [date, setDate] = useState<string>('');
  const [show, setShow] = useState(false);
  const progressText = `Выполнено ${data.progress} из ${data.total}`;

  const animationConfig = { tension: 210, friction: 20 };

  const transitionBadge = useTransition(show, {
    from: { y: -310 },
    enter: { y: 0 },
    leave: { x: -310 },
    delay: 1000,
    config: animationConfig,
  });

  const transitionImg = useTransition(show, {
    from: { opacity: 0, transform: 'scale(0)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0)' },
    delay: 1000,
    config: animationConfig,
  });

  const transitionText = useTransition(show, {
    from: { opacity: 0, transform: 'translateY(-150px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-150px)' },
    delay: 1000,
    config: animationConfig,
  });

  const transitionDescriptionText = useTransition(show, {
    from: { opacity: 0, transform: 'translateY(-200px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-200px)' },
    delay: 1000,
    config: animationConfig,
  });

  const transitionAchievedDataText = useTransition(show, {
    from: { opacity: 0, transform: 'translateY(-250px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-250px)' },
    delay: 1000,
    config: animationConfig,
  });

  useEffect(() => {
    setShow(true);

    const dataString = moment(data.date).locale('ru').format('LL').slice(0, -3);
    setDate(`Получена ${dataString}`);
  }, []);
  //@ts-ignore
  window.ym(88659232, 'reachGoal', 'receivedMedals');
  return (
    <>
      {data.earned && <StarsAndCircles show={show} />}
      {transitionBadge(
        (styles, item) => item && <Badge style={styles} data={data} />
      )}
      {data.earned ? (
        transitionImg(
          (styles, item) =>
            item && <MedalImg style={styles} src={data.img} data={data} />
        )
      ) : (
        <MedalImg src={data.img} data={data} />
      )}
      <ContentBlock>
        {!data.earned && data.progress > 0 && (
          <>
            <Text>{progressText}</Text>
            <LevelBlock>
              <LevelProgress data={data} />
            </LevelBlock>
          </>
        )}
        {data.earned ? (
          transitionText(
            (styles, item) =>
              item && (
                <NameText data={data} style={styles}>
                  {data.name}
                </NameText>
              )
          )
        ) : (
          <NameText data={data}>{data.name}</NameText>
        )}
        {data.earned ? (
          transitionDescriptionText(
            (styles, item) =>
              item && (
                <DescriptionText style={styles}>
                  <div>Вы получаете новую медаль за задание:</div>
                  <div>{data.description}</div>
                  <div className={'afterReceive'}>
                    {data.descriptionAfterReceive}
                  </div>
                </DescriptionText>
              )
          )
        ) : (
          <DescriptionText>{data.descriptionToReceive}</DescriptionText>
        )}
        {data.earned &&
          transitionAchievedDataText(
            (styles, item) =>
              item && <AchievedDataText style={styles}>{date}</AchievedDataText>
          )}
      </ContentBlock>
    </>
  );
};

export default MedalNew;
