import React from 'react';
import { StyledFC } from '../../types';

const _EditAvatarIcon: StyledFC<{ color?: string; fontSize?: number }> = ({
  className,
  color = 'white',
  fontSize = 16,
}) => {
  return (
    <svg
      width={fontSize}
      height={fontSize}
      viewBox='-1 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_190_861)'>
        <path
          d='M0.683666 11.1528C0.246051 11.5903 0.000132154 12.1837 0 12.8025L0 14H1.19758C1.81637 13.9999 2.40977 13.754 2.84725 13.3164L10.6307 5.53297L8.46708 3.36938L0.683666 11.1528Z'
          fill={color}
        />
        <path
          d='M13.5016 0.498837C13.3595 0.356641 13.1908 0.243836 13.0052 0.166872C12.8195 0.0899075 12.6205 0.050293 12.4195 0.050293C12.2185 0.050293 12.0195 0.0899075 11.8338 0.166872C11.6481 0.243836 11.4795 0.356641 11.3374 0.498837L9.29224 2.54458L11.4558 4.70817L13.5016 2.663C13.6438 2.52095 13.7566 2.35227 13.8335 2.1666C13.9105 1.98093 13.9501 1.78191 13.9501 1.58092C13.9501 1.37993 13.9105 1.18091 13.8335 0.995237C13.7566 0.809566 13.6438 0.640885 13.5016 0.498837Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_190_861'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default _EditAvatarIcon;
