import React from 'react';
import ReactDOM from 'react-dom';
import App from './main';
import {Provider} from "react-redux";
import FontStyles from './_styled/glogbalFonts';
import store from "./store/store";

ReactDOM.render(
  <React.StrictMode>
    <FontStyles />
      <Provider store={store}>
    <App />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
