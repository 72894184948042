import { connect } from 'react-redux';
import MainPage from './index';
import {
  setProfileData,
  setProfileNickname,
  setProfileToken,
  turnOffOnboarding,
} from '../../reducers/profileReducer';

let mapStateToProps = (state: any) => {
  return {
    profileData: state.profileReducer,
  };
};

let mapDispatchToProps = (dispatch: any) => {
  return {
    setNickname: (data: any) => {
      dispatch(setProfileNickname(data));
    },
    setProfile: (data: any) => {
      dispatch(setProfileData(data));
    },
    setToken: (data: any) => {
      dispatch(setProfileToken(data));
    },
    setOB: (data: boolean) => {
      dispatch(turnOffOnboarding(data));
    },
  };
};
const MainPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainPage);
export default MainPageContainer;
