import React from 'react';
import { StyledFC } from '../../../types';
import styled from 'styled-components';
import SimpleIconContainer from '../SimpleIconContainer';
import MedalsIconSVG from '../../../iconsSource/MedalsIconSVG';

const _MedalsIcon: StyledFC<{ size: number; color: string }> = ({
  className,
  size,
  color,
}) => {
  return (
    <SimpleIconContainer className={className} color={color} size={size}>
      <MedalsIconSVG />
    </SimpleIconContainer>
  );
};

const MedalsIcon = styled(_MedalsIcon)``;

export default MedalsIcon;
