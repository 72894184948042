import axios from 'axios';
import { Cookies } from 'react-cookie';
import { tokenCookies } from '../utils';

let cookie = new Cookies();
let subscribers: any[] = [];
function onAccessTokenFetched(accessToken: any) {
  subscribers = subscribers.filter((callback) => callback(accessToken));
  subscribers = [];
}

export const API = axios.create({
  baseURL:
    process.env.REACT_APP_API_URL ||
    'https://client.playground.sysdyn.ru/api/v1/',
  headers: {
    Authorization: `Bearer ${cookie.get('token')}`,
  },
});

API.interceptors.response.use(
  (response) => response,
  (error) => {
    const {
      config,
      response: { status },
    } = error;

    if (status === 401 || status === 500) {
      let token: any;
      if (!tokenCookies.get('token')) {
        token = localStorage.getItem('userToken');
      } else {
        token = tokenCookies.get('token');
      }

      onAccessTokenFetched(token);
      config.headers!.Authorization = `Bearer ${token}`;
      return axios.request(config);
    }
  }
);
API.interceptors.request.use(
  (config) => {
    let token: any;
    if (!tokenCookies.get('token')) {
      token = localStorage.getItem('userToken');
    } else {
      token = tokenCookies.get('token');
    }

    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);
