import React, { useEffect, useRef, useState } from 'react';
import { StyledFC } from '../../types';
import styled from 'styled-components';
import SeasonCard from './SeasonCard';
import ProgressBar from '../_shared/ProgressBar';
import { seasonServices } from '../../services';

const _Seasons: StyledFC<{
  onLoad?: () => void | undefined;
}> = ({ className, onLoad }) => {
  const scrollRef = useRef<HTMLDivElement>();
  const [expLeft, setExpLeft] = useState(0);
  const [nextLvl, setNextLvl] = useState(0);
  const [rewardTouched, setRewardTouched] = useState(false);
  const [seasonData, setSeasonData] = useState<{
    name: string;
    daysLeft: string;
    currentLevel: number;
    expEarned: number;
    cards: {
      notifyNew: any;
      playbookId: any;
      award: string;
      receivedAwardTimestamp?: any;
      awardDescription: string;
      id: number;
      status: 'TAKEN' | 'LOCKED' | 'EARNED' | 'IN_PROGRESS';
      title: string;
      exp: number;
      imageFileKey: string;
      productId: number;
      link: string;
      awardId?: number;
      desc?: '';
    }[];
  }>();
  //@ts-ignore
  window.ym(88659232, 'reachGoal', 'seasonOpen');
  useEffect(() => {
    if (scrollRef.current && seasonData) {
      // @ts-ignore
      scrollRef.current.scrollLeft = 165 * seasonData.currentLevel - 200;
    }
  }, [scrollRef, seasonData]);

  useEffect(() => {
    onLoad && onLoad();
  }, [seasonData]);

  const takeReward = (id?: number, playbookId?: number) => {
    //@ts-ignore
    window.ym(88659232, 'reachGoal', 'takeReward');
    seasonServices
      .takeReward(id)
      .then(({ data }) => {
        //@ts-ignore
        window.ym(88659232, 'reachGoal', 'takeSeasonReward');
        if (playbookId) {
          seasonServices.takeMedal(playbookId);
        }
        setRewardTouched(!rewardTouched);
        const { activationLink, award: promocode } = data;
        navigator.clipboard.writeText(promocode);
        //@ts-ignore
        setSeasonData((state) => ({
          ...state,
          cards: state!.cards.map((card) =>
            card.awardId === id
              ? {
                  ...card,
                  desc: `Вы получили промокод ${promocode}. Вы можете активировать его на сайте компании, нажав на кнопку ниже.`,
                  link: activationLink,
                }
              : { ...card }
          ),
        }));
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    seasonServices
      .getSeasons()
      .then(({ data }) => {
        setSeasonData({
          ...data,
          cards: data.cards.map((c: any) => {
            return {
              id: c.id,
              exp: c.exp,
              awardId: c.awards[0]?.awardId,
              imageFileKey: c.awards[0]?.imageFileKey,
              productId: c.awards[0]?.productId,
              award: c.awards[0]?.award,
              playbookId: c.awards[0]?.playbookId,
              title: c.awards[0]?.title,
              receivedAwardTimestamp: c.awards[0]?.receivedAwardTimestamp || '',
              awardDescription: c.awards[0]?.awardDescription,
              notifyNew: c.awards[0]?.new,
              status: c.status,
              link: c.awards[0]?.activationLink,
            };
          }),
        });
      })
      .catch((e) => {
        console.warn(e);
      });
  }, [rewardTouched]);
  let nxtlvl = 0;
  let expToLvl: any;
  if (seasonData) {
    for (let n = 0; n < seasonData.cards.length; n += 1) {
      if (
        (seasonData.cards[n].status === 'TAKEN' ||
          seasonData.cards[n].status === 'EARNED') &&
        seasonData.cards[n + 1]?.status !== 'TAKEN'
      ) {
        nxtlvl = n;
      }
    }
    expToLvl = seasonData?.cards[nxtlvl + 1]?.exp - seasonData?.expEarned;
  }

  useEffect(() => {
    setNextLvl(nxtlvl + 2);
  }, [nxtlvl]);

  useEffect(() => {
    if (expToLvl) {
      setExpLeft(expToLvl);
    }
  }, [expToLvl]);

  return (
    <div>
      <div className={className}>
        <div className={'expToLvl'}>
          {seasonData?.cards.length !== nextLvl - 1 && (
            <span className='xpCount'>
              {expLeft} XP до {nextLvl}-го уровня
            </span>
          )}
        </div>
        {seasonData?.cards.map((card, index) => {
          let percent = 0;
          if (
            index != 0 &&
            seasonData.cards[index + 1] &&
            seasonData.cards[index + 1].status === 'IN_PROGRESS'
          ) {
            percent =
              (seasonData.expEarned / seasonData.cards[index + 1]?.exp) * 100;
          }
          if (index === 0) {
            percent =
              (seasonData.expEarned / seasonData.cards[index + 1]?.exp) * 100;
          }
          if (percent > 100) {
            percent = 100;
          }
          if (
            seasonData.cards[index + 1]?.status === 'TAKEN' ||
            seasonData.cards[index + 1]?.status === 'EARNED'
          ) {
            percent = 100;
          }
          return (
            <div key={index} className='numberWrapper'>
              <div>
                <div
                  className={
                    card.status === 'TAKEN' || card.status === 'EARNED'
                      ? 'number'
                      : 'numberLocked'
                  }
                  key={index}
                >
                  {(card.status === 'LOCKED' ||
                    card.status === 'IN_PROGRESS') &&
                    index + 1}
                  {(card.status === 'TAKEN' || card.status === 'EARNED') && (
                    <svg
                      width='16'
                      height='12'
                      viewBox='0 0 16 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M5.16612 11.7751C4.71195 11.7753 4.27638 11.5947 3.9555 11.2733L0.295374 7.61455C-0.0984579 7.22059 -0.0984579 6.58199 0.295374 6.18803C0.689331 5.7942 1.32794 5.7942 1.72189 6.18803L5.16612 9.63226L14.2781 0.520288C14.6721 0.126457 15.3107 0.126457 15.7046 0.520288C16.0985 0.914246 16.0985 1.55285 15.7046 1.94681L6.37675 11.2733C6.05587 11.5947 5.6203 11.7753 5.16612 11.7751Z'
                        fill='white'
                      />
                    </svg>
                  )}
                </div>
                {index + 1 !== seasonData.cards.length && (
                  <div className='progress'>
                    <ProgressBar
                      currentExp={
                        seasonData.cards[index + 1].status === 'IN_PROGRESS'
                          ? seasonData.expEarned
                          : null
                      }
                      percentage={percent}
                    />
                  </div>
                )}
              </div>
              <SeasonCard
                status={card.status}
                exp={card.exp}
                title={card.title}
                key={index}
                notifyNew={card.notifyNew}
                award={card.award}
                imageFileKey={card.imageFileKey}
                productId={card.productId}
                playbookId={card.playbookId}
                link={card.link}
                date={card.receivedAwardTimestamp}
                desc={card.awardDescription}
                //@ts-ignore
                takeReward={
                  card.awardId &&
                  (() => takeReward(card.awardId, card.playbookId))
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Seasons = styled(_Seasons)`
  .expToLvl {
    text-align: center;
    margin-bottom: 58px;
  }
  .number {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    border: 2px solid #90d128;
    text-align: center;
    background-color: #90d128;
  }
  .numberLocked {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border: 2px solid #d5d5d5;
    border-radius: 50%;
  }
  .numberWrapper {
    display: grid;
    grid-template-columns: 5% 95%;
  }

  .progress {
    margin-left: 20px;
    height: 108px;
    width: 2px;
    align-items: center;
    background-color: #d5d5d5;
  }

  .xpCount {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #212121;
  }
`;

export default Seasons;
