import { createGlobalStyle } from 'styled-components';
import fontsCss from '../global/css/fonts.module.css';

const FontStyles = createGlobalStyle`
// FOUT bug fix
${fontsCss}

body {
  letter-spacing: -0.02em;
}
`;

export default FontStyles;
